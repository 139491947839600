import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./App/Pages/NotFound";
import ProtectedRoute from "./Config/ProtectedRoute";
import Home from "./App/Home/Home";
import Login from "./App/login/Login"; // Asegúrate de que el archivo se llame Login.js o Login.jsx
import Register from "./App/sigunp/Register"; // Verifica que el archivo Register esté en la carpeta Signup
import ComprarVip from "./App/Vip/ComprarVip";
import ForgotPassword from "./App/Profile/ForgotPassword";
import BetFree from "./App/Free/BetFree";
import PanelAdmin from "./Admin/PanelAdmin";
import UpVipAdmin from "./Admin/UpVipAdmin";
import HomeBlogs from "./App/Blogs/HomeBlogs";
import Marcadores from "./App/Home/Marcadores";
import ContactForm from "./App/Contact/ContactForm";
import HomeProfile from "./App/Shorts/Profile/HomeProfile";
import SuperPromos from "./App/promos/SuperPromos";
import ApuestasEnVivo from "./App/Blogs/articles/ApuestasEnVivo";
import WalletView from "./App/Wallet/WalletView";
import HomeShop from "./App/shop/HomeShop";
import ProductDetail from "./App/shop/ProductDetail";
import Recharge from "./App/Wallet/Recharge";
import UserProfile from "./App/Profile/UserProfile";
import Chat from "./App/Chat/Chat"
import Dashboard from "../src/App/Demo/Dashboard"
import LoginDemo from "./App/Demo/Pages/LoginDemo";
import ListDoc from "./App/Demo/Pages/ListDoc";
import GameDetail from "./App/casino/GameDetail";
import GameOne from "./App/casino/games/GameOne";
import HomeVip from "./App/Vip/HomeVip";
import LoveYou from "./App/Example/LoveYou";
import { showMobileOnlyMessage } from "./Config/onlyPhone";
import RadioPage from "./App/Pages/Radio";
import HomeShort from "./App/Shorts/HomeShort";
import AppLayout from "./App/Shorts/AppLayout";
import HomeShorts from "./App/Shorts/HomeShort";
import FollowingPage from "./App/Shorts/FollowingPage";
import UploadPage from "./App/Shorts/UploadPage";
import InboxPage from "./App/Shorts/InboxPage";
import ProfilePage from "./App/Shorts/ProfilePage";
import ChatConversation from "./App/Shorts/ChatConversation";
import ChatHome from "./App/Shorts/ChatHome";
import UserProfileView from "./App/Shorts/Profile/UserProfileView";
import MusicPlayer from "./App/Pages/MusicPlayer";
import HomeBetting from "./App/betting/home";

//store
//recharge
function App() {
  useEffect(() => {
    // Llamar a la función cuando el componente se monta
    // showMobileOnlyMessage();
    // 1087 790 916

  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<ProtectedRoute element={<Home />} />} /> */}
        <Route
          path="/tips-free"
          element={<ProtectedRoute element={<BetFree />} />}
        />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route
          path="/home/vip"
          element={<ProtectedRoute element={<HomeVip />} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/comprar-vip" element={<ComprarVip />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/marcadores-en-vivo" element={<Marcadores />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/profile" element={<HomeProfile />} />{" "}
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/support" element={<Chat />} />
        <Route path="/betting" element={<HomeBetting />} />
        ///
        <Route path="/shorts" element={<HomeShorts />} />
        <Route path="/shorts/:shortId" element={<HomeShorts />} />
        {/* <Route index element={<HomeShorts />} /> */}
        <Route path="following" element={<FollowingPage />} />
        <Route path="upload" element={<UploadPage />} />
        <Route path="/inbox/:userId" element={<ChatConversation />} />
        <Route path="inbox" element={<ChatHome />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="profile-view/:userId" element={<UserProfileView />} />
        <Route path="profile/:userId" element={<ProfilePage />} />
        ///
        {/* Ruta al perfil */}
        <Route path="/promociones-vip" element={<SuperPromos />} />{" "}
        {/* Ruta al perfil */}
        <Route path="/wallet" element={<WalletView />} /> {/* Ruta al perfil */}
        <Route path="/juego/game-one" element={<GameOne />} />{" "}
        <Route path="/blogs" element={<HomeBlogs />} />
        <Route
          path="/articulos/apuestas-en-vivo"
          element={<ApuestasEnVivo />}
        />
        <Route path="/store" element={<HomeShop />} />
        <Route path="/recharge" element={<Recharge />} />
        <Route path="products/:productId" element={<ProductDetail />} />
        {/* <Route path="/article/:slug" component={ArticlePage} /> */}
        <Route
          path="/app-admin"
          element={<ProtectedRoute element={<PanelAdmin />} />}
        />
        <Route
          path="/app-admin-vip"
          element={<ProtectedRoute element={<UpVipAdmin />} />}
        />
        {/* Ruta para manejar páginas no encontradas */}
        <Route path="*" element={<NotFound />} />
        {/* new software */}
        <Route
          path="/demo/docmaganer"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route
          path="/demo/login"
          element={<ProtectedRoute element={<LoginDemo />} />}
        />
        <Route
          path="/demo/list"
          element={<ProtectedRoute element={<ListDoc />} />}
        />
        <Route
          path="/te-dedico-amor"
          element={<ProtectedRoute element={<LoveYou />} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
