import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../Config/firebase"; // Importamos la base de datos desde tu configuración

export default function RadioOnline() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const radioRef = ref(database, "App/radio_online"); // Ruta correcta en Firebase

    const unsubscribe = onValue(radioRef, (snapshot) => {
      const data = snapshot.val();
      setIsActive(data?.active === true); // Verificamos si "active" es true
    });

    return () => unsubscribe(); // Limpiar la suscripción al desmontar el componente
  }, []);

  if (!isActive) return null; // Oculta el componente si "active" no es true

  return (
    <div>
      <h2 className="text-xl font-bold text-red-500 mb-3 flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
          />
        </svg>
        Radio en línea
      </h2>
      <iframe
        src="https://deportivospro.mixlr.com/embed"
        frameBorder="0"
        height="200px"
        width="100%"
      ></iframe>
      <small>
        <a
          href="https://mixlr.com/"
          style={{
            color: "#1a1a1a",
            display: "block",
            fontFamily: "Helvetica, sans-serif",
            fontSize: "11px",
            textAlign: "left",
            padding: "4px 0",
          }}
        >
          DeportivosPro
        </a>
      </small>
    </div>
  );
}
