import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

// Asegúrate de que el elemento con id 'app' esté presente en el HTML
const rootElement = document.getElementById('app');
if (!rootElement) {
  throw new Error('El contenedor de React no se encontró en el HTML.');
}

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Registrar el service worker si es soportado por el navegador
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register("service-worker.js") // Asegúrate de usar una ruta absoluta
      .then((registration) => {
        console.log(
          "Service Worker registrado con el alcance:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Error al registrar el Service Worker:", error);
      });
  });
} else {
  console.warn('Service Worker no está soportado en este navegador.');
}
