import React, { useState, useEffect, useCallback } from "react";
import {
  Coins,
  RotateCcw,
  Lock,
  User,
  CheckCircle,
  Volume2,
  VolumeX,
  History,
  ChevronDown,
  ChevronUp,
  Trophy,
} from "lucide-react";
import { database } from "../../../Config/firebase";
import { ref, onValue, update, push } from "firebase/database";

// Sounds
const SOUNDS = {
  spin: new Audio("/sounds/spin.mp3"),
  win: new Audio("/sounds/win.mp3"),
  jackpot: new Audio("/sounds/jackpot.mp3"),
  click: new Audio("/sounds/click.mp3"),
  lose: new Audio("/sounds/lose.mp3"),
};

const PreloadValidator = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userUUID, setUserUUID] = useState(null);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedUUID = localStorage.getItem("userUUID");
    if (!storedUUID) {
      setError("No se encontró la sesión. Inicia sesión nuevamente.");
      setLoading(false);
      return;
    }

    const userRef = ref(database, `usuarios/${storedUUID}`);
    setUserUUID(storedUUID);

    const unsubscribe = onValue(
      userRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setUserData(snapshot.val());
          setLoading(false);
        } else {
          setError("Perfil de usuario no encontrado");
          setLoading(false);
        }
      },
      (error) => {
        setError("Error al cargar datos de usuario");
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const updateUserBalance = async (newBalance) => {
    if (!userUUID) return;
    try {
      await update(ref(database, `usuarios/${userUUID}`), {
        saldo: newBalance,
      });
    } catch (err) {
      console.error("Error actualizando saldo", err);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-black to-red-900">
        <div className="text-red-500 text-2xl animate-pulse">
          Cargando Slot Machine...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-black to-red-900 text-white p-4">
        <Lock size={64} className="mb-4 text-red-500 animate-pulse" />
        <h2 className="text-2xl font-bold mb-2">Error de Acceso</h2>
        <p className="text-center">{error}</p>
      </div>
    );
  }

  return React.cloneElement(children, {
    userUUID,
    userData,
    updateUserBalance,
  });
};

const GameOne = ({ userUUID, userData, updateUserBalance }) => {
  const [credits, setCredits] = useState(userData?.saldo || 0);
  const [bet, setBet] = useState(7);
  const [isSpinning, setIsSpinning] = useState(false);
  const [slots, setSlots] = useState(["🎰", "🎰", "🎰"]);
  const [message, setMessage] = useState("¡Buena suerte!");
  const [showWinAnimation, setShowWinAnimation] = useState(false);
  const [winType, setWinType] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [gameHistory, setGameHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [stats, setStats] = useState({
    totalGames: 0,
    wins: 0,
    losses: 0,
    biggestWin: 0,
  });
  const [showStats, setShowStats] = useState(false);

  const symbols = ["🍒", "🍋", "💎", "7️⃣", "🍇", "🎰", "💥", "⭐", "🔔", "🍀"];

  useEffect(() => {
    // Load game history from Firebase
    const historyRef = ref(database, `usuarios/${userUUID}/gameHistory`);
    const unsubscribe = onValue(historyRef, (snapshot) => {
      if (snapshot.exists()) {
        const historyData = snapshot.val();
        const historyArray = Object.keys(historyData).map((key) => ({
          id: key,
          ...historyData[key],
        }));

        // Sort by timestamp in descending order
        historyArray.sort((a, b) => b.timestamp - a.timestamp);

        // Keep only the last 20 games
        const recentHistory = historyArray.slice(0, 20);
        setGameHistory(recentHistory);

        // Calculate stats
        const wins = historyArray.filter(
          (game) => game.result === "win"
        ).length;
        const totalGames = historyArray.length;
        const biggestWin = historyArray.reduce(
          (max, game) => (game.winAmount > max ? game.winAmount : max),
          0
        );

        setStats({
          totalGames,
          wins,
          losses: totalGames - wins,
          biggestWin,
        });
      }
    });

    return () => unsubscribe();
  }, [userUUID]);

  const playSound = useCallback(
    (soundType) => {
      if (isMuted || !SOUNDS[soundType]) return;
      SOUNDS[soundType].pause();
      SOUNDS[soundType].currentTime = 0;
      SOUNDS[soundType]
        .play()
        .catch((e) => console.error(`Error playing ${soundType} sound:`, e));
    },
    [isMuted]
  );

  const getRandomSymbol = () =>
    symbols[Math.floor(Math.random() * symbols.length)];

  const saveGameToHistory = (resultData) => {
    const historyRef = ref(database, `usuarios/${userUUID}/gameHistory`);
    push(historyRef, {
      ...resultData,
      timestamp: Date.now(),
    });
  };

  const spin = () => {
    if (credits < bet) {
      setMessage("¡Saldo insuficiente!");
      return;
    }

    playSound("spin");
    setIsSpinning(true);
    setShowWinAnimation(false);
    setWinType(null);

    const newCredits = credits - bet;
    setCredits(newCredits);
    updateUserBalance(newCredits);
    setMessage("¡Girando!");

    let spinsCount = 0;
    const maxSpins = 20;

    const intervalId = setInterval(() => {
      setSlots([getRandomSymbol(), getRandomSymbol(), getRandomSymbol()]);
      spinsCount++;

      if (spinsCount >= maxSpins) {
        clearInterval(intervalId);
        const finalSymbols = [
          getRandomSymbol(),
          getRandomSymbol(),
          getRandomSymbol(),
        ];
        setSlots(finalSymbols);
        setIsSpinning(false);
        checkWin(finalSymbols);
      }
    }, 100);
  };

  const checkWin = (finalSlots) => {
    const [s1, s2, s3] = finalSlots;
    let winAmount = 0;
    let winCategory = null;
    let resultMessage = "";

    // Probabilidades de ganar
    const rng = Math.random();

    if (s1 === s2 && s2 === s3) {
      if (rng < 0.08) {
        // 8% probabilidad de jackpot
        winAmount = bet * 20;
        winCategory = "jackpot";
        playSound("jackpot");
        resultMessage = `🎉 ¡JACKPOT SUPREMO! +${winAmount} monedas 🎉`;
        setMessage(resultMessage);
      }
    } else if ((s1 === s2 || s2 === s3 || s1 === s3) && rng < 0.15) {
      // 15% probabilidad de premio normal
      winAmount = bet * 5;
      winCategory = "normal";
      playSound("win");
      resultMessage = `¡Premio! +${winAmount} monedas`;
      setMessage(resultMessage);
    } else {
      playSound("lose");
      resultMessage = "¡Inténtalo de nuevo!";
      setMessage(resultMessage);
    }

    // Save game to history
    saveGameToHistory({
      bet,
      symbols: finalSlots.join(" "),
      result: winAmount > 0 ? "win" : "lose",
      winAmount,
      winCategory,
      balanceAfter: credits + winAmount,
    });

    if (winAmount > 0) {
      const newCredits = credits + winAmount;
      setCredits(newCredits);
      updateUserBalance(newCredits);
      setShowWinAnimation(true);
      setWinType(winCategory);
    }
  };

  const changeBet = (amount) => {
    playSound("click");
    const newBet = Math.max(7, Math.min(100, bet + amount));
    setBet(newBet);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="flex flex-col items-center pt-10 min-h-screen bg-gradient-to-b from-black via-red-900 to-black p-2 relative overflow-hidden">
      {/* Jackpot animation */}
      {showWinAnimation && winType === "jackpot" && (
        <div className="absolute inset-0 animate-fireworks">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute w-2 h-2 bg-red-500 rounded-full"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animation: `explosion 1s ease-out ${
                  Math.random() * 0.5
                }s infinite`,
              }}
            />
          ))}
        </div>
      )}

      <div className="bg-black/90 backdrop-blur-sm rounded-xl shadow-2xl p-6 w-full max-w-md relative transform hover:scale-102 transition-transform duration-300 border-2 border-red-700">
        {/* Sound toggle button */}
        <button
          onClick={() => setIsMuted(!isMuted)}
          className="absolute top-4 right-4 text-red-600 hover:text-red-400"
        >
          {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
        </button>

        {/* User and Credits */}
        <div className="text-center mb-4 flex justify-between items-center">
          <div className="flex items-center gap-2 bg-red-950 p-2 rounded-lg">
            <User className="text-red-500" />
            <span className="font-medium text-white">
              {userData?.name?.split(" ")[0] || "Jugador"}
            </span>
          </div>
          <div className="flex items-center gap-2 bg-black p-2 rounded-lg border border-red-500">
            <Coins className="text-red-500" />
            <span className="text-2xl font-bold text-red-500">{credits}</span>
          </div>
        </div>

        {/* Bet controls */}
        <div className="flex justify-center gap-4 mb-6">
          <div className="flex-1">
            <div className="text-center mb-2 flex justify-center items-center gap-2 bg-red-900 p-2 rounded-lg">
              <span className="font-medium text-white">Apuesta: {bet}</span>
              <CheckCircle
                className={
                  bet >= 7 && bet <= 100 ? "text-green-500" : "text-red-500"
                }
                size={18}
              />
            </div>
            <div className="flex gap-2 justify-center">
              <button
                onClick={() => changeBet(-5)}
                className="px-4 py-2 bg-black text-white rounded-lg border border-red-600 hover:bg-red-900 disabled:opacity-50 transform hover:scale-105 transition-transform duration-200"
                disabled={bet <= 7}
              >
                -5
              </button>
              <button
                onClick={() => changeBet(5)}
                className="px-4 py-2 bg-black text-white rounded-lg border border-red-600 hover:bg-red-900 disabled:opacity-50 transform hover:scale-105 transition-transform duration-200"
                disabled={bet >= 100}
              >
                +5
              </button>
            </div>
          </div>
        </div>

        {/* Slot machine display */}
        <div className="flex justify-center gap-2 mb-4">
          {slots.map((symbol, index) => (
            <div
              key={index}
              className={`w-24 h-24 flex items-center justify-center text-5xl 
                ${isSpinning ? "animate-bounce" : ""} 
                ${
                  showWinAnimation && winType === "jackpot"
                    ? "animate-pulse"
                    : ""
                }
                bg-gradient-to-b from-black to-red-950 rounded-xl border-4 
                ${showWinAnimation ? "border-red-500" : "border-red-900"}
                shadow-lg transform hover:scale-105 transition-all duration-300`}
            >
              {symbol}
            </div>
          ))}
        </div>

        {/* Game message */}
        <div className="text-center mb-4">
          <p
            className={`text-lg font-bold ${
              showWinAnimation ? "text-red-500 animate-bounce" : "text-gray-300"
            }`}
          >
            {message}
          </p>
        </div>

        {/* Spin button */}
        <button
          onClick={spin}
          disabled={isSpinning || credits < bet || bet < 7 || bet > 100}
          className={`w-full py-4 bg-gradient-to-r from-red-800 to-red-600 
            text-white rounded-lg font-bold text-lg
            hover:from-red-900 hover:to-red-700
            disabled:opacity-50 flex items-center justify-center gap-3
            transform hover:scale-102 transition-all duration-300
            ${isSpinning ? "animate-pulse" : ""}`}
        >
          <RotateCcw className={`${isSpinning ? "animate-spin" : ""}`} />
          GIRAR
        </button>

        {/* Stats button */}
        <button
          onClick={() => {
            setShowStats(!showStats);
            setShowHistory(false);
            playSound("click");
          }}
          className="w-full mt-4 py-2 bg-black text-red-500 rounded-lg font-bold 
            border border-red-600 hover:bg-red-900 hover:text-white
            flex items-center justify-center gap-2 transition-all duration-300"
        >
          <Trophy size={20} />
          {showStats ? "Ocultar Estadísticas" : "Ver Estadísticas"}
          {showStats ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </button>

        {/* Stats panel */}
        {showStats && (
          <div className="mt-4 p-4 bg-black rounded-lg border border-red-700">
            <h3 className="text-red-500 font-bold text-lg mb-2 text-center">
              Estadísticas de Juego
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-red-950 p-3 rounded-lg text-center">
                <p className="text-gray-300 text-sm">Partidas Jugadas</p>
                <p className="text-white text-xl font-bold">
                  {stats.totalGames}
                </p>
              </div>
              <div className="bg-red-950 p-3 rounded-lg text-center">
                <p className="text-gray-300 text-sm">Mayor Premio</p>
                <p className="text-white text-xl font-bold">
                  {stats.biggestWin}
                </p>
              </div>
              <div className="bg-red-950 p-3 rounded-lg text-center">
                <p className="text-gray-300 text-sm">Victorias</p>
                <p className="text-white text-xl font-bold">{stats.wins}</p>
              </div>
              <div className="bg-red-950 p-3 rounded-lg text-center">
                <p className="text-gray-300 text-sm">Derrotas</p>
                <p className="text-white text-xl font-bold">{stats.losses}</p>
              </div>
            </div>
          </div>
        )}

        {/* History button */}
        <button
          onClick={() => {
            setShowHistory(!showHistory);
            setShowStats(false);
            playSound("click");
          }}
          className="w-full mt-4 py-2 bg-black text-red-500 rounded-lg font-bold 
            border border-red-600 hover:bg-red-900 hover:text-white
            flex items-center justify-center gap-2 transition-all duration-300"
        >
          <History size={20} />
          {showHistory ? "Ocultar Historial" : "Ver Historial"}
          {showHistory ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </button>

        {/* History panel */}
        {showHistory && (
          <div className="mt-4 p-4 bg-black rounded-lg border border-red-700 max-h-64 overflow-y-auto">
            <h3 className="text-red-500 font-bold text-lg mb-2 sticky top-0 bg-black">
              Historial de Juegos
            </h3>
            {gameHistory.length === 0 ? (
              <p className="text-gray-400 text-center">
                No hay historial disponible
              </p>
            ) : (
              <div className="space-y-2">
                {gameHistory.map((game) => (
                  <div
                    key={game.id}
                    className={`p-2 rounded-lg border ${
                      game.result === "win"
                        ? "border-green-700 bg-green-950/30"
                        : "border-red-800 bg-red-950/30"
                    }`}
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-gray-300 text-xs">
                        {formatTimestamp(game.timestamp)}
                      </span>
                      <span
                        className={`text-sm font-bold ${
                          game.result === "win"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {game.result === "win"
                          ? `+${game.winAmount}`
                          : "-" + game.bet}
                      </span>
                    </div>
                    <div className="flex justify-between mt-1">
                      <span className="text-gray-400 text-xs">
                        Apuesta: {game.bet}
                      </span>
                      <span className="text-xl">{game.symbols}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Recharge call to action */}
        {credits < bet && (
          <div className="mt-4 p-4 bg-red-950 rounded-lg shadow-md text-center border border-red-600">
            <h1 className="text-xl font-bold text-white">
              ¿Te quedaste sin saldo?
            </h1>
            <a
              href={`https://wa.me/573023580862?text=Hola,%20quiero%20recargar%20mi%20saldo.%20Datos%20del%20usuario:%0A%20Nombre:%20${userData.name}%0A%20UID:%20${userUUID}%0A%20Email:%20${userData.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-red-300 underline mt-2 block hover:text-white"
            >
              Puedes recargar aquí
            </a>
          </div>
        )}
      </div>

      {/* Add custom CSS for animations */}
      <style jsx>{`
        @keyframes explosion {
          0% {
            transform: scale(0);
            opacity: 1;
          }
          100% {
            transform: scale(10);
            opacity: 0;
          }
        }

        .animate-fireworks {
          pointer-events: none;
        }
      `}</style>
    </div>
  );
};

const SlotMachineApp = () => {
  return (
    <PreloadValidator>
      <GameOne />
    </PreloadValidator>
  );
};

export default SlotMachineApp;
