import React, { useState, useEffect } from "react";
import {
  ChevronRight,
  Mail,
  User,
  Wallet,
  X,
  ArrowDown,
  ArrowUp,
  Clock,
  FileText,
} from "lucide-react";
import useUserData from "../../Hooks/useUserData";
import {
  get,
  getDatabase,
  ref,
  update,
  push,
  serverTimestamp,
  query,
  orderByChild,
  startAt,
  endAt,
} from "firebase/database";

const WalletView = () => {
  const { userData, loading, error, updateUserData } = useUserData();
  const [amount, setAmount] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1);
  const [activeTab, setActiveTab] = useState("transfer");
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalReason, setWithdrawalReason] = useState("");
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [dailyWithdrawals, setDailyWithdrawals] = useState(0);

  useEffect(() => {
    if (userData) {
      const sortedTransactions = [...(userData.transacciones || [])].sort(
        (a, b) => new Date(b.fecha) - new Date(a.fecha)
      );
      setTransactionHistory(sortedTransactions);

      // Count today's withdrawals
      const today = new Date().toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const todayWithdrawals = sortedTransactions.filter((transaction) => {
        const transactionDate = transaction.fecha.split(",")[0]; // Extract date part
        return (
          transaction.tipo === "Retiro solicitado" && transactionDate === today
        );
      });

      setDailyWithdrawals(todayWithdrawals.length);
    }
  }, [userData]);

  const showNotification = (type, message) => {
    setNotification({ show: true, type, message });
    setTimeout(
      () => setNotification({ show: false, type: "", message: "" }),
      5000
    );
  };

  const Notification = () => {
    if (!notification.show) return null;

    const bgColor =
      notification.type === "error" ? "bg-red-500" : "bg-green-500";

    return (
      <div
        className={`fixed top-4 right-4 ${bgColor} text-white p-4 rounded-lg shadow-lg z-50 flex items-center justify-between max-w-md`}
        style={{ animation: "fadeIn 0.3s ease-out" }}
      >
        <span>{notification.message}</span>
        <button
          onClick={() =>
            setNotification({ show: false, type: "", message: "" })
          }
          className="ml-4 hover:text-gray-200"
        >
          <X size={20} />
        </button>
      </div>
    );
  };

  const handleEmailCheck = async () => {
    if (!recipientEmail) {
      showNotification("error", "Por favor ingrese un correo electrónico");
      return;
    }

    if (recipientEmail.toLowerCase() === userData.email.toLowerCase()) {
      showNotification(
        "error",
        "No puedes transferir dinero a tu propia cuenta"
      );
      return;
    }

    setIsLoading(true);
    const db = getDatabase();
    const usersRef = ref(db, "usuarios");

    try {
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const users = snapshot.val();
        const recipientUser = Object.values(users).find(
          (user) => user.email.toLowerCase() === recipientEmail.toLowerCase()
        );
        if (recipientUser) {
          setRecipientName(recipientUser.name || "Usuario");
          setStep(2);
          showNotification("success", "Usuario encontrado");
        } else {
          showNotification("error", "El usuario no existe en el sistema");
        }
      }
    } catch (error) {
      console.error("Error al verificar el usuario:", error);
      showNotification(
        "error",
        "Error al verificar el usuario. Por favor, inténtelo de nuevo"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTransfer = async () => {
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      showNotification("error", "Por favor ingrese una cantidad válida");
      return;
    }

    setIsLoading(true);
    const transferAmount = parseFloat(amount);
    const newSenderSaldo = (userData.saldo || 0) - transferAmount;

    if (newSenderSaldo < 0) {
      showNotification("error", "Saldo insuficiente");
      setIsLoading(false);
      return;
    }

    try {
      const db = getDatabase();
      const usersRef = ref(db, "usuarios");
      const snapshot = await get(usersRef);

      if (snapshot.exists()) {
        const users = snapshot.val();
        const recipientUserEntry = Object.entries(users).find(
          ([, user]) =>
            user.email.toLowerCase() === recipientEmail.toLowerCase()
        );

        if (recipientUserEntry) {
          const [recipientKey, recipientData] = recipientUserEntry;
          const recipientRef = ref(db, `usuarios/${recipientKey}`);
          const newRecipientSaldo = (recipientData.saldo || 0) + transferAmount;

          const timestamp = new Date().toISOString();
          const formattedDate = new Date().toLocaleString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });

          const newSenderTransaction = {
            tipo: "Transferencia enviada",
            monto: -transferAmount,
            fecha: formattedDate,
            timestamp: timestamp,
            destinatario: recipientEmail,
            destinatarioNombre: recipientName,
            mensaje: message || "",
          };

          const newRecipientTransaction = {
            tipo: "Transferencia recibida",
            monto: transferAmount,
            fecha: formattedDate,
            timestamp: timestamp,
            remitente: userData.email,
            remitenteNombre: userData.name || "Usuario",
            mensaje: message || "",
          };

          await Promise.all([
            updateUserData({
              saldo: newSenderSaldo,
              transacciones: [
                newSenderTransaction,
                ...(userData.transacciones || []),
              ],
            }),
            update(recipientRef, {
              saldo: newRecipientSaldo,
              transacciones: [
                newRecipientTransaction,
                ...(recipientData.transacciones || []),
              ],
            }),
          ]);

          setAmount("");
          setRecipientEmail("");
          setRecipientName("");
          setMessage("");
          setStep(1);
          showNotification("success", "Transferencia realizada con éxito");
        }
      }
    } catch (error) {
      console.error("Error al realizar la transferencia:", error);
      showNotification(
        "error",
        "Error al realizar la transferencia. Por favor, inténtelo de nuevo"
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Función actualizada para manejar los retiros con nuevas restricciones
  const handleWithdrawal = async () => {
    if (
      !withdrawalAmount ||
      isNaN(withdrawalAmount) ||
      parseFloat(withdrawalAmount) <= 0
    ) {
      showNotification(
        "error",
        "Por favor ingrese una cantidad válida para retirar"
      );
      return;
    }

    const withdrawalAmountValue = parseFloat(withdrawalAmount);

    // Validar monto mínimo de retiro
    if (withdrawalAmountValue < 350) {
      showNotification("error", "El monto mínimo de retiro es de $350");
      return;
    }

    if (withdrawalAmountValue > (userData.saldo || 0)) {
      showNotification("error", "Saldo insuficiente para realizar este retiro");
      return;
    }

    // Validar número máximo de retiros diarios
    if (dailyWithdrawals >= 3) {
      showNotification("error", "Has alcanzado el límite de 3 retiros diarios");
      return;
    }

    setIsLoading(true);

    try {
      const db = getDatabase();
      const newSaldo = (userData.saldo || 0) - withdrawalAmountValue;

      const timestamp = new Date().toISOString();
      const formattedDate = new Date().toLocaleString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      // Crear registro de transacción para el usuario
      const withdrawalTransaction = {
        tipo: "Retiro solicitado",
        monto: -withdrawalAmountValue,
        fecha: formattedDate,
        timestamp: timestamp,
        estado: "Pendiente",
        motivo: withdrawalReason || "No especificado",
      };

      // Guardar en el nodo de retiros
      const retiroRef = ref(db, "Pay/retiros");
      await push(retiroRef, {
        usuarioId: userData.id || "",
        nombre: userData.name || "Usuario",
        email: userData.email || "",
        monto: withdrawalAmountValue,
        motivo: withdrawalReason || "",
        fecha: formattedDate,
        timestamp: serverTimestamp(),
        estado: "Pendiente",
      });

      // Actualizar datos del usuario
      await updateUserData({
        saldo: newSaldo,
        transacciones: [
          withdrawalTransaction,
          ...(userData.transacciones || []),
        ],
      });

      setWithdrawalAmount("");
      setWithdrawalReason("");
      setShowWithdrawalModal(false);
      showNotification(
        "success",
        "Solicitud de retiro enviada con éxito. Un administrador se pondrá en contacto con usted pronto."
      );

      // Actualizar contador de retiros diarios
      setDailyWithdrawals(dailyWithdrawals + 1);
    } catch (error) {
      console.error("Error al procesar el retiro:", error);
      showNotification(
        "error",
        "Error al procesar el retiro. Por favor, inténtelo de nuevo"
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Modal de retiro actualizado con información sobre límites
  const WithdrawalModal = () => {
    if (!showWithdrawalModal) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-gray-800 rounded-2xl shadow-2xl p-6 w-full max-w-md mx-4 animate-fade-in">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-bold text-white">Solicitar Retiro</h3>
            <button
              onClick={() => setShowWithdrawalModal(false)}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X size={24} />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-gray-300 mb-2 text-sm">
                Saldo disponible
              </label>
              <div className="text-green-400 text-xl font-bold">
                ${userData.saldo?.toLocaleString() || "0"}
              </div>
            </div>

            {/* Información sobre límites */}
            <div className="bg-blue-900 bg-opacity-30 border border-blue-800 rounded-lg p-3 text-sm">
              <div className="flex items-start space-x-2">
                <Clock className="w-4 h-4 text-blue-400 mt-0.5" />
                <div>
                  <p className="text-blue-300">Límites de retiro:</p>
                  <ul className="text-gray-300 list-disc pl-5 mt-1 space-y-1">
                    <li>Monto mínimo: $350</li>
                    <li>
                      Máximo 3 retiros por día (Usados: {dailyWithdrawals}/3)
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-gray-300 mb-2 text-sm">
                Cantidad a retirar
              </label>
              <input
                type="number"
                placeholder="Ingrese monto (mín. $350)"
                className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-gray-300 mb-2 text-sm">
                Motivo (opcional)
              </label>
              <textarea
                placeholder="Indique forma de pago o datos para el retiro"
                className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors resize-none"
                rows="3"
                value={withdrawalReason}
                onChange={(e) => setWithdrawalReason(e.target.value)}
              />
            </div>

            <button
              onClick={handleWithdrawal}
              disabled={isLoading || dailyWithdrawals >= 3}
              className={`w-full p-4 rounded-lg font-medium text-white transition-all duration-300 ${
                isLoading || dailyWithdrawals >= 3
                  ? "bg-red-700 cursor-not-allowed opacity-70"
                  : "bg-red-600 hover:bg-red-700"
              }`}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full mr-2"></div>
                  Procesando...
                </div>
              ) : dailyWithdrawals >= 3 ? (
                "Límite de retiros diarios alcanzado"
              ) : (
                "Solicitar Retiro"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const TransactionHistory = () => (
    <div className="space-y-4 mt-2">
      <h3 className="text-gray-300 font-medium mb-4">Últimas transacciones</h3>

      {transactionHistory.length === 0 ? (
        <div className="text-center py-8 bg-gray-800 rounded-xl flex flex-col items-center justify-center">
          <Clock className="w-12 h-12 text-gray-500 mb-2" />
          <p className="text-gray-400">No hay transacciones para mostrar</p>
        </div>
      ) : (
        <div className="space-y-3">
          {transactionHistory.map((transaction, index) => {
            // Determinar el icono basado en el tipo de transacción
            let icon;
            let bgColor;

            if (transaction.tipo.includes("recibida")) {
              icon = <ArrowDown className="w-6 h-6 text-green-400" />;
              bgColor = "bg-green-400 bg-opacity-10";
            } else if (transaction.tipo.includes("enviada")) {
              icon = <ArrowUp className="w-6 h-6 text-red-400" />;
              bgColor = "bg-red-400 bg-opacity-10";
            } else if (transaction.tipo.includes("Retiro")) {
              icon = <Wallet className="w-6 h-6 text-yellow-400" />;
              bgColor = "bg-yellow-400 bg-opacity-10";
            } else {
              icon = <FileText className="w-6 h-6 text-blue-400" />;
              bgColor = "bg-blue-400 bg-opacity-10";
            }

            return (
              <div
                key={index}
                className="bg-gray-800 p-4 rounded-xl transition-all duration-300 hover:bg-gray-750 border border-gray-700"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className={`p-2 rounded-full ${bgColor}`}>{icon}</div>
                    <div>
                      <p className="text-white font-medium">
                        {transaction.tipo}
                      </p>
                      <p className="text-gray-400 text-xs">
                        {transaction.fecha}
                      </p>
                      {transaction.destinatario && (
                        <p className="text-gray-400 text-xs">
                          Para:{" "}
                          {transaction.destinatarioNombre ||
                            transaction.destinatario}
                        </p>
                      )}
                      {transaction.remitente && (
                        <p className="text-gray-400 text-xs">
                          De:{" "}
                          {transaction.remitenteNombre || transaction.remitente}
                        </p>
                      )}
                      {transaction.estado && (
                        <p
                          className={`text-xs ${
                            transaction.estado === "Completado"
                              ? "text-green-400"
                              : "text-yellow-400"
                          }`}
                        >
                          Estado: {transaction.estado}
                        </p>
                      )}
                    </div>
                  </div>
                  <p
                    className={`text-lg font-bold ${
                      transaction.monto > 0 ? "text-green-400" : "text-red-400"
                    }`}
                  >
                    {transaction.monto > 0 ? "+" : ""}$
                    {Math.abs(transaction.monto).toLocaleString()}
                  </p>
                </div>
                {transaction.mensaje && (
                  <p className="text-gray-400 mt-2 text-xs pl-11">
                    Mensaje: {transaction.mensaje}
                  </p>
                )}
                {transaction.motivo && (
                  <p className="text-gray-400 mt-2 text-xs pl-11">
                    Motivo: {transaction.motivo}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="bg-red-500 text-white p-4 rounded-lg shadow-lg">
          Error: {error}
        </div>
      </div>
    );
  }

  // Render the component
  return (
    <div className="min-h-screen bg-gray-900 p-4 md:p-6">
      <Notification />
      <WithdrawalModal />
      <div className="max-w-lg mx-auto">
        <div className="bg-gradient-to-b from-gray-800 to-gray-900 rounded-3xl shadow-2xl overflow-hidden">
          {/* Cabecera con balance */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 p-6 relative overflow-hidden">
            <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-10 rounded-full -mr-16 -mt-16"></div>
            <div className="absolute bottom-0 left-0 w-20 h-20 bg-white opacity-10 rounded-full -ml-10 -mb-10"></div>

            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-3">
                <Wallet className="w-6 h-6 text-white" />
                <span className="text-white font-medium">Mi Billetera</span>
              </div>
              <div className="text-white text-opacity-80 text-sm">
                {new Date().toLocaleDateString()}
              </div>
            </div>

            <div className="mb-1 text-white text-opacity-80">
              Saldo Disponible
            </div>
            <div className="text-4xl font-bold text-white mb-6">
              ${userData.saldo?.toLocaleString() || "0"}
            </div>

            <div className="flex space-x-3">
              <button
                className="flex-1 bg-white bg-opacity-20 backdrop-blur-sm text-white py-2.5 px-4 rounded-xl hover:bg-opacity-30 transition-colors duration-200 flex items-center justify-center space-x-2"
                onClick={() => {
                  const phoneNumber = "+573023580862";
                  const message = "Quiero recargar mi cuenta";
                  const encodedMessage = encodeURIComponent(message);
                  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
                  window.open(whatsappLink, "_blank");
                }}
              >
                <ArrowDown className="w-4 h-4" />
                <span>Recargar</span>
              </button>
              <button
                className="flex-1 bg-white bg-opacity-20 backdrop-blur-sm text-white py-2.5 px-4 rounded-xl hover:bg-opacity-30 transition-colors duration-200 flex items-center justify-center space-x-2"
                onClick={() => setShowWithdrawalModal(true)}
              >
                <ArrowUp className="w-4 h-4" />
                <span>Retirar</span>
              </button>
            </div>
          </div>

          {/* Información del usuario */}
          <div className="p-4 border-b border-gray-700">
            <div className="flex items-center justify-between p-2 mb-2">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-gray-700 rounded-full flex items-center justify-center">
                  <User className="w-5 h-5 text-blue-400" />
                </div>
                <div>
                  <p className="text-white font-medium">
                    {userData.name || "Usuario"}
                  </p>
                  <p className="text-gray-400 text-sm">
                    {userData.email || "usuario@ejemplo.com"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div className="flex border-b border-gray-700">
            <button
              onClick={() => setActiveTab("transfer")}
              className={`flex-1 py-3 text-center font-medium transition-colors duration-200 ${
                activeTab === "transfer"
                  ? "text-blue-400 border-b-2 border-blue-400"
                  : "text-gray-400 hover:text-gray-300"
              }`}
            >
              Transferir
            </button>
            <button
              onClick={() => setActiveTab("history")}
              className={`flex-1 py-3 text-center font-medium transition-colors duration-200 ${
                activeTab === "history"
                  ? "text-blue-400 border-b-2 border-blue-400"
                  : "text-gray-400 hover:text-gray-300"
              }`}
            >
              Historial
            </button>
          </div>

          {/* Contenido */}
          <div className="p-6">
            {activeTab === "transfer" && (
              <div className="space-y-6">
                {step === 1 ? (
                  <div className="space-y-4">
                    <h3 className="text-white font-medium mb-4">
                      Transferir fondos
                    </h3>
                    <div className="space-y-2">
                      <label className="text-gray-300 text-sm">
                        Correo del destinatario
                      </label>
                      <input
                        type="email"
                        placeholder="nombre@ejemplo.com"
                        className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors"
                        value={recipientEmail}
                        onChange={(e) => setRecipientEmail(e.target.value)}
                      />
                    </div>

                    <button
                      onClick={handleEmailCheck}
                      disabled={isLoading}
                      className={`w-full p-4 rounded-lg font-medium text-white transition-all duration-300 ${
                        isLoading
                          ? "bg-gray-600 cursor-not-allowed"
                          : "bg-blue-500 hover:bg-blue-600"
                      }`}
                    >
                      {isLoading ? (
                        <div className="flex items-center justify-center">
                          <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full mr-2"></div>
                          Verificando...
                        </div>
                      ) : (
                        "Continuar"
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className="bg-blue-500 bg-opacity-10 border border-blue-500 border-opacity-20 rounded-lg p-4">
                      <div className="flex items-center space-x-3">
                        <User className="w-5 h-5 text-blue-400" />
                        <div>
                          <p className="text-white font-medium">
                            {recipientName}
                          </p>
                          <p className="text-gray-400 text-sm">
                            {recipientEmail}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="text-gray-300 text-sm">
                        Cantidad a transferir
                      </label>
                      <input
                        type="text"
                        inputMode="numeric"
                        placeholder="$0.00"
                        className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors"
                        value={amount}
                        onChange={(e) => {
                          // Only allow digits and a single decimal point
                          const value = e.target.value.replace(/[^\d.]/g, "");
                          // Prevent multiple decimal points
                          const decimalCount = (value.match(/\./g) || [])
                            .length;
                          if (decimalCount <= 1) {
                            setAmount(value);
                          }
                        }}
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="text-gray-300 text-sm">
                        Mensaje (opcional)
                      </label>
                      <textarea
                        placeholder="Añadir un mensaje..."
                        className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors resize-none"
                        rows="2"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>

                    <div className="flex space-x-4">
                      <button
                        onClick={() => {
                          setStep(1);
                          setRecipientEmail("");
                          setRecipientName("");
                        }}
                        className="flex-1 p-4 rounded-lg font-medium text-white bg-gray-700 hover:bg-gray-600 transition-all duration-300"
                      >
                        Cancelar
                      </button>
                      <button
                        onClick={handleTransfer}
                        disabled={isLoading}
                        className={`flex-1 p-4 rounded-lg font-medium text-white transition-all duration-300 ${
                          isLoading
                            ? "bg-green-600 cursor-not-allowed"
                            : "bg-green-500 hover:bg-green-600"
                        }`}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center">
                            <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full mr-2"></div>
                            Procesando...
                          </div>
                        ) : (
                          "Transferir"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeTab === "history" && <TransactionHistory />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletView;
