// ProfilePage.jsx
import React, { useState, useEffect } from "react";
import { Settings, Grid, Bookmark, Upload, Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";

export default function ProfilePage() {
  const [userVideos, setUserVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  // Obtener el UUID del usuario desde localStorage
  useEffect(() => {
    try {
      const userUUID = localStorage.getItem("userUUID");
      if (userUUID) {
        setUserId(userUUID);
      } else {
        console.error("No se encontró el ID de usuario en localStorage");
      }
    } catch (error) {
      console.error("Error al obtener el UUID del usuario:", error);
    }
  }, []);

  // Obtener datos del usuario y sus videos
  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    const db = getDatabase();

    // Obtener datos del perfil
    const userRef = ref(db, `usuarios/${userId}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData(data);
      }
    });

    // Obtener videos del usuario
    const shortsRef = ref(db, `usuarios/${userId}/shorts`);
    onValue(shortsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convertir objeto a array y ordenar por fecha (más reciente primero)
        const videosArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        videosArray.sort(
          (a, b) => new Date(b.fechaSubida) - new Date(a.fechaSubida)
        );

        setUserVideos(videosArray);
      }
      setLoading(false);
    });
  }, [userId]);

  const handleUploadClick = () => {
    navigate("/upload");
  };

  const username = userData?.username || "@usuario";
  const bio =
    userData?.bio ||
    "Agrega una biografía para que las personas conozcan más sobre ti.";
  const profileImage = userData?.profileImage || "https://placehold.co/100";
  const following = userData?.following?.length || 0;
  const followers = userData?.followers?.length || 0;
  const likes = userData?.totalLikes || 0;

  return (
    <div className="h-full text-white pb-16">
      <div className="pt-4 px-4 pb-2">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold">{username}</h2>
          <button className="p-2 hover:bg-gray-800 rounded-full transition-colors">
            <Settings size={24} />
          </button>
        </div>

        <div className="flex items-center mb-4">
          <img
            src={profileImage}
            alt="Profile"
            className="w-20 h-20 rounded-full object-cover border-2 border-pink-500"
          />
          <div className="flex space-x-6 ml-6">
            <div className="text-center">
              <div className="font-bold">{following}</div>
              <div className="text-xs text-gray-400">Siguiendo</div>
            </div>
            <div className="text-center">
              <div className="font-bold">{followers}</div>
              <div className="text-xs text-gray-400">Seguidores</div>
            </div>
            <div className="text-center">
              <div className="font-bold">{likes}</div>
              <div className="text-xs text-gray-400">Me gusta</div>
            </div>
          </div>
        </div>

        <p className="mb-4 text-gray-300">{bio}</p>

        <button className="w-full border border-gray-600 text-white font-medium py-1.5 rounded-md hover:bg-gray-800 transition-colors">
          Editar perfil
        </button>
      </div>

      <div className="border-b border-gray-800 mt-4">
        <div className="flex">
          <button className="flex-1 py-2 border-b-2 border-white flex justify-center items-center">
            <Grid size={20} className="mr-1" />
            <span className="text-sm">Videos</span>
          </button>
          <button className="flex-1 py-2 text-gray-500 flex justify-center items-center hover:text-white transition-colors">
            <Bookmark size={20} className="mr-1" />
            <span className="text-sm">Guardados</span>
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex flex-col items-center justify-center p-8 h-64">
          <Loader2 className="animate-spin h-8 w-8 text-pink-500" />
          <p className="mt-4 text-gray-400">Cargando videos...</p>
        </div>
      ) : userVideos.length === 0 ? (
        <div className="flex flex-col items-center justify-center p-8 h-64 text-center">
          <h3 className="font-medium mb-2">Sube tu primer video</h3>
          <p className="text-sm text-gray-400 mb-4">
            Tus videos aparecerán aquí
          </p>
          <button
            className="bg-gradient-to-r from-pink-500 to-purple-600 text-white font-bold py-2 px-6 rounded-full shadow-lg shadow-pink-500/20 hover:shadow-xl transition-all duration-300 flex items-center"
            onClick={handleUploadClick}
          >
            <Upload size={18} className="mr-2" />
            Subir
          </button>
        </div>
      ) : (
        <div className="p-1">
          <div className="grid grid-cols-3 gap-1">
            {userVideos.map((video) => (
              <div key={video.id} className="aspect-[9/16] relative group">
                <video
                  src={video.url}
                  className="w-full h-full object-cover bg-gray-900"
                  poster={`https://placehold.co/400x720/333/444?text=Video`}
                />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300 flex items-end p-2">
                  <p className="text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300 line-clamp-1">
                    {video.descripcion || "Sin descripción"}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="fixed bottom-4 right-4">
            <button
              className="bg-gradient-to-r from-pink-500 to-purple-600 text-white p-3 rounded-full shadow-lg shadow-pink-500/30 hover:shadow-xl transition-all duration-300"
              onClick={handleUploadClick}
            >
              <Upload size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
