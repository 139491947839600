import React, { useState, useRef, useEffect } from "react";
import {
  Upload,
  X,
  Check,
  Loader2,
  ArrowRight,
  Film,
  Hash,
  Music,
  Smile,
  Clock,
  AtSign,
  ChevronLeft,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import useUserData from "../../Hooks/useUserData";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getDatabase, ref as dbRef, set, push } from "firebase/database";
import NavShorts from "./NavShorts";

export default function UploadPage() {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [description, setDescription] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [inputHashtag, setInputHashtag] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userId, setUserId] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // 1: Upload, 2: Edit, 3: Publish
  const fileInputRef = useRef(null);
  const hashtagInputRef = useRef(null);
  const navigate = useNavigate();

  // Popular hashtags suggestion
  const suggestedHashtags = [
    "viral",
    "parati",
    "foryou",
    "trend",
    "challenge",
    "music",
    "dance",
    "funny",
  ];

  // Emojis para el selector rápido
  const quickEmojis = ["😂", "❤️", "🔥", "✨", "🥰", "😍", "👀", "🎵"];

  // Obtenemos los datos del usuario
  const { userData, loading, error } = useUserData();

  // Obtener el UUID del usuario desde localStorage
  useEffect(() => {
    try {
      const userUUID = localStorage.getItem("userUUID");
      if (userUUID) {
        setUserId(userUUID);
      } else {
        setUploadError("No se encontró el ID de usuario en localStorage");
      }
    } catch (error) {
      console.error("Error al obtener el UUID del usuario:", error);
      setUploadError("Error al obtener el ID de usuario");
    }
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Validar que sea un archivo de video
    if (!selectedFile || !selectedFile.type.startsWith("video/")) {
      setUploadError("Por favor selecciona un archivo de video válido");
      return;
    }

    // Validar duración del video (máximo 60 segundos)
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      if (video.duration > 60) {
        setUploadError("El video no puede durar más de 60 segundos");
        setFile(null);
        setFilePreview(null);
        return;
      }

      // Si todo es válido, avanzar al siguiente paso
      setCurrentStep(2);
    };
    video.src = URL.createObjectURL(selectedFile);

    // Guardar el archivo y crear preview
    setFile(selectedFile);
    setFilePreview(URL.createObjectURL(selectedFile));
    setUploadError(null);
  };

  const handleAddHashtag = () => {
    if (inputHashtag.trim() !== "" && !hashtags.includes(inputHashtag.trim())) {
      setHashtags([...hashtags, inputHashtag.trim()]);
      setInputHashtag("");
      if (hashtagInputRef.current) {
        hashtagInputRef.current.focus();
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleAddHashtag();
    }
  };

  const handleRemoveHashtag = (tag) => {
    setHashtags(hashtags.filter((t) => t !== tag));
  };

  const handleAddSuggestedHashtag = (tag) => {
    if (!hashtags.includes(tag)) {
      setHashtags([...hashtags, tag]);
    }
  };

  const handleAddEmoji = (emoji) => {
    setDescription((prev) => prev + emoji);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const goToPublishStep = () => {
    if (description.trim() === "" && hashtags.length === 0) {
      setUploadError("Agrega una descripción o hashtags antes de continuar");
      return;
    }
    setCurrentStep(3);
  };

  const handleSubmit = async () => {
    if (!file) {
      setUploadError("Por favor selecciona un video");
      return;
    }

    if (!userId) {
      setUploadError("No se pudo obtener la información del usuario");
      return;
    }

    setUploading(true);
    setUploadError(null);

    try {
      // Configurar Firebase Storage
      const storage = getStorage();
      const fileName = `${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `shorts/${fileName}`);

      // Iniciar la subida
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Escuchar eventos de progreso
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
        },
        (error) => {
          setUploadError("Error al subir el video: " + error.message);
          setUploading(false);
        },
        async () => {
          // Subida completada, obtener URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Preparar datos completos con hashtags
          const fullDescription =
            description +
            (hashtags.length > 0
              ? " " + hashtags.map((tag) => `#${tag}`).join(" ")
              : "");

          // Guardar en la base de datos
          const database = getDatabase();
          const userShortsRef = dbRef(database, `usuarios/${userId}/shorts`);
          const newShortRef = push(userShortsRef);
          const shortId = newShortRef.key;

          await set(newShortRef, {
            id: shortId,
            url: downloadURL,
            descripcion: fullDescription,
            hashtags: hashtags,
            fechaSubida: new Date().toISOString(),
            nombreArchivo: fileName,
            isPrivate: isPrivate,
          });

          setUploadComplete(true);
          setUploading(false);

          // Resetear el formulario después de 3 segundos y redirigir
          setTimeout(() => {
            navigate("/profile");
          }, 3000);
        }
      );
    } catch (error) {
      setUploadError("Error: " + error.message);
      setUploading(false);
    }
  };

  const resetForm = () => {
    setFile(null);
    setFilePreview(null);
    setDescription("");
    setHashtags([]);
    setInputHashtag("");
    setUploadError(null);
    setCurrentStep(1);
  };

  // Mostrar spinner mientras verifica el ID del usuario
  if (!userId && !uploadError) {
    return (
      <div className="h-screen w-full flex items-center justify-center text-white bg-black">
        <div className="p-6 rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-sm">
          <Loader2 className="animate-spin h-10 w-10 text-pink-500 mx-auto" />
          <p className="mt-3 text-center">Cargando...</p>
        </div>
      </div>
    );
  }

  // Paso 1: Subir video - Versión móvil
  if (currentStep === 1) {
    return (
      <div className="flex flex-col min-h-screen bg-black text-white">
        {/* Encabezado fijo */}
        <div className="px-4 py-3 border-b border-gray-800 flex items-center">
          <button onClick={() => navigate(-1)} className="mr-4">
            <ChevronLeft size={24} />
          </button>
          <h1 className="text-lg font-bold">Crear</h1>
        </div>

        <div className="flex-1 flex flex-col items-center justify-center p-4">
          <div className="w-full max-w-sm">
            {/* Círculo animado */}
            <div className="flex justify-center mb-8">
              <div className="w-20 h-20 bg-gradient-to-br from-pink-500 to-purple-600 rounded-full flex items-center justify-center shadow-lg animate-pulse">
                <Upload size={32} className="text-white" />
              </div>
            </div>

            {/* Instrucciones */}
            <div className="text-center mb-8">
              <h2 className="text-2xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-pink-400 to-purple-500">
                Sube tu video
              </h2>
              <p className="text-gray-400 text-sm">
                Comparte tu creatividad con el mundo
              </p>
            </div>

            {/* Botón de upload */}
            <input
              type="file"
              accept="video/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileChange}
            />

            <div className="bg-gray-900 rounded-xl p-5 border border-gray-800">
              <div
                className="border-2 border-dashed border-gray-700 rounded-xl p-6 text-center cursor-pointer hover:border-pink-500 transition-colors"
                onClick={() => fileInputRef.current.click()}
              >
                <Film size={40} className="mx-auto text-pink-400 mb-3" />
                <p className="font-medium mb-2 text-sm">Selecciona un video</p>
                <p className="text-gray-500 text-xs mb-4">Máximo 60 segundos</p>
                <button className="bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 text-white font-medium py-2 px-4 rounded-full transition-all duration-300 shadow-lg shadow-pink-500/20 flex items-center justify-center mx-auto text-sm">
                  <Upload size={16} className="mr-2" />
                  Seleccionar
                </button>
              </div>
            </div>

            {uploadError && (
              <div className="mt-4 bg-red-500 bg-opacity-20 border border-red-500 rounded-lg p-3 text-red-200">
                <p className="text-sm">{uploadError}</p>
              </div>
            )}
          </div>
        </div>

        <NavShorts />
      </div>
    );
  }

  // Paso 2: Editar descripción y hashtags - Versión móvil
  if (currentStep === 2) {
    return (
      <div className="flex flex-col min-h-screen bg-black text-white">
        {/* Cabecera fija */}
        <div className="px-4 py-3 border-b border-gray-800 flex justify-between items-center">
          <button onClick={resetForm} className="text-gray-400">
            <X size={22} />
          </button>
          <h2 className="text-base font-bold">Editar video</h2>
          <button
            onClick={goToPublishStep}
            className="text-pink-500 font-medium text-sm"
          >
            Siguiente
          </button>
        </div>

        <div className="flex-1 overflow-y-auto pb-16">
          {/* Preview del video */}
          <div className="p-4">
            <div className="relative w-full h-64 rounded-lg overflow-hidden border border-gray-800 mx-auto">
              <video
                src={filePreview}
                className="w-full h-full object-cover"
                autoPlay
                loop
                muted
                playsInline
              />
              <div className="absolute bottom-2 right-2 bg-black bg-opacity-70 rounded-full p-1">
                <Clock size={14} className="text-white" />
              </div>
            </div>
          </div>

          {/* Área de descripción */}
          <div className="px-4 mb-4">
            <div className="bg-gray-900 border border-gray-800 rounded-xl p-4">
              <textarea
                className="w-full bg-transparent text-white placeholder-gray-500 resize-none focus:outline-none min-h-24 text-base"
                placeholder="Describe tu video..."
                value={description}
                onChange={handleDescriptionChange}
                maxLength={2200}
              />

              <div className="flex justify-between items-center mt-1">
                {/* Contador de caracteres */}
                <div className="text-gray-500 text-xs">
                  {description.length}/2200
                </div>

                {/* Selector rápido de emojis */}
                <div className="flex space-x-1">
                  {quickEmojis.slice(0, 5).map((emoji, index) => (
                    <button
                      key={index}
                      onClick={() => handleAddEmoji(emoji)}
                      className="w-7 h-7 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700"
                    >
                      {emoji}
                    </button>
                  ))}
                  <button
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    className="w-7 h-7 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700"
                  >
                    <Smile size={14} />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Sistema de hashtags */}
          <div className="px-4 mb-4">
            <div className="bg-gray-900 border border-gray-800 rounded-xl p-4">
              <div className="flex items-center space-x-2 mb-3">
                <Hash size={16} className="text-pink-500" />
                <h3 className="text-white text-sm font-medium">Hashtags</h3>
              </div>

              {/* Hashtags actuales */}
              <div className="flex flex-wrap gap-2 mb-3">
                {hashtags.map((tag, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gray-800 px-2 py-1 rounded-full"
                  >
                    <span className="text-pink-500 mr-1 text-sm">#</span>
                    <span className="text-xs">{tag}</span>
                    <button
                      onClick={() => handleRemoveHashtag(tag)}
                      className="ml-1 text-gray-400 hover:text-white"
                    >
                      <X size={12} />
                    </button>
                  </div>
                ))}
              </div>

              {/* Input para nuevos hashtags */}
              <div className="flex items-center mb-3">
                <div className="flex-1 bg-gray-800 rounded-full px-3 py-2 flex items-center">
                  <span className="text-pink-500 mr-1">#</span>
                  <input
                    type="text"
                    ref={hashtagInputRef}
                    className="flex-1 bg-transparent border-none outline-none text-white placeholder-gray-500 text-sm"
                    placeholder="Añade un hashtag"
                    value={inputHashtag}
                    onChange={(e) => setInputHashtag(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <button
                  onClick={handleAddHashtag}
                  className="ml-2 bg-pink-500 rounded-full p-1.5 hover:bg-pink-600 transition-colors"
                >
                  <ArrowRight size={14} />
                </button>
              </div>

              {/* Hashtags sugeridos */}
              <div>
                <h4 className="text-gray-400 text-xs mb-2">TENDENCIAS</h4>
                <div className="flex flex-wrap gap-2">
                  {suggestedHashtags.map((tag, index) => (
                    <button
                      key={index}
                      onClick={() => handleAddSuggestedHashtag(tag)}
                      className="bg-gray-800 hover:bg-gray-700 px-2 py-1 rounded-full text-xs transition-colors"
                    >
                      <span className="text-pink-500">#</span>
                      {tag}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Acciones adicionales */}
          {/* <div className="px-4 space-y-3">
            <div className="bg-gray-900 border border-gray-800 rounded-xl p-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Music size={16} className="text-pink-500 mr-2" />
                  <span className="text-sm">Añadir música</span>
                </div>
                <ArrowRight size={14} className="text-gray-400" />
              </div>
            </div>

            <div className="bg-gray-900 border border-gray-800 rounded-xl p-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <AtSign size={16} className="text-pink-500 mr-2" />
                  <span className="text-sm">Etiquetar amigos</span>
                </div>
                <ArrowRight size={14} className="text-gray-400" />
              </div>
            </div>
          </div> */}
        </div>
        <NavShorts />
      </div>
    );
  }

  // Paso 3: Publicar - Versión móvil
  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      {/* Cabecera fija */}
      <div className="px-4 py-3 border-b border-gray-800 flex justify-between items-center">
        <button onClick={() => setCurrentStep(2)} className="text-gray-400">
          <ChevronLeft size={22} />
        </button>
        <h2 className="text-base font-bold">Publicar</h2>
        <div className="w-6"></div> {/* Espacio vacío para equilibrar */}
      </div>

      <div className="flex-1 overflow-y-auto p-4">
        {/* Tarjeta de publicación */}
        <div className="bg-gray-900 border border-gray-800 rounded-xl p-4 mb-4">
          <div className="flex space-x-3">
            {/* Miniatura del video */}
            <div className="w-20 h-28 bg-gray-800 rounded-lg overflow-hidden">
              <video
                src={filePreview}
                className="w-full h-full object-cover"
                muted
              />
            </div>

            {/* Descripción e información */}
            <div className="flex-1">
              <h3 className="font-medium text-sm mb-2">Tu video está listo</h3>

              <div className="text-gray-400 text-xs mb-2 line-clamp-2">
                {description}
                {hashtags.length > 0 && (
                  <span className="text-pink-500">
                    {" "}
                    {hashtags.map((tag) => `#${tag}`).join(" ")}
                  </span>
                )}
              </div>

              <div className="flex items-center text-xs text-gray-500">
                <Clock size={12} className="mr-1" />
                {new Date().toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>

        {/* Opciones de privacidad */}
        <div className="bg-gray-900 border border-gray-800 rounded-xl p-4 mb-4">
          <h3 className="font-medium text-sm mb-3">
            ¿Quién puede ver este video?
          </h3>

          <div className="space-y-2">
            <div
              className={`flex items-center justify-between p-2 rounded-lg cursor-pointer ${
                !isPrivate
                  ? "bg-gray-800 border border-pink-500"
                  : "bg-gray-800 border border-transparent"
              }`}
              onClick={() => setIsPrivate(false)}
            >
              <div className="flex items-center">
                <div
                  className={`w-3 h-3 rounded-full ${
                    !isPrivate ? "bg-pink-500" : "border border-gray-500"
                  } mr-2`}
                ></div>
                <div>
                  <div className="font-medium text-sm">Público</div>
                  <div className="text-xs text-gray-400">
                    Visible para todos
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`flex items-center justify-between p-2 rounded-lg cursor-pointer ${
                isPrivate
                  ? "bg-gray-800 border border-pink-500"
                  : "bg-gray-800 border border-transparent"
              }`}
              onClick={() => setIsPrivate(true)}
            >
              <div className="flex items-center">
                <div
                  className={`w-3 h-3 rounded-full ${
                    isPrivate ? "bg-pink-500" : "border border-gray-500"
                  } mr-2`}
                ></div>
                <div>
                  <div className="font-medium text-sm">Solo yo</div>
                  <div className="text-xs text-gray-400">
                    Solo tú puedes verlo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mensajes de error */}
        {uploadError && (
          <div className="bg-red-500 bg-opacity-20 border border-red-500 rounded-lg p-3 text-red-200 mb-4">
            <p className="text-xs">{uploadError}</p>
          </div>
        )}

        {/* Botón de publicar */}
        {uploading ? (
          <div className="space-y-2">
            <div className="w-full bg-gray-800 rounded-full h-2 overflow-hidden">
              <div
                className="h-full rounded-full bg-gradient-to-r from-pink-500 to-purple-600 transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-center text-xs text-gray-300">
              Subiendo: {uploadProgress}%
            </p>
          </div>
        ) : uploadComplete ? (
          <button className="w-full bg-gradient-to-r from-green-500 to-emerald-600 text-white font-bold py-3 px-4 rounded-full flex items-center justify-center shadow-lg shadow-green-500/20 transition-all duration-300 hover:shadow-xl text-sm">
            <Check size={18} className="mr-2" />
            ¡Publicado con éxito!
          </button>
        ) : (
          <button
            className="w-full bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-full flex items-center justify-center shadow-lg shadow-pink-500/20 transition-all duration-300 hover:shadow-xl text-sm"
            onClick={handleSubmit}
          >
            Publicar ahora
            <ArrowRight size={16} className="ml-2" />
          </button>
        )}

        {uploadComplete && (
          <p className="text-center text-xs text-gray-300 mt-3 animate-pulse">
            Redirigiendo a tus videos...
          </p>
        )}
      </div>
      <NavShorts />
    </div>
  );
}
