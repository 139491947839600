import { MessageCircle, Users } from "lucide-react";
import React from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function NavShorts() {
  return (
    <div>
      {/* Bottom Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-black z-40">
        <div className="h-px w-full bg-gradient-to-r from-transparent via-gray-600 to-transparent"></div>
        <div className="flex justify-around items-center h-16">
          <Link to="/shorts" className="block text-center">
            <button className="text-white font-medium flex flex-col items-center opacity-100">
              <svg
                className="w-6 h-6 mb-1"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4,5V18H21V5H4M14,18V15H10V18H7V9H17V18H14M6,7H19V11H6V7Z"></path>
              </svg>
              <span className="text-xs">Para ti</span>
            </button>
          </Link>

          <Link to="/following" className="block text-center">
            <button className="text-gray-500 hover:text-white flex flex-col items-center">
              <Users className="w-5 h-5 mb-1 mx-auto" />
              <span className="text-xs">Siguiendo</span>
            </button>
          </Link>

          <Link to="/upload" className="block text-center">
            <button className="transform scale-125 bg-gradient-to-tr from-pink-500 to-purple-600 rounded-md p-2 -mt-6 shadow-lg">
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
            </button>
          </Link>

          <Link to="/inbox" className="block text-center">
            <button className="text-gray-500 hover:text-white flex flex-col items-center">
              <MessageCircle size={22} className="mb-1" />
              <span className="text-xs">Bandeja</span>
            </button>
          </Link>

          <Link to="/profile" className="block text-center">
            <button className="text-gray-500 hover:text-white flex flex-col items-center">
              <FaRegUserCircle className="w-5 h-5 mb-1 mx-auto" />
              <span className="text-xs">Perfil</span>
            </button>
          </Link>
        </div>
      </nav>
    </div>
  );
}
