import React, { useState, lazy, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Menu,
  Home as HomeIcon,
  User,
  HelpCircle,
  Gem,
  MessageCircle,
  FileClock,
  DollarSign,
  Wallet,
  ChevronDown,
  Dice1Icon,
  Joystick,
  Store,
  LogOut,
  Radio,
  Video,
  Bell,
  Search,
  InfoIcon,
} from "lucide-react";
import { MdContacts } from "react-icons/md";
import { FaBlog } from "react-icons/fa";

// Import the non-lazy loaded components first
import useUserData from "../../Hooks/useUserData";
import MessageAlerts from "../../Utils/MessageAlerts";
import VipPhoneModal from "../Vip/VipPhoneModal";
import AddToHomeBanner from "../../Config/AddToHomeBanner";
import VipGrupoWhatsApp from "../Vip/VipGrupoWhatsApp";
import HomeShort from "../Shorts/HomeShort";
import NotificationModal from "../../Components/NotificationModal";
import DeportivosProApp from "../../info/DeportivosProApp";
// Lazy loaded components for better performance
const FootballLoading = lazy(() => import("../../Utils/FootballLoading"));
const UserProfile = lazy(() => import("../Profile/UserProfile"));
const SupportChat = lazy(() => import("../Chat/Chat"));
const BetFree = lazy(() => import("../Free/BetFree"));
const InviteGrupo = lazy(() => import("../World/InviteGrupo"));
const BetHistory = lazy(() => import("../Free/BetHistory"));
const ContactForm = lazy(() => import("../Contact/ContactForm"));
const MessageVip = lazy(() => import("../../Components/MessageVip"));
const Marketing = lazy(() => import("../Marketing/Marketing"));
const AppAnality = lazy(() => import("../../Config/AppAnality"));
const CircularProgressReward = lazy(() =>
  import("../../Utils/CircularProgressReward")
);
const WalletView = lazy(() => import("../Wallet/WalletView"));
const HomeVip = lazy(() => import("../Vip/HomeVip"));
const HomeCourse = lazy(() => import("../media/HomeCourse"));
const CasinoPage = lazy(() => import("../casino/CasinoPage"));
const GameOne = lazy(() => import("../casino/games/GameOne"));

const LoadingFallback = () => (
  <div className="flex items-center justify-center h-full min-h-[200px]">
    <div className="animate-pulse flex space-x-2">
      <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
      <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
      <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
    </div>
  </div>
);

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentView, setCurrentView] = useState("home");
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const { userData, loading, error } = useUserData();
  const navigate = useNavigate();

  // Handle external navigation
  const handleExternalNavigation = (url) => {
    window.location.href = url;
  };

  // Handle logout
  const handleLogout = () => {
    // Implement logout logic
    alert("Sesión cerrada correctamente");
    navigate("/login");
  };

  if (loading) return <FootballLoading />;
  if (error)
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 to-gray-800">
        <div className="bg-gray-800/80 backdrop-blur-sm p-6 rounded-lg shadow-xl border border-gray-700 max-w-md w-full">
          <div className="text-red-500 text-xl mb-4">Error al cargar datos</div>
          <p className="text-gray-300 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium px-4 py-2 rounded-md hover:brightness-110 transition-all w-full"
          >
            Reintentar
          </button>
        </div>
      </div>
    );

  const MenuItem = ({ icon: Icon, text, view, badge, subItems }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleClick = () => {
      if (subItems) {
        setIsSubMenuOpen(!isSubMenuOpen);
      } else if (view === "store") {
        handleExternalNavigation("/store");
      } else if (view === "blogs") {
        handleExternalNavigation("https://deportivosblogs.online");
      } else if (view === "dp-shorts") {
        handleExternalNavigation("/shorts");
      } else {
        setCurrentView(view);
        setIsMenuOpen(false);
      }
    };

    return (
      <li>
        <div
          className={`flex items-center justify-between rounded-md transition-all duration-200 cursor-pointer hover:bg-gray-700/50 group ${
            currentView === view ? "bg-gray-700/70" : ""
          }`}
          onClick={handleClick}
        >
          <div className="flex items-center w-full p-2">
            <Icon
              className={`w-5 h-5 ${
                currentView === view ? "text-blue-400" : "text-gray-400"
              } group-hover:text-white transition-colors`}
            />
            <span
              className={`ml-3 ${
                currentView === view
                  ? "text-white font-semibold"
                  : "text-gray-300"
              } group-hover:text-white font-medium`}
            >
              {text}
            </span>
            {badge && <div className="ml-auto">{badge}</div>}
            {subItems && (
              <ChevronDown
                className={`w-4 h-4 ml-2 text-gray-400 transition-transform ${
                  isSubMenuOpen ? "rotate-180" : ""
                }`}
              />
            )}
          </div>
        </div>

        {isSubMenuOpen && subItems && (
          <ul
            className="pl-6 mt-1 space-y-1 overflow-hidden"
            style={{ animation: "fadeIn 0.3s" }}
          >
            {subItems.map((subItem, index) => (
              <li
                key={index}
                className={`flex items-center p-2 rounded-md cursor-pointer hover:bg-gray-700/50 transition-colors ${
                  currentView === subItem.view ? "bg-gray-700/70" : ""
                }`}
                onClick={() => {
                  setCurrentView(subItem.view);
                  setIsMenuOpen(false);
                }}
                style={{
                  animation: "slideIn 0.3s",
                  animationDelay: `${index * 0.05}s`,
                }}
              >
                <subItem.icon
                  className={`w-4 h-4 ${
                    currentView === subItem.view
                      ? "text-blue-400"
                      : "text-gray-400"
                  }`}
                />
                <span
                  className={`ml-3 ${
                    currentView === subItem.view
                      ? "text-white"
                      : "text-gray-300"
                  }`}
                >
                  {subItem.text}
                </span>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  const renderContent = () => {
    return (
      <Suspense fallback={<LoadingFallback />}>
        {(() => {
          switch (currentView) {
            case "profile":
              return <UserProfile />;
            case "help":
              return <SupportChat />;
            case "bet":
              return <BetFree limit={userData ? undefined : 3} />;
            case "vip":
              return <HomeVip />;
            case "whatsapp":
              return <InviteGrupo />;
            case "history":
              return <BetHistory />;
            case "contact":
              return <ContactForm />;
            case "analitys":
              return <AppAnality />;
            case "win-pay":
              return <Marketing />;
            case "wallet":
              return <WalletView />;
            case "homeCasino":
              return <CasinoPage />;
            case "DeportivosProApp":
              return <DeportivosProApp />;
            case "depbonus":
              return <GameOne />;
            default:
              return (
                <>
                  <BetFree limit={userData ? undefined : 3} />
                  {!userData && (
                    <div
                      className="fixed bottom-0 left-0 w-full bg-gray-800/95 backdrop-blur-sm shadow-lg flex flex-col items-center z-40 border-t border-gray-700"
                      style={{ animation: "slideUpFade 0.5s" }}
                    >
                      <div className="w-full max-w-lg mx-auto py-3 px-4">
                        <p className="text-gray-300 mb-3 text-base font-medium text-center">
                          Inicie sesión o cree una cuenta para ver más
                          pronósticos
                        </p>
                        <div className="flex justify-center gap-3">
                          <Link to="/login">
                            <button className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium px-5 py-2 rounded-md hover:brightness-110 transition-all shadow-lg shadow-blue-600/20">
                              Iniciar sesión
                            </button>
                          </Link>
                          <Link to="/register">
                            <button className="bg-gradient-to-r from-emerald-600 to-green-600 text-white font-medium px-5 py-2 rounded-md hover:brightness-110 transition-all shadow-lg shadow-green-600/20">
                              Crear cuenta
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
          }
        })()}
      </Suspense>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
      {/* Style for CSS animations without framer-motion */}
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes slideIn {
          from {
            transform: translateX(-10px);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }
        @keyframes slideUpFade {
          from {
            transform: translateY(50px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        @keyframes slideDownFade {
          from {
            transform: translateY(-10px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        .sidebar-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .sidebar-scrollbar::-webkit-scrollbar-track {
          background: rgba(31, 41, 55, 0.1);
        }
        .sidebar-scrollbar::-webkit-scrollbar-thumb {
          background: rgba(75, 85, 99, 0.5);
          border-radius: 20px;
        }
        .main-content-scrollbar::-webkit-scrollbar {
          width: 2px;
        }
        .main-content-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }
        .main-content-scrollbar::-webkit-scrollbar-thumb {
          background: rgba(75, 85, 99, 0.3);
          border-radius: 20px;
        }
      `}</style>

      {/* Navbar */}
      <nav className="fixed top-0 z-50 w-full bg-gray-800/90 backdrop-blur-md border-b border-gray-700/50 shadow-lg">
        <div className="h-16 flex items-center justify-between w-full px-2 max-w-full mx-auto">
          <div className="flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="lg:hidden p-2 text-gray-400 hover:text-white rounded-md hover:bg-gray-700/50 transition-colors"
              aria-label="Toggle menu"
            >
              <Menu className="h-5 w-5" />
            </button>
            <div className="hidden lg:flex items-center ml-2">
              <Gem className="h-6 w-6 text-indigo-400" />
              <span className="ml-2 text-white font-semibold text-lg">
                DeportivosPro
              </span>
            </div>
            <div className="ml-3 lg:ml-6">
              {/* <CircularProgressReward /> */}
            </div>
          </div>

          <div className="flex items-center gap-2">
            
            <Link
              to="/support"
              className="relative text-gray-400 hover:text-white p-2 rounded-md hover:bg-gray-700/50 transition-colors"
              aria-label="Support"
            >
              <MessageCircle className="h-5 w-5" />
              <span className="absolute -top-0.5 -right-0.5 h-4 w-4 rounded-full bg-red-500 border-2 border-gray-800 text-xs flex items-center justify-center" />
            </Link>
            <Link
              to="/shorts"
              className="relative text-gray-400 hover:text-white p-2 rounded-md hover:bg-gray-700/50 transition-colors"
              aria-label="Support"
            >
              <Video className="h-5 w-5" />
              <span className="absolute -top-0.5 -right-0.5 h-4 w-4 rounded-full bg-yellow-500 border-2 border-gray-800 text-xs flex items-center justify-center" />
            </Link>
            <a
              href="http://deportivosblogs.online/"
              target="_blank"
              rel="noopener noreferrer"
              className="relative text-gray-400 hover:text-white p-2 rounded-md hover:bg-gray-700/50 transition-colors"
              aria-label="Blog"
            >
              <FaBlog className="h-4 w-4" />
              <span className="absolute -top-0.5 -right-0.5 h-4 w-4 rounded-full bg-orange-500 border-2 border-gray-800 text-xs flex items-center justify-center" />
            </a>
            <NotificationModal />

            <div className="relative">
              <button
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                className="flex items-center ml-1"
                aria-label="User menu"
              >
                <div className="relative">
                  <img
                    src={
                      userData?.avatar ||
                      "https://avatar.iran.liara.run/public/boy"
                    }
                    alt="Profile"
                    className="h-8 w-8 rounded-full ring-2 ring-indigo-500 cursor-pointer hover:brightness-110 transition-all object-cover"
                  />
                  <div className="absolute bottom-0 right-0 h-3 w-3 rounded-full bg-emerald-500 ring-1 ring-gray-800" />
                </div>
              </button>

              {isUserMenuOpen && (
                <div
                  className="absolute right-0 top-full mt-2 w-64 bg-gray-800 rounded-md shadow-lg border border-gray-700 overflow-hidden z-50"
                  style={{ animation: "slideDownFade 0.3s" }}
                >
                  <div className="p-4 border-b border-gray-700 bg-gray-900/50">
                    <div className="flex items-center">
                      <img
                        src={
                          userData?.avatar ||
                          "https://avatar.iran.liara.run/public/boy"
                        }
                        alt="Profile"
                        className="h-12 w-12 rounded-full ring-2 ring-indigo-500"
                      />
                      <div className="ml-3">
                        <p className="text-white font-medium">
                          {userData?.name || "Usuario"}
                        </p>
                        <p className="text-gray-400 text-sm truncate">
                          {userData?.email || "usuario@ejemplo.com"}
                        </p>
                        <div className="mt-1 flex items-center">
                          <div className="h-2 w-2 rounded-full bg-emerald-500 mr-2"></div>
                          <p className="text-gray-400 text-xs">
                            {userData?.typeU || "Cuenta Gratis"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-1">
                    <button
                      className="flex items-center w-full px-4 py-2 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors"
                      onClick={() => {
                        setCurrentView("profile");
                        setIsUserMenuOpen(false);
                      }}
                    >
                      <User className="h-4 w-4 mr-3" />
                      Mi perfil
                    </button>
                    <button
                      className="flex items-center w-full px-4 py-2 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors"
                      onClick={() => {
                        setCurrentView("wallet");
                        setIsUserMenuOpen(false);
                      }}
                    >
                      <Wallet className="h-4 w-4 mr-3" />
                      Mi billetera
                    </button>
                    <button
                      className="flex items-center w-full px-4 py-2 text-red-400 hover:bg-gray-700 hover:text-red-300 transition-colors"
                      onClick={handleLogout}
                    >
                      <LogOut className="h-4 w-4 mr-3" />
                      Cerrar sesión
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Main Layout */}
      <div className="pt-16 lg:grid lg:grid-cols-[240px,1fr]">
        {/* Sidebar */}
        <aside
          className={`fixed inset-y-0 left-0 z-40 w-60 transform bg-gray-800/60 backdrop-blur-sm pt-16 transition-transform duration-300 border-r border-gray-700/50 lg:static lg:translate-x-0 ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="h-full flex flex-col">
            <div className="flex-1 overflow-y-auto sidebar-scrollbar">
              <nav className="px-2 py-3">
                <div className="px-3 py-1 mb-2">
                  <h3 className="text-xs uppercase text-gray-500 font-semibold tracking-wider">
                    Navegación principal
                  </h3>
                </div>
                <ul className="space-y-1">
                  <MenuItem icon={HomeIcon} text="Inicio" view="home" />

                  <MenuItem
                    icon={Gem}
                    text="VIP"
                    view="vip"
                    badge={
                      <span className="bg-gradient-to-r from-green-600 to-emerald-600 text-xs rounded-full px-2 py-0.5">
                        +99%🔥
                      </span>
                    }
                  />
                  <MenuItem
                    icon={Video}
                    text="Shorts"
                    view="dp-shorts"
                    badge={
                      <span className="bg-gradient-to-r from-red-600 to-amber-600 text-xs rounded-full px-2 py-0.5">
                        Beta📽
                      </span>
                    }
                  />
                  <MenuItem icon={FileClock} text="Análisis" view="analitys" />

                  <MenuItem
                    icon={DollarSign}
                    text="Gana dinero"
                    view="win-pay"
                    badge={
                      <span className="bg-gradient-to-r from-yellow-600 to-amber-600 text-xs rounded-full px-2 py-0.5">
                        🤑💲
                      </span>
                    }
                  />
                </ul>

                <div className="px-3 py-1 mt-4 mb-2">
                  <h3 className="text-xs uppercase text-gray-500 font-semibold tracking-wider">
                    Servicios
                  </h3>
                </div>
                <ul className="space-y-1">
                  <MenuItem
                    icon={Store}
                    text="Tienda"
                    view="store"
                    badge={
                      <span className="bg-gradient-to-r from-red-600 to-amber-600 text-xs rounded-full px-2 py-0.5">
                        🛒
                      </span>
                    }
                  />
                  <MenuItem icon={Wallet} text="Billetera" view="wallet" />
                  <MenuItem
                    icon={Joystick}
                    text="Casino"
                    view="casino"
                    badge={
                      <span className="bg-gradient-to-r from-green-600 to-emerald-600 text-xs rounded-full px-2 py-0.5">
                        +😎
                      </span>
                    }
                    subItems={[
                      { icon: Dice1Icon, text: "DepBonus", view: "depbonus" },
                    ]}
                  />
                  <MenuItem
                    icon={MessageCircle}
                    text="Grupo WhatsApp"
                    view="whatsapp"
                  />
                </ul>

                <div className="px-3 py-1 mt-4 mb-2">
                  <h3 className="text-xs uppercase text-gray-500 font-semibold tracking-wider">
                    Cuenta
                  </h3>
                </div>
                <ul className="space-y-1">
                  <MenuItem icon={User} text="Perfil" view="profile" />
                  <MenuItem
                    icon={MdContacts}
                    text="Contacto"
                    view="contact"
                    badge={
                      <span className="bg-gradient-to-r from-orange-600 to-amber-600 text-xs rounded-full px-2 py-0.5">
                        Nuevo
                      </span>
                    }
                  />
                  <MenuItem
                    icon={HelpCircle}
                    text="Mensajes"
                    view="help"
                    badge={<span className="text-xs text-gray-400">FAQ</span>}
                  />
                  {/* <MenuItem
                    icon={InfoIcon}
                    text="DeportivosPro"
                    view="DeportivosProApp"
                    badge={<span className="text-xs text-gray-400">Info</span>}
                  /> */}
                </ul>
              </nav>
            </div>
            <div className="p-3 border-t border-gray-700/50">
              <MessageVip />
            </div>
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1 overflow-y-auto bg-transparent px-0 py-1 lg:px-1 main-content-scrollbar">
          <div className="w-full">{renderContent()}</div>
        </main>
      </div>

      {/* Mobile Overlay */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 z-30 bg-gray-900/80 backdrop-blur-sm lg:hidden"
          onClick={() => setIsMenuOpen(false)}
          style={{ animation: "fadeIn 0.2s" }}
        />
      )}

      <VipGrupoWhatsApp />
      <AddToHomeBanner />
      <VipPhoneModal />
      <MessageAlerts />
    </div>
  );
};

export default Home;
