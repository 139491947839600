import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";

export default function Recharge() {
  const [coinCount, setCoinCount] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");
  const COIN_VALUE = 30;

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setUserEmail(user.email);
      setUserId(user.uid);
    } else {
      console.error("No hay ningún usuario logueado.");
    }
  }, []);

  const handleCoinChange = (e) => {
    const count = parseInt(e.target.value) || 0;
    setCoinCount(count);
  };

  const handleRecharge = () => {
    const totalAmount = coinCount * COIN_VALUE;
    const formattedAmount = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(totalAmount);

    const message =
      `🎮 Solicitud de Recarga de Monedas 🎮\n\n` +
      `📌 Detalles de la recarga:\n` +
      `• Cantidad de monedas: ${coinCount}\n` +
      `• Valor total: ${formattedAmount}\n\n` +
      `👤 Información del usuario:\n` +
      `• ID: ${userId}\n` +
      `• Email: ${userEmail}`;

    const whatsappUrl = `https://wa.me/573023580862?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const totalAmount = coinCount * COIN_VALUE;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-950 to-gray-500 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden transform transition-all hover:scale-105">
        <div className="bg-blue-600 text-white p-6 text-center">
          <h2 className="text-2xl font-bold tracking-wide">
            Recarga de Monedas
          </h2>
        </div>

        <div className="p-6 space-y-6">
          <div>
            <label
              htmlFor="coinInput"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Número de Monedas
            </label>
            <div className="relative">
              <input
                id="coinInput"
                type="number"
                min="0"
                value={coinCount}
                onChange={handleCoinChange}
                className="w-full px-4 py-3 border-2 text-black border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                placeholder="Ingrese cantidad de monedas"
              />
            </div>
          </div>

          <div className="p-4 border rounded-lg bg-gray-50 space-y-2">
            <h3 className="text-lg font-bold text-gray-800">
              Resumen de Recarga
            </h3>
            <p className="text-sm text-gray-600">
              <strong>Email:</strong> {userEmail}
            </p>
            <p className="text-sm text-gray-600">
              <strong>ID Usuario:</strong> {userId}
            </p>
            <p className="text-sm text-gray-600">
              <strong>Cantidad de Monedas:</strong> {coinCount}
            </p>
            <p className="text-sm text-gray-600">
              <strong>Precio Total:</strong>{" "}
              {new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
              }).format(totalAmount)}
            </p>
          </div>

          <button
            onClick={handleRecharge}
            disabled={coinCount === 0}
            className={`w-full py-3 rounded-lg transition-all duration-300 ${
              coinCount === 0
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-green-500 text-white hover:bg-green-600 active:bg-green-700"
            }`}
          >
            Enviar Solicitud por WhatsApp
          </button>
        </div>

        <div className="bg-gray-100 p-4 text-center text-sm text-gray-500">
          Cada moneda vale {COIN_VALUE} COP
        </div>
      </div>
    </div>
  );
}
