import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../Config/firebase";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import NavShorts from "./NavShorts";

export default function FollowingPage() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("sugeridos");
  const navigate = useNavigate();

  useEffect(() => {
    // Referencia al nodo 'usuarios' en Firebase Realtime Database
    const usersRef = ref(database, "usuarios");

    // Escuchar cambios en tiempo real
    const unsubscribe = onValue(
      usersRef,
      (snapshot) => {
        setLoading(true);

        if (snapshot.exists()) {
          const usersData = snapshot.val();
          const usersArray = Object.keys(usersData).map((key) => ({
            id: key,
            ...usersData[key],
            // Añadimos propiedades aleatorias para hacer más dinámico el contenido
            engagement: Math.floor(Math.random() * 100),
            lastActive: Math.floor(Math.random() * 60), // minutos
            isSuggested: Math.random() > 0.5, // 50% serán sugeridos
          }));

          // Ordenamos aleatoriamente la lista
          const shuffledUsers = [...usersArray].sort(() => Math.random() - 0.5);
          setUsers(shuffledUsers);
        } else {
          setUsers([]);
        }

        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );

    // Limpiar el listener cuando el componente se desmonte
    return () => unsubscribe();
  }, []);

  // Filtrar usuarios basados en la búsqueda y el tab activo
  const filteredUsers = users.filter((user) => {
    const searchMatch =
      !searchTerm ||
      (user.name || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.username || "").toLowerCase().includes(searchTerm.toLowerCase());

    // Aplicar filtro según la pestaña activa
    if (activeTab === "sugeridos") {
      return searchMatch && user.isSuggested;
    }

    // Si estamos en la pestaña "todos", mostramos todos los que coinciden con la búsqueda
    return searchMatch;
  });

  const handleChatClick = (userId) => {
    // Navegar a la página de chat con el usuario seleccionado
    navigate(`/inbox/${userId}`);
  };

  const handleProfileClick = (userId) => {
    // Navegar al perfil del usuario
    navigate(`/profile-view/${userId}`);
  };

  // Componente del banner con animaciones
  const AnimatedBanner = () => (
    <motion.div
      className="relative w-full h-40 mb-4 overflow-hidden rounded-xl bg-gradient-to-r from-purple-900 via-pink-800 to-indigo-900"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Elementos visuales animados */}
      <motion.div
        className="absolute top-5 left-10 w-20 h-20 rounded-full bg-pink-500/20 blur-xl"
        animate={{
          x: [0, 20, 0],
          scale: [1, 1.2, 1],
        }}
        transition={{
          repeat: Infinity,
          duration: 8,
          ease: "easeInOut",
        }}
      />
      <motion.div
        className="absolute bottom-10 right-20 w-32 h-32 rounded-full bg-purple-500/20 blur-xl"
        animate={{
          y: [0, -15, 0],
          scale: [1, 1.3, 1],
        }}
        transition={{
          repeat: Infinity,
          duration: 6,
          ease: "easeInOut",
        }}
      />

      <div className="relative z-10 h-full flex flex-col items-center justify-center p-5 text-center">
        <motion.h1
          className="text-2xl sm:text-3xl font-bold text-white mb-2"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Descubre Nuevas Conexiones
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base text-white/80 max-w-md"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          Encuentra personas con intereses similares y expande tu red social
        </motion.p>

        <motion.div
          className="flex space-x-2 mt-4"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.4, duration: 0.3 }}
        >
          {[1, 2, 3, 4, 5].map((_, index) => (
            <motion.div
              key={index}
              className="w-8 h-8 rounded-full bg-white/90 overflow-hidden border-2 border-white"
              initial={{ x: -10, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.4 + index * 0.1, duration: 0.3 }}
            >
              <motion.div
                className="w-full h-full bg-gradient-to-br from-pink-500 to-purple-600"
                whileHover={{ scale: 1.1 }}
              />
            </motion.div>
          ))}
          <motion.div
            className="w-8 h-8 rounded-full bg-pink-600 flex items-center justify-center text-white text-xs font-bold"
            initial={{ x: -10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.9, duration: 0.3 }}
          >
            +99
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );

  if (loading && users.length === 0) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center text-white p-4">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 relative">
            <motion.div
              className="absolute top-0 w-6 h-6 left-0 bg-pink-500 rounded-full"
              animate={{
                scale: [1, 1.5, 1],
                opacity: [0.7, 0.2, 0.7],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.div
              className="absolute top-8 w-4 h-4 left-8 bg-purple-500 rounded-full"
              animate={{
                scale: [1, 1.5, 1],
                opacity: [0.7, 0.2, 0.7],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0.5,
              }}
            />
            <motion.div
              className="absolute top-4 w-5 h-5 left-4 bg-blue-500 rounded-full"
              animate={{
                scale: [1, 1.5, 1],
                opacity: [0.7, 0.2, 0.7],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 1,
              }}
            />
          </div>
          <motion.p
            className="mt-4 text-lg"
            animate={{
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            Descubriendo personas...
          </motion.p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center text-white p-4">
        <motion.div
          className="bg-red-900/50 p-6 rounded-xl max-w-md text-center shadow-lg"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.svg
            animate={{
              rotate: [0, 5, -5, 0],
            }}
            transition={{
              duration: 0.5,
              repeat: 3,
              ease: "easeInOut",
              delay: 0.2,
            }}
            className="w-12 h-12 text-red-400 mx-auto mb-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </motion.svg>
          <p className="text-xl mb-2">Ups, algo salió mal</p>
          <p className="text-gray-300">Error al cargar usuarios: {error}</p>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Header con buscador */}
      <motion.div
        className="sticky top-0 bg-black/90 backdrop-blur-sm z-10 pt-4 pb-2 px-4 border-b border-gray-800"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.h2
          className="text-2xl font-bold mb-4 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.3 }}
        >
          Encuentra personas
        </motion.h2>

        <motion.div
          className="relative mb-3 max-w-md mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.3 }}
        >
          <input
            type="text"
            placeholder="Buscar usuarios..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full bg-gray-900 border border-gray-700 rounded-full py-2.5 px-5 pl-12 text-white focus:outline-none focus:ring-2 focus:ring-pink-500 text-sm transition-all duration-300 focus:border-pink-400"
          />
          <svg
            className="w-5 h-5 absolute left-4 top-2.5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </motion.div>

        {/* Tabs de navegación - Todos y Sugeridos con indicadores */}
        <motion.div
          className="flex justify-center space-x-2 text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.3 }}
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setActiveTab("todos")}
            className={`px-4 py-2 rounded-full transition-colors relative ${
              activeTab === "todos"
                ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium shadow-lg"
                : "text-gray-400 hover:text-white"
            }`}
          >
            Todos
            <motion.span
              initial={{ scale: 0 }}
              animate={{ scale: activeTab === "todos" ? 1 : 0 }}
              className="absolute -right-1 -top-1 bg-pink-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
            >
              {users.length}
            </motion.span>
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setActiveTab("sugeridos")}
            className={`px-4 py-2 rounded-full transition-colors relative ${
              activeTab === "sugeridos"
                ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium shadow-lg"
                : "text-gray-400 hover:text-white"
            }`}
          >
            Sugeridos
            <motion.span
              initial={{ scale: 0 }}
              animate={{ scale: activeTab === "sugeridos" ? 1 : 0 }}
              className="absolute -right-1 -top-1 bg-pink-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
            >
              {users.filter((user) => user.isSuggested).length}
            </motion.span>
          </motion.button>
        </motion.div>
      </motion.div>

      {/* Contenido principal */}
      <div className="max-w-2xl mx-auto py-4 px-4">
        {/* Banner Animado */}
        <AnimatedBanner />

        {/* Estadísticas animadas */}
        <motion.div
          className="flex justify-between mb-6 px-1"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.4 }}
        >
          <div className="text-center">
            <motion.p
              className="text-2xl font-bold bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.3 }}
            >
              {users.length}
            </motion.p>
            <p className="text-xs text-gray-400">Usuarios</p>
          </div>
          <div className="text-center">
            <motion.p
              className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-cyan-500 bg-clip-text text-transparent"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.3 }}
            >
              {users.filter((user) => user.isSuggested).length}
            </motion.p>
            <p className="text-xs text-gray-400">Sugeridos</p>
          </div>
          <div className="text-center">
            <motion.p
              className="text-2xl font-bold bg-gradient-to-r from-amber-400 to-orange-500 bg-clip-text text-transparent"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.3 }}
            >
              {users.filter((user) => user.verified).length}
            </motion.p>
            <p className="text-xs text-gray-400">Verificados</p>
          </div>
        </motion.div>

        {filteredUsers.length === 0 ? (
          <motion.div
            className="mt-12 flex flex-col items-center justify-center text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              className="w-20 h-20 bg-gradient-to-br from-purple-500 to-pink-500 rounded-full flex items-center justify-center mb-6"
              animate={{
                boxShadow: [
                  "0px 0px 0px rgba(236, 72, 153, 0.3)",
                  "0px 0px 20px rgba(236, 72, 153, 0.6)",
                  "0px 0px 0px rgba(236, 72, 153, 0.3)",
                ],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <svg
                className="w-10 h-10 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                ></path>
              </svg>
            </motion.div>
            <motion.h3
              className="text-xl font-bold mb-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.3 }}
            >
              No se encontraron usuarios
            </motion.h3>
            <motion.p
              className="text-gray-400 max-w-xs mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.3 }}
            >
              {searchTerm
                ? "Intenta con otra búsqueda o revisa los filtros aplicados."
                : activeTab === "sugeridos"
                ? "No hay sugerencias disponibles en este momento. Intenta ver 'Todos'."
                : "Aquí verás los usuarios disponibles. Explora y descubre nuevas personas para conectar."}
            </motion.p>
          </motion.div>
        ) : (
          <motion.div
            className="space-y-3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {filteredUsers.map((user, index) => (
              <motion.div
                key={user.id}
                className="bg-gray-900 rounded-xl p-3 flex items-center border border-gray-800 hover:border-pink-500/30 transition-all hover:bg-gray-800"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05, duration: 0.3 }}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0 4px 12px rgba(156, 39, 176, 0.15)",
                }}
              >
                {/* Avatar con indicador de actividad */}
                <div
                  className="w-14 h-14 rounded-full overflow-hidden mr-3 cursor-pointer hover:opacity-90 transition-opacity border-2 border-transparent hover:border-pink-500/50 relative"
                  onClick={() => handleProfileClick(user.id)}
                >
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3 }}
                    src={user.profileImage || "https://placehold.co/100"}
                    alt={`Perfil de ${user.name || "Usuario"}`}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.src = "https://placehold.co/100";
                    }}
                  />
                  {/* Indicador de actividad */}
                  {user.lastActive < 15 && (
                    <motion.div
                      className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-gray-900"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.2 + index * 0.05 }}
                    />
                  )}
                </div>

                {/* Información del usuario */}
                <div
                  className="flex-1 min-w-0 cursor-pointer"
                  onClick={() => handleProfileClick(user.id)}
                >
                  <div className="flex items-center">
                    <h4 className="font-bold truncate">
                      {user.name || user.username || "Usuario sin nombre"}
                    </h4>
                    {user.verified && (
                      <motion.svg
                        initial={{ scale: 0 }}
                        animate={{ scale: 1, rotate: [0, 15, 0] }}
                        transition={{
                          duration: 0.5,
                          delay: 0.1 + index * 0.05,
                        }}
                        className="w-4 h-4 text-blue-400 ml-1 flex-shrink-0"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                      </motion.svg>
                    )}
                  </div>
                  <div className="flex items-center">
                    {user.username && (
                      <p className="text-gray-400 text-sm truncate mr-2">
                        @{user.username}
                      </p>
                    )}
                    {/* Etiqueta de sugerido si aplica */}
                    {user.isSuggested && (
                      <motion.span
                        className="text-xs px-1.5 py-0.5 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-full text-pink-400 border border-pink-500/30"
                        initial={{ opacity: 0, x: 10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3 + index * 0.05 }}
                      >
                        Sugerido
                      </motion.span>
                    )}
                  </div>
                </div>

                {/* Indicador de actividad y botón de chat */}
                <div className="ml-2 flex items-center">
                  {/* Indicador de tiempo de actividad */}
                  <motion.div
                    className="mr-2 text-xs text-gray-400"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 + index * 0.05 }}
                  >
                    {user.lastActive < 15 ? (
                      <span className="text-green-400">Activo ahora</span>
                    ) : (
                      <span>{user.lastActive}m</span>
                    )}
                  </motion.div>

                  {/* Botón de chat */}
                  <motion.button
                    whileHover={{ scale: 1.1, backgroundColor: "#4B5563" }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleChatClick(user.id)}
                    className="w-10 h-10 flex-shrink-0 flex items-center justify-center rounded-full bg-gray-800 hover:bg-gray-700 transition-colors"
                    title="Enviar mensaje"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      ></path>
                    </svg>
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </div>
      <NavShorts />
      
    </div>
  );
}
