import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Bookmark,
  Loader2,
  MessageCircle,
  ArrowLeft,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref, onValue, update } from "firebase/database";

export default function UserProfileView() {
  const [userVideos, setUserVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const videoRefs = useRef({});
  const navigate = useNavigate();
  const { userId } = useParams(); // Get userId from URL params

  // Get current user's UUID from localStorage
  useEffect(() => {
    try {
      const userUUID = localStorage.getItem("userUUID");
      if (userUUID) {
        setCurrentUserId(userUUID);

        // Check if we're already following this user
        const db = getDatabase();
        const followingRef = ref(
          db,
          `usuarios/${userUUID}/following/${userId}`
        );
        onValue(followingRef, (snapshot) => {
          if (snapshot.exists()) {
            setIsFollowing(true);
          }
        });
      } else {
        console.error("No se encontró el ID de usuario en localStorage");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al obtener el UUID del usuario:", error);
    }
  }, [userId, navigate]);

  // Get profile user data and videos
  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    const db = getDatabase();

    // Get profile data
    const userRef = ref(db, `usuarios/${userId}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData(data);
      } else {
        // User not found
        console.error("Usuario no encontrado");
        navigate("/not-found");
      }
    });

    // Get user videos
    const shortsRef = ref(db, `usuarios/${userId}/shorts`);
    onValue(shortsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert object to array and sort by date (most recent first)
        const videosArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        videosArray.sort(
          (a, b) => new Date(b.fechaSubida) - new Date(a.fechaSubida)
        );

        setUserVideos(videosArray);
      } else {
        setUserVideos([]);
      }
      setLoading(false);
    });
  }, [userId, navigate]);

  // Start autoplay when entering viewport
  useEffect(() => {
    if (!userVideos.length) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const videoId = entry.target.dataset.videoId;
          if (entry.isIntersecting) {
            if (
              videoRefs.current[videoId] &&
              videoRefs.current[videoId].paused
            ) {
              videoRefs.current[videoId]
                .play()
                .catch((e) => console.log("Auto-play prevented:", e));
            }
          } else {
            if (
              videoRefs.current[videoId] &&
              !videoRefs.current[videoId].paused
            ) {
              videoRefs.current[videoId].pause();
            }
          }
        });
      },
      { threshold: 0.6 }
    );

    // Observe all videos
    const videoElements = document.querySelectorAll(".video-thumbnail");
    videoElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      videoElements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, [userVideos]);

  const handleVideoClick = (videoId) => {
    navigate(`/shorts/${videoId}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSendMessage = () => {
    // Navigate to chat with this user
    navigate(`/inbox/${userId}`);
  };

  const handleFollowToggle = () => {
    if (!currentUserId) return;

    const db = getDatabase();
    const followingRef = ref(
      db,
      `usuarios/${currentUserId}/following/${userId}`
    );
    const followerRef = ref(
      db,
      `usuarios/${userId}/followers/${currentUserId}`
    );

    if (isFollowing) {
      // Unfollow: Remove both references
      update(followingRef, null);
      update(followerRef, null);
      setIsFollowing(false);
    } else {
      // Follow: Set both references
      update(followingRef, { followed: true });
      update(followerRef, { follower: true });
      setIsFollowing(true);
    }
  };

  // Get first name of user
  const getFirstName = (fullName) => fullName?.split(" ")[0] || "";

  // Use username if exists, otherwise use first name or "usuario"
  const displayUsername = userData?.name
    ? getFirstName(userData.name)
    : userData?.nombre
    ? getFirstName(userData.nombre)
    : "usuario";

  // Ensure username has @ at beginning
  const username = displayUsername.startsWith("@")
    ? displayUsername
    : `@${displayUsername}`;

  const bio = userData?.bio || "Sin biografía";
  const profileImage = userData?.profileImage || "https://placehold.co/100";

  // Validate followers, following and likes
  const following = userData?.following
    ? Object.keys(userData.following).length
    : 0;
  const followers = userData?.followers
    ? Object.keys(userData.followers).length
    : 0;
  const likes = userData?.likes ? Object.keys(userData.likes).length : 0;

  if (loading) {
    return (
      <div className="h-full flex flex-col items-center justify-center text-white">
        <Loader2 className="animate-spin h-8 w-8 text-pink-500" />
        <p className="mt-4 text-gray-400">Cargando perfil...</p>
      </div>
    );
  }

  return (
    <div className="h-full text-white pb-16">
      {/* Header with back button */}
      <div className="py-3 px-4 flex items-center border-b border-gray-800">
        <button
          onClick={handleBack}
          className="p-1 mr-4 hover:bg-gray-800 rounded-full transition-colors"
        >
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-lg font-bold">Perfil</h1>
      </div>

      <div className="pt-4 px-4 pb-2">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold">{username}</h2>
        </div>

        <div className="flex items-center mb-4">
          <img
            src={profileImage}
            alt="Profile"
            className="w-20 h-20 rounded-full object-cover border-2 border-pink-500"
          />
          <div className="flex space-x-6 ml-6">
            <div className="text-center">
              <div className="font-bold">{following}</div>
              <div className="text-xs text-gray-400">Siguiendo</div>
            </div>
            <div className="text-center">
              <div className="font-bold">{followers}</div>
              <div className="text-xs text-gray-400">Seguidores</div>
            </div>
            <div className="text-center">
              <div className="font-bold">{likes}</div>
              <div className="text-xs text-gray-400">Me gusta</div>
            </div>
          </div>
        </div>

        <p className="mb-4 text-gray-300">{bio}</p>

        {/* Message and Follow buttons */}
        <div className="flex space-x-2">
          <button
            className="flex-1 bg-gradient-to-r from-pink-500 to-purple-600 text-white font-medium py-1.5 rounded-md hover:from-pink-600 hover:to-purple-700 transition-colors flex items-center justify-center"
            onClick={handleSendMessage}
          >
            <MessageCircle size={16} className="mr-1" /> Mensaje
          </button>
          <button
            className={`flex-1 font-medium py-1.5 rounded-md transition-colors ${
              isFollowing
                ? "bg-gray-800 text-white hover:bg-gray-700"
                : "border border-gray-600 text-white hover:bg-gray-800"
            }`}
            onClick={handleFollowToggle}
          >
            {isFollowing ? "Siguiendo" : "Seguir"}
          </button>
        </div>
      </div>

      <div className="border-b border-gray-800 mt-4">
        <div className="flex">
          <button className="flex-1 py-2 border-b-2 border-white flex justify-center items-center">
            <Grid size={20} className="mr-1" />
            <span className="text-sm">Videos</span>
          </button>
          <button className="flex-1 py-2 text-gray-500 flex justify-center items-center hover:text-white transition-colors">
            <Bookmark size={20} className="mr-1" />
            <span className="text-sm">Guardados</span>
          </button>
        </div>
      </div>

      {userVideos.length === 0 ? (
        <div className="flex flex-col items-center justify-center p-8 h-64 text-center">
          <h3 className="font-medium mb-2">No hay videos</h3>
          <p className="text-sm text-gray-400">
            Este usuario aún no ha subido videos
          </p>
        </div>
      ) : (
        <div className="p-1">
          <div className="grid grid-cols-3 gap-1">
            {userVideos.map((video) => (
              <div
                key={video.id}
                className="aspect-[9/16] relative group cursor-pointer"
                onClick={() => handleVideoClick(video.id)}
              >
                <video
                  ref={(el) => (videoRefs.current[video.id] = el)}
                  src={video.url}
                  className="w-full h-full object-cover bg-gray-900 video-thumbnail"
                  poster={
                    video.thumbnailUrl ||
                    `https://placehold.co/400x720/333/444?text=Video`
                  }
                  muted
                  playsInline
                  loop
                  data-video-id={video.id}
                />
                <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-between p-2 group-hover:opacity-0 transition-opacity">
                  <div className="flex justify-end">
                    <div className="bg-black bg-opacity-60 rounded p-1 text-xs">
                      {new Date(video.fechaSubida).toLocaleDateString()}
                    </div>
                  </div>
                  <p className="text-white text-xs line-clamp-1">
                    {video.descripcion || "Sin descripción"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
