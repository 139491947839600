import React from "react";

const FootballAnimation = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-slate-900 via-blue-900 to-slate-900 overflow-hidden">
      {/* Stadium Background - More premium sports look */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-full h-64 bg-gradient-to-r from-green-900 to-green-800 border border-gold-300 rounded-lg overflow-hidden shadow-lg">
          {/* Field Lines - Premium gold accent */}
          <div className="absolute top-1/2 left-0 right-0 h-px bg-yellow-300 opacity-80"></div>
          <div className="absolute top-0 bottom-0 left-1/2 w-px bg-yellow-300 opacity-80"></div>
          <div className="absolute w-32 h-32 border border-yellow-300 rounded-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-80"></div>
          {/* Goal Posts - Premium gold */}
          <div className="absolute h-16 w-px bg-yellow-300 bottom-0 left-10 opacity-80"></div>
          <div className="absolute h-16 w-px bg-yellow-300 bottom-0 right-10 opacity-80"></div>
          <div className="absolute h-px w-20 bg-yellow-300 bottom-16 left-10 opacity-80"></div>
          <div className="absolute h-px w-20 bg-yellow-300 bottom-16 right-10 opacity-80"></div>
        </div>
      </div>

      {/* Stadium Lights */}
      <div className="absolute top-4 left-4 w-4 h-4 bg-white rounded-full animate-flicker opacity-70"></div>
      <div className="absolute top-4 right-4 w-4 h-4 bg-white rounded-full animate-flicker-delay opacity-70"></div>

      {/* Animated Player - More dynamic sports style */}
      <div className="player-running absolute left-0 animate-player-run">
        <div className="w-8 h-16 relative">
          <div className="w-6 h-6 bg-blue-600 rounded-full absolute top-0 left-1 shadow-glow"></div>
          <div className="w-8 h-8 bg-blue-600 absolute top-6 shadow-glow"></div>
          <div className="w-2 h-6 bg-white absolute top-10 left-1 animate-leg-left"></div>
          <div className="w-2 h-6 bg-white absolute top-10 right-1 animate-leg-right"></div>
        </div>
      </div>

      {/* Animated Ball - Premium sports ball */}
      <div className="ball absolute animate-float-diagonal">
        <div className="w-6 h-6 bg-white rounded-full border border-gray-400 shadow-ball animate-rotate-slow">
          <div
            className="absolute inset-0 border border-gray-800 rounded-full"
            style={{ clipPath: "polygon(0 0, 100% 0, 50% 100%)" }}
          ></div>
        </div>
      </div>

      {/* Premium Score Display */}
      <div className="score-container absolute top-6 bg-black bg-opacity-70 px-6 py-2 rounded-lg border border-yellow-500 shadow-glow">
        <div className="flex items-center justify-center space-x-4">
          <div className="text-right">
            <span className="text-sm font-light text-yellow-200">PSG</span>
            <div className="text-white font-bold tracking-wide">CHAMPIONS</div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-2xl font-bold text-white">2</span>
            <span className="text-xs text-yellow-200 mx-1">:</span>
            <span className="text-2xl font-bold text-white">1</span>
          </div>
          <div className="text-left">
            <span className="text-sm font-light text-yellow-200">Liverpol</span>
            <div className="text-white font-bold tracking-wide">PREMIER</div>
          </div>
        </div>
        <div className="text-center text-xs text-yellow-200 mt-1 animate-pulse">
          CHAMPIONS LEAGUE
        </div>
      </div>

      {/* Dynamic Match Time */}
      <div className="absolute top-24 right-6 bg-black bg-opacity-70 px-4 py-1 rounded-lg border border-yellow-500">
        <p className="text-yellow-300 font-mono font-bold animate-time-tick">
          89:42
        </p>
      </div>

      {/* Dynamic Stats Bars */}
      <div className="absolute left-6 top-24 flex flex-col space-y-2 bg-black bg-opacity-70 p-3 rounded-lg border border-yellow-500">
        <div className="flex items-center text-xs">
          <span className="text-white w-24">Posesión</span>
          <div className="w-32 h-2 bg-gray-700 rounded-full">
            <div
              className="h-full bg-blue-500 rounded-full animate-stat-grow"
              style={{ width: "65%" }}
            ></div>
          </div>
        </div>
        <div className="flex items-center text-xs">
          <span className="text-white w-24">Tiros a Gol</span>
          <div className="w-32 h-2 bg-gray-700 rounded-full">
            <div
              className="h-full bg-blue-500 rounded-full"
              style={{ width: "75%" }}
            ></div>
          </div>
        </div>
        <div className="flex items-center text-xs">
          <span className="text-white w-24">Precisión</span>
          <div className="w-32 h-2 bg-gray-700 rounded-full">
            <div
              className="h-full bg-blue-500 rounded-full"
              style={{ width: "85%" }}
            ></div>
          </div>
        </div>
      </div>

      {/* Elegant Status Message with Sports theme */}
      <div className="absolute bottom-20 bg-black bg-opacity-70 px-6 py-3 rounded-lg border border-yellow-500 shadow-glow">
        <p className="text-lg font-bold text-white tracking-wider">
          ANÁLISIS PREMIUM
        </p>
        <div className="flex justify-center gap-2 mt-1">
          <span className="text-xs px-2 py-1 bg-blue-700 rounded text-white">
            VICTORIA
          </span>
          <span className="text-xs px-2 py-1 bg-green-700 rounded text-white">
            ALTO VALOR
          </span>
        </div>
      </div>

      {/* Premium Progress Bar */}
      <div className="absolute bottom-10 w-64 h-3 bg-black bg-opacity-70 rounded-full overflow-hidden border border-yellow-500">
        <div className="h-full bg-gradient-to-r from-blue-500 to-indigo-600 animate-progress-pulse"></div>
      </div>

      {/* Particle effects for premium feel */}
      <div className="particles absolute inset-0 overflow-hidden pointer-events-none">
        {[...Array(10)].map((_, i) => (
          <div
            key={i}
            className="particle absolute bg-yellow-300 rounded-full opacity-30"
            style={{
              width: `${Math.random() * 4 + 1}px`,
              height: `${Math.random() * 4 + 1}px`,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 5 + 10}s`,
              animationDelay: `${Math.random() * 5}s`,
            }}
          ></div>
        ))}
      </div>

      <style jsx>{`
        @keyframes player-run {
          0% {
            transform: translateX(-50px);
          }
          100% {
            transform: translateX(calc(100vw + 50px));
          }
        }

        @keyframes float-diagonal {
          0% {
            transform: translate(0, 0);
          }
          25% {
            transform: translate(100px, -30px);
          }
          50% {
            transform: translate(200px, 0);
          }
          75% {
            transform: translate(300px, -30px);
          }
          100% {
            transform: translate(400px, 0);
          }
        }

        @keyframes leg-left {
          0%,
          100% {
            transform: rotate(-25deg);
          }
          50% {
            transform: rotate(25deg);
          }
        }

        @keyframes leg-right {
          0%,
          100% {
            transform: rotate(25deg);
          }
          50% {
            transform: rotate(-25deg);
          }
        }

        @keyframes rotate-slow {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @keyframes progress-pulse {
          0% {
            width: 0%;
            opacity: 0.8;
          }
          50% {
            opacity: 1;
          }
          100% {
            width: 100%;
            opacity: 0.8;
          }
        }

        @keyframes time-tick {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.7;
          }
        }

        @keyframes flicker {
          0%,
          100% {
            opacity: 0.7;
            box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.2);
          }
          50% {
            opacity: 0.9;
            box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.3);
          }
        }

        @keyframes flicker-delay {
          0%,
          100% {
            opacity: 0.9;
            box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.3);
          }
          50% {
            opacity: 0.7;
            box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.2);
          }
        }

        @keyframes stat-grow {
          0% {
            width: 55%;
          }
          50% {
            width: 65%;
          }
          100% {
            width: 55%;
          }
        }

        @keyframes float-around {
          0%,
          100% {
            transform: translate(0, 0);
          }
          25% {
            transform: translate(100px, 100px);
          }
          50% {
            transform: translate(0, 200px);
          }
          75% {
            transform: translate(-100px, 100px);
          }
        }

        .animate-player-run {
          animation: player-run 8s linear infinite;
        }

        .animate-float-diagonal {
          animation: float-diagonal 7s ease-in-out infinite;
        }

        .animate-leg-left {
          animation: leg-left 0.5s linear infinite;
        }

        .animate-leg-right {
          animation: leg-right 0.5s linear infinite;
        }

        .animate-rotate-slow {
          animation: rotate-slow 3s linear infinite;
        }

        .animate-progress-pulse {
          animation: progress-pulse 4s ease-in-out infinite;
        }

        .animate-time-tick {
          animation: time-tick 1s ease-in-out infinite;
        }

        .animate-flicker {
          animation: flicker 2s ease-in-out infinite;
        }

        .animate-flicker-delay {
          animation: flicker-delay 2s ease-in-out infinite;
        }

        .animate-stat-grow {
          animation: stat-grow 5s ease-in-out infinite;
        }

        .particle {
          animation: float-around linear infinite;
        }

        .shadow-glow {
          box-shadow: 0 0 8px 2px rgba(59, 130, 246, 0.5);
        }

        .shadow-ball {
          box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.5);
        }
      `}</style>
    </div>
  );
};

export default FootballAnimation;
