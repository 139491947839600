import React from "react";
import { Helmet } from "react-helmet";
import {
  FaWhatsapp,
  FaTag,
  FaClock,
  FaTrophy,
  FaChartLine,
  FaFootballBall,
  FaCheckCircle,
} from "react-icons/fa";

const promos = [
  {
    id: 1,
    title: "50% OFF Plan VIP Mensual",
    description:
      "Accede a todos nuestros pronósticos premium con un 50% de descuento. ¡Oferta por tiempo limitado!",
    image:
      "https://media.istockphoto.com/id/540395152/vector/sale-sticker-50-percent-off-label-tag-vector-badge-icon.jpg?s=612x612&w=0&k=20&c=FST2s1RTRLXfZNLqEO8otSGW3gpPFrwI4-sBvqUmcEo=",
    expiryDate: "2024-10-31",
    icon: <FaTrophy className="text-yellow-400 text-3xl" />,
    state: "Activa",
  },
  {
    id: 2,
    title: "15 días gratis",
    description:
      "Suscríbete a nuestro Plan VIP y obtén 15 días adicionales sin costo. ¡Aprovecha ahora!",
    image: "https://jime.blog/wp-content/uploads/2019/02/15-dias.png",
    expiryDate: "2024-11-31",
    icon: <FaChartLine className="text-blue-400 text-3xl" />,
    state: "Activa",
  },
  {
    id: 3,
    title: "Invita a 5 amigos y obtén 15 días VIP gratis",
    description:
      "Invita a 5 amigos a unirse a DeportivosPro y obtendrás 15 días de nuestro Plan VIP totalmente gratis.",
    image:
      "https://img.freepik.com/vector-gratis/amigos-brindando-juntos_23-2148403249.jpg", // Puedes reemplazar esto con la URL correcta de la imagen
    expiryDate: "2024-12-31",
    icon: <FaFootballBall className="text-green-400 text-3xl" />,
    state: "Activa",
  },
];

const WhatsAppButton = ({ promo }) => {
  const message = encodeURIComponent(
    `Hola, me interesa la promoción: ${promo.title} de DeportivosPro. ¿Podrías darme más información?`
  );
  const whatsappUrl = `https://wa.me/573023580862?text=${message}`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
    >
      <FaWhatsapp className="mr-2 text-lg" />
      <span>Consultar por WhatsApp</span>
    </a>
  );
};

const PromoCard = ({ promo }) => (
  <div className="bg-gray-800 rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-300 ease-in-out border border-gray-700 hover:border-gray-500">
    <div className="relative">
      <img
        src={promo.image}
        alt={promo.title}
        className="w-full h-56 object-cover"
      />
      <div className="absolute top-0 left-0 bg-blue-600 text-white px-4 py-1 rounded-br-lg">
        <FaTag className="inline-block mr-2" />
        Oferta Especial
      </div>
      {promo.state === "Activa" && (
        <div className="absolute top-0 right-0 bg-green-500 text-white px-4 py-1 rounded-bl-lg flex items-center">
          <FaCheckCircle className="mr-2" />
          <span>Activa</span>
        </div>
      )}
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        {promo.icon}
        <h3 className="text-2xl font-semibold text-white ml-2">
          {promo.title}
        </h3>
      </div>
      <p className="text-gray-300 mb-4">{promo.description}</p>
      <div className="flex items-center text-sm text-gray-400 mb-4">
        <FaClock className="mr-2 text-red-400" />
        <span>Válido hasta: {promo.expiryDate}</span>
      </div>
      <WhatsAppButton promo={promo} />
    </div>
  </div>
);

const DeportivosProPromos = () => {
  return (
    <div className="bg-gradient-to-b from-gray-900 to-black min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Promociones - DeportivosPro</title>
        <meta
          name="description"
          content="Descubre nuestras increíbles promociones en planes VIP de pronósticos deportivos. Ofertas exclusivas en DeportivosPro. ¡No te las pierdas!"
        />
        <meta
          name="keywords"
          content="promociones, ofertas, descuentos, pronósticos deportivos, pronosticos, apuestas deportivas, pronosticos fijos de hoy, futbol, deporte, ganadoras de hoy,  DeportivosPro, planes VIP"
        />
        <meta property="og:title" content="Promociones VIP - DeportivosPro" />
        <meta
          property="og:description"
          content="Descubre nuestras increíbles promociones en planes VIP de pronósticos deportivos. Ofertas exclusivas en DeportivosPro. ¡No te las pierdas!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
        />
        <link
          rel="canonical"
          href="https://deportivospro.com/promociones-vip"
        />
      </Helmet>

      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl md:text-5xl font-bold text-center text-white mb-8">
          Ofertas Exclusivas
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {promos.map((promo) => (
            <PromoCard key={promo.id} promo={promo} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeportivosProPromos;
