import React from "react";
import {
  Star,
  Check,
  AlertCircle,
  PhoneCall,
  MessageCircle,
} from "lucide-react";

const plans = [
  {
    id: 1,
    name: "Plan Básico",
    price: "$20.000/mes",
    icon: <Star className="w-12 h-12 text-yellow-400 mb-4" />,
    benefits: [
      "Acceso a pronósticos regulares",
      "Actualizaciones diarias",
      "Soporte básico",
    ],
    buttonText: "Comprar Básico",
    color: "from-blue-500 to-blue-600",
  },
  {
    id: 2,
    name: "Plan Premium",
    price: "$35.000/mes",
    icon: <AlertCircle className="w-12 h-12 text-purple-400 mb-4" />,
    benefits: [
      "Acceso a todos los pronósticos",
      "Actualizaciones en tiempo real",
      "Soporte prioritario",
      "Estadísticas detalladas",
    ],
    buttonText: "Comprar Premium",
    color: "from-purple-500 to-purple-600",
  },
  {
    id: 3,
    name: "Plan VIP",
    price: "$50.000/mes",
    icon: <PhoneCall className="w-12 h-12 text-green-400 mb-4" />,
    benefits: [
      "Acceso exclusivo a pronósticos VIP",
      "Alertas personalizadas",
      "Soporte VIP 24/7",
      "Consultas personalizadas con expertos",
    ],
    buttonText: "Comprar VIP",
    color: "from-green-500 to-green-600",
  },
];

const PlanCard = ({ plan }) => {
  const handleBuyClick = () => {
    const message = `Hola, estoy interesado en adquirir el ${plan.name}.`;
    const whatsappLink = `https://wa.me/573023580862?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="bg-gray-800 text-white rounded-xl shadow-lg p-8 m-4 transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 flex flex-col justify-between">
      <div>
        {plan.icon}
        <h3 className="text-2xl font-bold mb-4">{plan.name}</h3>
        <p className="text-3xl font-semibold mb-6">{plan.price}</p>
        <ul className="mb-8">
          {plan.benefits.map((benefit, index) => (
            <li key={index} className="flex items-center text-gray-300 mb-3">
              <Check className="w-5 h-5 mr-2 text-green-400" />
              {benefit}
            </li>
          ))}
        </ul>
      </div>
      <button
        onClick={handleBuyClick}
        className={`bg-gradient-to-r ${plan.color} text-white py-3 px-6 rounded-lg font-semibold text-lg transition-all duration-300 hover:opacity-90 hover:scale-105`}
      >
        {plan.buttonText}
      </button>
    </div>
  );
};

const ComprarVip = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white py-16 px-4 sm:px-6 lg:px-8 relative">
      <div className="max-w-7xl mx-auto text-center">
        <h1 className="text-5xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-green-400">
          Elige tu Plan VIP
        </h1>
        <p className="text-xl text-gray-400 mb-12">
          Desbloquea todo el potencial con nuestros planes exclusivos
        </p>
        <div className="flex flex-wrap justify-center">
          {plans.map((plan) => (
            <PlanCard key={plan.id} plan={plan} />
          ))}
        </div>
      </div>

      {/* Botón flotante de WhatsApp */}
      <a
        href="https://wa.me/573023580862?text=Hola, necesito ayuda para adquirir un plan VIP."
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-8 right-8 bg-green-500 text-white p-4 rounded-full shadow-lg transition-all duration-300 hover:bg-green-600 hover:scale-110"
      >
        <MessageCircle className="w-6 h-6" />
      </a>
    </div>
  );
};

export default ComprarVip;
