import React, { useState, useEffect, useRef } from "react";
import {
  Settings,
  Grid,
  Bookmark,
  Upload,
  Loader2,
  X,
  Camera,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue, update } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import NavShorts from "../NavShorts";

export default function ProfilePage() {
  const [userVideos, setUserVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showBioModal, setShowBioModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [newBio, setNewBio] = useState("");
  const [bioError, setBioError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const videoRefs = useRef({});
  const navigate = useNavigate();

  // Obtener el UUID del usuario desde localStorage
  useEffect(() => {
    try {
      const userUUID = localStorage.getItem("userUUID");
      if (userUUID) {
        setUserId(userUUID);
      } else {
        console.error("No se encontró el ID de usuario en localStorage");
      }
    } catch (error) {
      console.error("Error al obtener el UUID del usuario:", error);
    }
  }, []);

  // Obtener datos del usuario y sus videos
  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    const db = getDatabase();

    // Obtener datos del perfil
    const userRef = ref(db, `usuarios/${userId}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData(data);
        if (data.bio) {
          setNewBio(data.bio);
        }
      }
    });

    // Obtener videos del usuario
    const shortsRef = ref(db, `usuarios/${userId}/shorts`);
    onValue(shortsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convertir objeto a array y ordenar por fecha (más reciente primero)
        const videosArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        videosArray.sort(
          (a, b) => new Date(b.fechaSubida) - new Date(a.fechaSubida)
        );

        setUserVideos(videosArray);
      } else {
        setUserVideos([]);
      }
      setLoading(false);
    });
  }, [userId]);

  // Iniciar reproducción automática al entrar en viewport
  useEffect(() => {
    if (!userVideos.length) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const videoId = entry.target.dataset.videoId;
          if (entry.isIntersecting) {
            if (
              videoRefs.current[videoId] &&
              videoRefs.current[videoId].paused
            ) {
              videoRefs.current[videoId]
                .play()
                .catch((e) => console.log("Auto-play prevented:", e));
            }
          } else {
            if (
              videoRefs.current[videoId] &&
              !videoRefs.current[videoId].paused
            ) {
              videoRefs.current[videoId].pause();
            }
          }
        });
      },
      { threshold: 0.6 }
    );

    // Observar todos los videos
    const videoElements = document.querySelectorAll(".video-thumbnail");
    videoElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      videoElements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, [userVideos]);

  const handleUploadClick = () => {
    navigate("/upload");
  };

  const handleVideoClick = (videoId) => {
    navigate(`/shorts/${videoId}`);
  };

  const openBioModal = () => {
    setNewBio(userData?.bio || "");
    setBioError("");
    setShowBioModal(true);
  };

  const openSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const validateBio = (text) => {
    // Validar longitud
    if (text.length > 50) {
      return "La biografía no puede exceder los 50 caracteres";
    }

    // Validar que no haya correos
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    if (emailRegex.test(text)) {
      return "No se permiten correos electrónicos en la biografía";
    }

    // Validar que no haya enlaces
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/;
    if (urlRegex.test(text)) {
      return "No se permiten enlaces en la biografía";
    }

    return "";
  };

  const handleBioChange = (e) => {
    const text = e.target.value;
    setNewBio(text);
    const error = validateBio(text);
    setBioError(error);
  };

  const saveBio = async () => {
    const error = validateBio(newBio);
    if (error) {
      setBioError(error);
      return;
    }

    try {
      const db = getDatabase();
      const userRef = ref(db, `usuarios/${userId}`);
      await update(userRef, { bio: newBio });
      setShowBioModal(false);
    } catch (error) {
      console.error("Error al actualizar la biografía:", error);
      setBioError("Error al guardar la biografía");
    }
  };

  const handleProfileImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validar que es una imagen
    if (!file.type.startsWith("image/")) {
      alert("Por favor selecciona una imagen");
      return;
    }

    // Validar tamaño (máximo 5MB)
    if (file.size > 5 * 1024 * 1024) {
      alert("La imagen no puede exceder los 5MB");
      return;
    }

    try {
      setUploading(true);
      setUploadProgress(0);

      // Subir imagen a Firebase Storage
      const storage = getStorage();
      const profileImageRef = storageRef(
        storage,
        `Profile/${userId}/${file.name}`
      );

      // Crear una tarea de carga para poder rastrear el progreso
      const uploadTask = uploadBytes(profileImageRef, file);

      // Simular progreso (Firebase Storage no proporciona fácilmente progreso de carga)
      const interval = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 90) clearInterval(interval);
          return prev + 10;
        });
      }, 300);

      // Esperar a que se complete la carga
      await uploadTask;
      clearInterval(interval);
      setUploadProgress(100);

      // Obtener URL de descarga
      const downloadURL = await getDownloadURL(profileImageRef);

      // Actualizar referencia en la base de datos
      const db = getDatabase();
      const userRef = ref(db, `usuarios/${userId}`);
      await update(userRef, { profileImage: downloadURL });

      setUploading(false);
    } catch (error) {
      console.error("Error al subir la imagen de perfil:", error);
      setUploading(false);
      alert("Error al subir la imagen. Inténtalo de nuevo.");
    }
  };

  const handleLogout = () => {
    // Limpiar datos de sesión
    localStorage.removeItem("userUUID");
    localStorage.removeItem("userToken");

    // Redireccionar a login
    navigate("/login");
  };

  // Obtener el primer nombre del usuario
  const getFirstName = (fullName) => fullName.split(" ")[0];

  // Usar username si existe, de lo contrario usar solo el primer nombre o "usuario"
  const displayUsername = userData?.name
    ? getFirstName(userData.name)
    : userData?.nombre
    ? getFirstName(userData.nombre)
    : "usuario";

  // Asegurar que el nombre de usuario tenga @ al inicio
  const username = displayUsername.startsWith("@")
    ? displayUsername
    : `@${displayUsername}`;

  const bio =
    userData?.bio ||
    "Agrega una biografía para que las personas conozcan más sobre ti.";
  const profileImage = userData?.profileImage || "https://placehold.co/100";

  // Validar seguidores, seguidos y me gusta
  const following = userData?.following
    ? Object.keys(userData.following).length
    : 0;
  const followers = userData?.followers
    ? Object.keys(userData.followers).length
    : 0;
  const likes = userData?.likes ? Object.keys(userData.likes).length : 0;

  return (
    <div className="h-full text-white pb-16">
      <div className="pt-4 px-4 pb-2">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold">{username}</h2>
          <button
            className="p-2 hover:bg-gray-800 rounded-full transition-colors"
            onClick={openSettingsModal}
          >
            <Settings size={24} />
          </button>
        </div>

        <div className="flex items-center mb-4">
          <div className="relative">
            <img
              src={profileImage}
              alt="Profile"
              className="w-20 h-20 rounded-full object-cover border-2 border-pink-500"
              onClick={handleProfileImageClick}
            />
            <div
              className="absolute bottom-0 right-0 bg-pink-500 rounded-full p-1 cursor-pointer"
              onClick={handleProfileImageClick}
            >
              <Camera size={16} />
            </div>
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
            {uploading && (
              <div className="absolute inset-0 rounded-full flex items-center justify-center bg-black bg-opacity-50">
                <div className="text-xs font-bold">{uploadProgress}%</div>
              </div>
            )}
          </div>
          <div className="flex space-x-6 ml-6">
            <div className="text-center">
              <div className="font-bold">{following}</div>
              <div className="text-xs text-gray-400">Siguiendo</div>
            </div>
            <div className="text-center">
              <div className="font-bold">{followers}</div>
              <div className="text-xs text-gray-400">Seguidores</div>
            </div>
            <div className="text-center">
              <div className="font-bold">{likes}</div>
              <div className="text-xs text-gray-400">Me gusta</div>
            </div>
          </div>
        </div>

        <p className="mb-4 text-gray-300">{bio}</p>

        <button
          className="w-full border border-gray-600 text-white font-medium py-1.5 rounded-md hover:bg-gray-800 transition-colors"
          onClick={openBioModal}
        >
          Editar perfil
        </button>
      </div>

      <div className="border-b border-gray-800 mt-4">
        <div className="flex">
          <button className="flex-1 py-2 border-b-2 border-white flex justify-center items-center">
            <Grid size={20} className="mr-1" />
            <span className="text-sm">Videos</span>
          </button>
          <button className="flex-1 py-2 text-gray-500 flex justify-center items-center hover:text-white transition-colors">
            <Bookmark size={20} className="mr-1" />
            <span className="text-sm">Guardados</span>
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex flex-col items-center justify-center p-8 h-64">
          <Loader2 className="animate-spin h-8 w-8 text-pink-500" />
          <p className="mt-4 text-gray-400">Cargando videos...</p>
        </div>
      ) : userVideos.length === 0 ? (
        <div className="flex flex-col items-center justify-center p-8 h-64 text-center">
          <h3 className="font-medium mb-2">Sube tu primer video</h3>
          <p className="text-sm text-gray-400 mb-4">
            Tus videos aparecerán aquí
          </p>
          <button
            className="bg-gradient-to-r from-pink-500 to-purple-600 text-white font-bold py-2 px-6 rounded-full shadow-lg shadow-pink-500/20 hover:shadow-xl transition-all duration-300 flex items-center"
            onClick={handleUploadClick}
          >
            <Upload size={18} className="mr-2" />
            Subir
          </button>
        </div>
      ) : (
        <div className="p-1">
          <div className="grid grid-cols-3 gap-1">
            {userVideos.map((video) => (
              <div
                key={video.id}
                className="aspect-[9/16] relative group cursor-pointer"
                onClick={() => handleVideoClick(video.id)}
              >
                <video
                  ref={(el) => (videoRefs.current[video.id] = el)}
                  src={video.url}
                  className="w-full h-full object-cover bg-gray-900 video-thumbnail"
                  poster={
                    video.thumbnailUrl ||
                    `https://placehold.co/400x720/333/444?text=Video`
                  }
                  muted
                  playsInline
                  loop
                  data-video-id={video.id}
                />
                <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-between p-2 group-hover:opacity-0 transition-opacity">
                  <div className="flex justify-end">
                    <div className="bg-black bg-opacity-60 rounded p-1 text-xs">
                      {new Date(video.fechaSubida).toLocaleDateString()}
                    </div>
                  </div>
                  <p className="text-white text-xs line-clamp-1">
                    {video.descripcion || "Sin descripción"}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="fixed bottom-4 right-4">
            <button
              className="bg-gradient-to-r from-pink-500 to-purple-600 text-white p-3 rounded-full shadow-lg shadow-pink-500/30 hover:shadow-xl transition-all duration-300"
              onClick={handleUploadClick}
            >
              <Upload size={24} />
            </button>
          </div>
        </div>
      )}

      {/* Modal para editar biografía */}
      {showBioModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 px-4">
          <div className="bg-gray-900 rounded-lg max-w-md w-full p-5">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Editar biografía</h3>
              <button
                onClick={() => setShowBioModal(false)}
                className="p-1 rounded-full hover:bg-gray-800"
              >
                <X size={20} />
              </button>
            </div>

            <div className="mb-4">
              <textarea
                className="w-full p-3 rounded-lg bg-gray-800 text-white placeholder-gray-400 resize-none focus:outline-none focus:ring-2 focus:ring-pink-500"
                placeholder="Describe quién eres..."
                rows={3}
                value={newBio}
                onChange={handleBioChange}
                maxLength={50}
              />
              <div className="flex justify-between mt-1">
                <span className="text-xs text-red-500">{bioError}</span>
                <span className="text-xs text-gray-400">
                  {newBio.length}/50
                </span>
              </div>
            </div>

            <div className="flex gap-2">
              <button
                className="flex-1 py-2 bg-gray-800 text-white rounded-lg"
                onClick={() => setShowBioModal(false)}
              >
                Cancelar
              </button>
              <button
                className="flex-1 py-2 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-lg disabled:opacity-50"
                onClick={saveBio}
                disabled={!!bioError}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de configuraciones */}
      {showSettingsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 px-4">
          <div className="bg-gray-900 rounded-lg max-w-md w-full p-5">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Configuraciones</h3>
              <button
                onClick={() => setShowSettingsModal(false)}
                className="p-1 rounded-full hover:bg-gray-800"
              >
                <X size={20} />
              </button>
            </div>

            <div className="space-y-4">
              <button
                className="w-full p-3 bg-gray-800 rounded-lg text-left hover:bg-gray-700 transition-colors"
                onClick={() => {
                  setShowSettingsModal(false);
                  navigate("/privacy");
                }}
              >
                Privacidad y seguridad
              </button>

              <button
                className="w-full p-3 bg-gray-800 rounded-lg text-left hover:bg-gray-700 transition-colors"
                onClick={() => {
                  setShowSettingsModal(false);
                  navigate("/notifications");
                }}
              >
                Notificaciones
              </button>

              <button
                className="w-full p-3 bg-gray-800 rounded-lg text-left hover:bg-gray-700 transition-colors"
                onClick={() => {
                  setShowSettingsModal(false);
                  navigate("/account");
                }}
              >
                Cuenta
              </button>

              <button
                className="w-full p-3 bg-gray-800 rounded-lg text-left hover:bg-gray-700 transition-colors"
                onClick={() => {
                  setShowSettingsModal(false);
                  navigate("/help");
                }}
              >
                Ayuda
              </button>

              <button
                className="w-full p-3 bg-red-900 text-red-100 rounded-lg text-left hover:bg-red-800 transition-colors"
                onClick={handleLogout}
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      )}
      <NavShorts />
      
    </div>
  );
}
