import { useState, useEffect } from "react";
import { getDatabase, ref, onValue, update } from "firebase/database";

const useUserData = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const db = getDatabase();
    const uid = localStorage.getItem("userUUID"); // Verificamos el UID en LocalStorage

    if (!uid) {
      setLoading(false);
      setUserData(null);
      return;
    }

    const userRef = ref(db, `usuarios/${uid}`);

    const unsubscribe = onValue(
      userRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setUserData(snapshot.val());
          setError(null);
        } else {
          setUserData(null);
          setError("No se encontraron datos para este usuario.");
        }
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Cleanup para evitar fugas de memoria
  }, []);

  // Función para actualizar los datos del usuario
  const updateUserData = async (newData) => {
    try {
      if (!newData || typeof newData !== "object") {
        throw new Error("Los datos a actualizar deben ser un objeto válido.");
      }

      const db = getDatabase();
      const uid = localStorage.getItem("userUUID");

      if (!uid) {
        throw new Error("Usuario no autenticado.");
      }

      const userRef = ref(db, `usuarios/${uid}`);
      await update(userRef, newData);

      // Actualizar el estado localmente para reflejar los cambios en la UI
      setUserData((prevData) => ({ ...prevData, ...newData }));
    } catch (error) {
      setError(error.message);
    }
  };

  return { userData, loading, error, updateUserData };
};

export default useUserData;
