import React from 'react'

export default function MenuCarrito() {
  return (
    <div>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
      <h1>Carrito 123</h1>
    </div>
  )
}
