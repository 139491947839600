import { useEffect, useState } from "react";

const AddToHomeBanner = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    // Verificar si la app ya está instalada
    const checkIfInstalled = () => {
      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone ||
        document.referrer.includes("android-app://");
      setIsInstalled(isStandalone);

      // Si está instalada, no mostrar el banner
      if (isStandalone) {
        setVisible(false);
      }
    };

    // Capturar el evento de instalación
    const handleInstallPrompt = (event) => {
      // Prevenir el comportamiento por defecto
      event.preventDefault();

      // Guardar el evento para usarlo después
      setDeferredPrompt(event);

      // Mostrar el banner solo si no está instalada
      if (!isInstalled) {
        setVisible(true);
      }

      console.log("Evento de instalación capturado y listo para usar");
    };

    // Manejar cambios en el estado de instalación
    const handleAppInstalled = () => {
      setIsInstalled(true);
      setVisible(false);
      setDeferredPrompt(null);
      console.log("Aplicación instalada correctamente");
    };

    // Inicializar
    checkIfInstalled();

    // Escuchar eventos
    window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    // También verificar periódicamente si se ha instalado
    const checkInterval = setInterval(checkIfInstalled, 3000);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
      window.removeEventListener("appinstalled", handleAppInstalled);
      clearInterval(checkInterval);
    };
  }, [isInstalled]);

  // Función para manejar la instalación
  const handleInstall = async () => {
    if (!deferredPrompt) {
      console.log("No hay prompt de instalación disponible");

      // Intentar mostrar instrucciones genéricas si no hay prompt disponible
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) {
        alert(
          "Para instalar: toca el ícono de compartir y luego 'Agregar a pantalla de inicio'"
        );
      } else {
        // En Chrome/Edge/Firefox, intentar forzar el menú de instalación
        alert(
          "Para instalar: abre el menú del navegador (tres puntos) y selecciona 'Instalar aplicación'"
        );
      }

      return;
    }

    try {
      // Mostrar el prompt de instalación nativo
      deferredPrompt.prompt();

      // Esperar a la elección del usuario
      const choiceResult = await deferredPrompt.userChoice;

      if (choiceResult.outcome === "accepted") {
        console.log("El usuario aceptó la instalación");
        setIsInstalled(true);
      } else {
        console.log("El usuario rechazó la instalación");
      }

      // Limpiar el prompt después de usarlo
      setDeferredPrompt(null);
      setVisible(false);
    } catch (error) {
      console.error("Error al intentar instalar la aplicación:", error);
      alert(
        "Hubo un problema al instalar. Por favor, intenta nuevamente o instala manualmente desde el menú del navegador."
      );
    }
  };

  // Si no es visible o ya está instalada, no mostrar nada
  if (!visible || isInstalled) {
    return null;
  }

  return (
    <div className="fixed bottom-4 left-4 right-4 bg-blue-600 text-white p-4 rounded-lg shadow-lg flex justify-between items-center animate-fadeIn">
      <p className="text-sm md:text-base">
        Agrega esta aplicación a tu pantalla de inicio
      </p>
      <div className="flex gap-2">
        <button
          onClick={handleInstall}
          className="bg-white text-blue-600 px-4 py-2 rounded-md font-medium shadow hover:bg-gray-200 transition"
        >
          Instalar
        </button>
        <button
          onClick={() => setVisible(false)}
          className="bg-red-500 text-white px-3 py-2 rounded-md font-medium shadow hover:bg-red-600 transition"
        >
          X
        </button>
      </div>
    </div>
  );
};

export default AddToHomeBanner;
