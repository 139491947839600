import React, { useState } from "react";
import { Search, Eye, FileText, Filter, ChevronDown } from "lucide-react";

// Sample data
const sampleDocuments = [
  {
    id: 1,
    title: "Política de Calidad 2024",
    category: "Gestión Admin",
    date: "2024-02-01",
    author: "Juan Pérez",
    status: "Activo",
    type: "PDF",
  },
  {
    id: 2,
    title: "Plan de Ventas Q1",
    category: "Gestión Comercial",
    date: "2024-01-15",
    author: "María García",
    status: "En Revisión",
    type: "XLSX",
  },
  {
    id: 3,
    title: "Manual de Procedimientos",
    category: "Gestión Admin",
    date: "2024-01-20",
    author: "Carlos Rodríguez",
    status: "Activo",
    type: "DOC",
  },
  {
    id: 1,
    title: "Política de Calidad 2024",
    category: "Gestión Admin",
    date: "2024-02-01",
    author: "Juan Pérez",
    status: "Activo",
    type: "PDF",
  },
  {
    id: 2,
    title: "Plan de Ventas Q1",
    category: "Gestión Comercial",
    date: "2024-01-15",
    author: "María García",
    status: "En Revisión",
    type: "XLSX",
  },
  {
    id: 3,
    title: "Manual de Procedimientos",
    category: "Gestión Admin",
    date: "2024-01-20",
    author: "Carlos Rodríguez",
    status: "Activo",
    type: "DOC",
  },
];

const categories = [
  "Todos",
  "Gestión Admin",
  "Gestión Comercial",
  "Gestión Calidad",
  "Gestión Humana",
];

export default function ListDoc() {
  const [selectedCategory, setSelectedCategory] = useState("Todos");
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [documents] = useState(sampleDocuments);

  const getStatusColor = (status) => {
    switch (status) {
      case "Activo":
        return "bg-green-100 text-green-800";
      case "En Revisión":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getTypeColor = (type) => {
    switch (type) {
      case "PDF":
        return "bg-red-100 text-red-800";
      case "XLSX":
        return "bg-green-100 text-green-800";
      case "DOC":
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const filteredDocuments = documents.filter((doc) => {
    const matchesCategory =
      selectedCategory === "Todos" || doc.category === selectedCategory;
    const matchesSearch =
      doc.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doc.author.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="p-8 max-w-7xl mx-auto bg-white">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Documentos</h1>
        <p className="text-gray-500">
          Gestiona y visualiza todos los documentos del sistema
        </p>
      </div>

      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Buscar por título o autor..."
            className="pl-10 w-full h-10 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="w-full sm:w-[200px] h-10 px-4 flex items-center justify-between rounded-lg border border-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <div className="flex items-center">
              <Filter className="w-4 h-4 mr-2 text-gray-400" />
              <span className="text-gray-700">{selectedCategory}</span>
            </div>
            <ChevronDown className="w-4 h-4 text-gray-400" />
          </button>

          {isDropdownOpen && (
            <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
              {categories.map((category) => (
                <button
                  key={category}
                  className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
                  onClick={() => {
                    setSelectedCategory(category);
                    setIsDropdownOpen(false);
                  }}
                >
                  {category}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Título
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Categoría
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Fecha
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Autor
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Estado
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tipo
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredDocuments.map((doc) => (
              <tr key={doc.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    <FileText className="w-4 h-4 text-gray-400" />
                    <span className="font-medium text-gray-900">
                      {doc.title}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                  {doc.category}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                  {new Date(doc.date).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                  {doc.author}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                      doc.status
                    )}`}
                  >
                    {doc.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getTypeColor(
                      doc.type
                    )}`}
                  >
                    {doc.type}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <button
                    className="text-gray-600 hover:text-blue-600 hover:bg-blue-50 p-2 rounded-full transition-colors duration-200"
                    onClick={() => console.log(`View document ${doc.id}`)}
                  >
                    <Eye className="w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
