import React, { useState, useEffect } from "react";
import { Bell, X, Check, AlertCircle, Info, ExternalLink } from "lucide-react";
import { ref, onValue, update, query, orderByChild } from "firebase/database";
import { database } from "../Config/firebase";

const NotificationModal = ({ userId = "user123" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [hasNewNotification, setHasNewNotification] = useState(false);

  // Formatear tiempo relativo
  const getRelativeTime = (timestamp) => {
    const now = new Date().getTime();
    const diff = now - timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} día${days > 1 ? "s" : ""}`;
    if (hours > 0) return `${hours} hora${hours > 1 ? "s" : ""}`;
    if (minutes > 0) return `${minutes} min`;
    return "Ahora";
  };

  // Obtener notificaciones de Firebase
  useEffect(() => {
    // Referencia a todas las notificaciones (globales)
    const notificationsRef = query(
      ref(database, `notifications`),
      orderByChild("timestamp")
    );

    // Referencia a las notificaciones leídas por el usuario actual
    const userReadRef = ref(database, `users/${userId}/readNotifications`);

    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      const notificationsData = snapshot.val();

      // Obtener las notificaciones leídas por el usuario
      onValue(userReadRef, (readSnapshot) => {
        const readNotifications = readSnapshot.val() || {};

        if (notificationsData) {
          // Convertir objeto de notificaciones a array
          const notificationsArray = [];

          // Recorrer todas las notificaciones
          Object.keys(notificationsData).forEach((notificationKey) => {
            const notification = notificationsData[notificationKey];

            // Añadir la notificación al array con el estado de lectura específico del usuario
            notificationsArray.push({
              ...notification,
              id: notificationKey,
              read: !!readNotifications[notificationKey],
            });
          });

          // Ordenar por timestamp (más reciente primero)
          notificationsArray.sort((a, b) => b.timestamp - a.timestamp);

          // Calcular notificaciones no leídas
          const prevUnreadCount = unreadCount;
          const currentUnreadCount = notificationsArray.filter(
            (n) => !n.read
          ).length;

          setNotifications(notificationsArray);
          setUnreadCount(currentUnreadCount);

          // Mostrar notificación del navegador si hay nuevas
          if (currentUnreadCount > prevUnreadCount && prevUnreadCount !== 0) {
            const newNotifications = notificationsArray
              .filter((n) => !n.read)
              .slice(0, currentUnreadCount - prevUnreadCount);

            if (newNotifications.length > 0) {
              showBrowserNotification(newNotifications[0]);
              setHasNewNotification(true);

              // Quitar la animación después de 5 segundos
              setTimeout(() => {
                setHasNewNotification(false);
              }, 5000);
            }
          }
        } else {
          setNotifications([]);
          setUnreadCount(0);
        }
      });
    });

    return () => unsubscribe();
  }, [userId, unreadCount]);

  // Solicitar permisos para notificaciones del navegador
  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission();
    }
  }, []);

  // Mostrar notificación del navegador
  const showBrowserNotification = (notification) => {
    if ("Notification" in window && Notification.permission === "granted") {
      new Notification(notification.title, {
        body: notification.message,
        icon: "/favicon.ico",
      });
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);

    // Si se abre el modal, marcamos la animación como vista
    if (!isOpen) {
      setHasNewNotification(false);
    }
  };

  // Marcar una notificación como leída específicamente para este usuario
  const markAsRead = (notificationId) => {
    update(ref(database, `users/${userId}/readNotifications`), {
      [notificationId]: true,
    });
  };

  // Marcar todas las notificaciones como leídas para este usuario específico
  const markAllAsRead = () => {
    const updates = {};
    notifications.forEach((notification) => {
      if (!notification.read) {
        updates[notification.id] = true;
      }
    });

    if (Object.keys(updates).length > 0) {
      update(ref(database, `users/${userId}/readNotifications`), updates);
    }
  };

  // Manejar clic en notificación - abre URLs externas
  const handleNotificationClick = (notification) => {
    if (notification.path) {
      // Abrimos la URL en una nueva pestaña
      window.open(notification.path, "_blank", "noopener,noreferrer");
      markAsRead(notification.id);
    }
  };

  // Obtener icono según tipo de notificación
  const getIcon = (type) => {
    switch (type) {
      case "success":
        return <Check className="text-green-500" />;
      case "error":
        return <AlertCircle className="text-red-500" />;
      case "info":
        return <Info className="text-blue-500" />;
      default:
        return <Info className="text-blue-500" />;
    }
  };

  return (
    <div className="relative">
      {/* Botón de notificaciones */}
      <button
        onClick={toggleModal}
        className={`relative p-2 rounded-full bg-gray-100 hover:bg-gray-200 flex items-center justify-center ${
          hasNewNotification ? "animate-pulse" : ""
        }`}
      >
        <Bell className="h-6 w-6 text-gray-700" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </button>

      {/* Modal de notificaciones */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg overflow-hidden z-50 border border-gray-200">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h3 className="font-medium text-gray-800">Notificaciones</h3>
            <button
              onClick={toggleModal}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="max-h-80 overflow-y-auto">
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`p-4 border-b border-gray-100 hover:bg-gray-50 ${
                    !notification.read ? "bg-blue-50" : ""
                  }`}
                  onClick={() => handleNotificationClick(notification)}
                  style={{ cursor: notification.path ? "pointer" : "default" }}
                >
                  <div className="flex">
                    <div className="mr-3 mt-1">
                      {getIcon(notification.type)}
                    </div>
                    <div className="flex-1">
                      <div className="flex justify-between">
                        <h4 className="font-medium text-gray-800">
                          {notification.title}
                        </h4>
                        <span className="text-xs text-gray-500">
                          {getRelativeTime(notification.timestamp)}
                        </span>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">
                        {notification.message}
                      </p>
                      {notification.path && (
                        <div className="flex items-center mt-2 text-xs text-blue-600">
                          <ExternalLink className="h-3 w-3 mr-1" />
                          <span>Abrir enlace</span>
                        </div>
                      )}
                    </div>
                    {!notification.read && (
                      <div
                        className="w-2 h-2 bg-blue-500 rounded-full mt-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          markAsRead(notification.id);
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">
                No hay notificaciones
              </div>
            )}
          </div>

          {unreadCount > 0 && (
            <div className="p-3 text-center border-t border-gray-200">
              <button
                onClick={markAllAsRead}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Marcar todas como leídas
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationModal;
