import React, { useState, useEffect } from "react";
import { Heart, Stars, Sparkles, Music } from "lucide-react";

const LoveYou = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  // Simula donde irían tus fotos - reemplaza las URLs con las tuyas
  const photos = [
    "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/Ana%2FIMG-20250118-WA0036.jpg?alt=media&token=0abab0a4-c2df-4ee8-8a57-ae35f62b5ecc",
    "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/Ana%2FIMG_20250118_134135_606.webp?alt=media&token=439154f0-da1a-40d9-9184-1fbd0a9dd3eb",
    "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/Ana%2FIMG_20250130_200015_054.webp?alt=media&token=770092cd-18c4-4af8-86b2-40a1e46c44fe",
  ];

  const frases = [
    "Te quiero mucho",
    "Contigo todo es más bonito",
    "Mi corazón late por ti",
    "Eres mi persona favorita",
    "Me haces inmensamente feliz",
  ];

  useEffect(() => {
    setIsVisible(true);
    setTimeout(() => setShowMessage(true), 1000);
    const interval = setInterval(() => {
      setCurrentPhotoIndex((prev) => (prev + 1) % photos.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-r from-pink-100 via-purple-100 to-red-100 p-2 relative overflow-hidden">
      {/* Fondo con brillos */}
      <div className="absolute inset-0 animate-twinkle">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full bg-white"
            style={{
              width: Math.random() * 4 + "px",
              height: Math.random() * 4 + "px",
              left: Math.random() * 100 + "%",
              top: Math.random() * 100 + "%",
              animation: `twinkle ${Math.random() * 3 + 2}s infinite`,
            }}
          />
        ))}
      </div>

      <div
        className={`max-w-4xl mx-auto transition-all duration-1000 ${
          isVisible ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
        }`}
      >
        {/* Encabezado con efectos */}
        <div className="text-center mb-12 relative">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-32 h-32 bg-pink-200 rounded-full filter blur-xl animate-pulse opacity-70" />
          </div>
          <Heart className="inline-block text-red-500 animate-bounce w-20 h-20 mb-4" />
          <h1 className="text-5xl font-bold text-pink-600 mb-4 relative animate-float">
            Para mi Anita
          </h1>
          <p className="text-xl text-gray-700 italic animate-fade-in">
            {frases[currentPhotoIndex % frases.length]}
          </p>
        </div>

        {/* Sección de video con decoración */}
        <div className="relative mb-12 transform hover:scale-105 transition-transform duration-500">
          <div className="absolute -inset-4 bg-gradient-to-r from-pink-300 via-purple-300 to-red-300 rounded-lg blur-lg opacity-75 animate-pulse" />
          <div className="relative bg-white rounded-lg p-4 shadow-xl">
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/bIfJ3HPOLvg?si=LlWMxvQtz7H_UCL9"
              title="Nuestra Canción"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-lg"
            />
          </div>
        </div>

        {/* Carrusel de fotos mejorado */}
        <div className="relative rounded-xl overflow-hidden shadow-2xl mb-12 group">
          <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-500 opacity-0 group-hover:opacity-20 transition-opacity duration-300" />
          <img
            src={photos[currentPhotoIndex]}
            alt="Nuestros momentos especiales"
            className="w-full h-80 object-cover transform transition-transform duration-700 group-hover:scale-110"
          />
          <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/50 to-transparent">
            <p className="text-white text-center text-lg font-medium">
              Nuestros momentos más especiales
            </p>
          </div>
        </div>

        {/* Mensaje romántico con animación */}
        <div
          className={`bg-white/90 backdrop-blur-lg p-8 rounded-xl shadow-2xl mb-12 transform transition-all duration-1000 ${
            showMessage
              ? "translate-y-0 opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <div className="prose prose-lg max-w-none text-gray-800">
            <p className="text-xl leading-relaxed text-center">
              Cada día me gustas más, como una estrella que brilla un poquito
              más fuerte en el cielo. Estar contigo es un regalo, y tu sonrisa
              ilumina mi mundo de una forma especial. No sé qué nos depara el
              futuro, pero por ahora, me encanta el presente contigo.
            </p>
          </div>
        </div>

        {/* Lluvia de corazones y estrellas */}
        <div className="fixed top-0 left-0 w-full h-full pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <React.Fragment key={i}>
              <Heart
                className="absolute text-pink-500 opacity-50 animate-float"
                style={{
                  left: `${Math.random() * 100}%`,
                  animationDelay: `${Math.random() * 5}s`,
                  fontSize: `${Math.random() * 20 + 10}px`,
                }}
              />
              <Stars
                className="absolute text-purple-400 opacity-40 animate-spin-slow"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDelay: `${Math.random() * 7}s`,
                  fontSize: `${Math.random() * 15 + 8}px`,
                }}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoveYou;
