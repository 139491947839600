import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  Search,
  Info,
  X,
  Clock,
  Trophy,
  AlertCircle,
  Zap,
} from "lucide-react";
import useUserData from "../../Hooks/useUserData";
import FootballLoading from "../../Utils/FootballLoading";
import AlertPayVip from "./AlertPayVip";
import RadioOnline from "../radio/RadioOnline";

// Styles for animations
const styles = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes slideIn {
    from { 
      opacity: 0;
      transform: scale(0.95) translateY(-10px);
    }
    to { 
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  @keyframes glow {
    0% { box-shadow: 0 0 5px rgba(255,255,255,0.1); }
    50% { box-shadow: 0 0 20px rgba(255,255,255,0.2); }
    100% { box-shadow: 0 0 5px rgba(255,255,255,0.1); }
  }

  .animate-fadeIn {
    animation: fadeIn 0.2s ease-out;
  }

  .animate-slideIn {
    animation: slideIn 0.3s ease-out;
  }

  .animate-pulse-custom {
    animation: pulse 2s infinite ease-in-out;
  }

  .animate-glow {
    animation: glow 2s infinite ease-in-out;
  }
`;

// Inject styles into document
if (typeof document !== "undefined") {
  const styleSheet = document.createElement("style");
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4 overflow-y-auto animate-fadeIn backdrop-blur-sm"
      onClick={handleBackdropClick}
    >
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl w-full max-w-2xl relative transform animate-slideIn border border-gray-700 shadow-xl">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors duration-200 p-1 rounded-full hover:bg-gray-700 z-10"
        >
          <X size={24} />
        </button>
        <div className="max-h-[90vh] overflow-y-auto custom-scrollbar">
          {children}
        </div>
      </div>
    </div>
  );
};

const DetallesPartido = ({ isOpen, onClose, matchId }) => {
  const [matchDetails, setMatchDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getDatabase();

  useEffect(() => {
    if (!matchId) return;

    setLoading(true);
    // Reference to the specific match in Firebase
    const matchRef = ref(db, `abetvip/${matchId}`);

    // Set up real-time listener
    const unsubscribe = onValue(matchRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setMatchDetails(data);
      }
      setLoading(false);
    });

    // Clean up listener on component unmount or when matchId changes
    return () => unsubscribe();
  }, [db, matchId]);

  if (!isOpen) return null;

  if (loading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="p-8 flex justify-center items-center min-h-[300px]">
          <FootballLoading />
        </div>
      </Modal>
    );
  }

  if (!matchDetails) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="p-8 text-center text-white">
          <AlertCircle size={48} className="mx-auto text-yellow-500 mb-4" />
          <h2 className="text-xl font-bold">
            No se encontraron detalles del partido
          </h2>
          <p className="text-gray-400 mt-2">
            Intenta con otro partido o vuelve más tarde
          </p>
        </div>
      </Modal>
    );
  }

  // Determine if match is live directly from matchDetails
  const isLive = matchDetails.isLive;

  // Format match time display
  const getTimeDisplay = () => {
    return isLive ? "En juego" : "Por comenzar";
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <h2 className="text-2xl font-bold text-white mb-6 text-center mt-2">
          Detalles del Partido
        </h2>

        {/* Match status indicator */}
        <div className="flex justify-center mb-4">
          <div
            className={`px-4 py-1 rounded-full text-sm font-medium ${
              isLive
                ? "bg-red-500 animate-pulse"
                : isLive === "Finalizado"
                ? "bg-gray-600"
                : "bg-blue-600"
            }`}
          >
            {isLive ? (
              <div className="flex items-center space-x-2">
                <span className="h-2 w-2 bg-white rounded-full"></span>
                <span>EN VIVO • {getTimeDisplay()}</span>
              </div>
            ) : (
              getTimeDisplay()
            )}
          </div>
        </div>

        <div className="flex items-center justify-center space-x-8 mb-6">
          <div className="text-center">
            <img
              src={matchDetails.homeTeam.logo}
              alt={matchDetails.homeTeam.name}
              className="w-20 h-20 rounded-full mb-2 mx-auto border-2 border-gray-700"
            />
            <h3 className="font-semibold text-white">
              {matchDetails.homeTeam.name}
            </h3>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-4xl font-bold text-white mb-2">
              <span className={isLive ? "animate-pulse text-yellow-500" : ""}>
                {matchDetails.homeTeam?.score || "0"}
              </span>
              <span className="mx-2">:</span>
              <span className={isLive ? "animate-pulse text-yellow-500" : ""}>
                {matchDetails.awayTeam?.score || "0"}
              </span>
            </div>
            {/* <div className="text-xs text-gray-400">
              {matchDetails.league || "Liga no especificada"}
            </div> */}
          </div>
          <div className="text-center">
            <img
              src={matchDetails.awayTeam.logo}
              alt={matchDetails.awayTeam.name}
              className="w-20 h-20 rounded-full mb-2 mx-auto border-2 border-gray-700"
            />
            <h3 className="font-semibold text-white">
              {matchDetails.awayTeam.name}
            </h3>
          </div>
        </div>

        <div className="space-y-4">
          <div className="bg-gray-700 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-2 text-white">
              Estadísticas
            </h4>
            <p className="text-gray-300">
              {matchDetails?.data || "No hay en el momento"}
            </p>
          </div>
          <div className="bg-gray-700 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-2 text-white">
              Detalles de la Predicción
            </h4>
            <p className="text-gray-300">{matchDetails.prediction}</p>
            <div className="mt-4">
              <div className="flex justify-between items-center mb-2">
                <span className="text-white">Confianza</span>
                <span className="text-green-500">
                  {matchDetails.confidence}%
                </span>
              </div>
              <div className="w-full bg-gray-600 rounded-full h-2">
                <div
                  className="bg-green-500 rounded-full h-2 transition-all duration-500"
                  style={{ width: `${matchDetails.confidence}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const TarjetaPrediccion = ({ prediction, onShowDetails }) => {
  const [liveScore, setLiveScore] = useState({
    home: prediction.homeTeam?.score || 0,
    away: prediction.awayTeam?.score || 0,
  });
  const db = getDatabase();

  useEffect(() => {
    // Reference to the specific match in Firebase
    const matchRef = ref(db, `abetvip/${prediction.id}`);

    // Set up real-time listener for score updates
    const unsubscribe = onValue(matchRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setLiveScore({
          home: data.homeTeam?.score || 0,
          away: data.awayTeam?.score || 0,
          isLive: data.isLive,
          minute: data.minute,
        });
      }
    });

    // Clean up listener on component unmount
    return () => unsubscribe();
  }, [db, prediction.id]);

  // Determine status color and icon
  const getStatusConfig = () => {
    const statusConfig = {
      pendiente: {
        bg: "from-amber-500 to-orange-600",
        textColor: "text-amber-900",
        icon: <Clock size={16} className="mr-1" />,
        label: "PENDIENTE",
      },
      ganada: {
        bg: "from-emerald-500 to-green-600",
        textColor: "text-emerald-900",
        icon: <Trophy size={16} className="mr-1" />,
        label: "GANADA",
      },
      perdida: {
        bg: "from-rose-500 to-red-600",
        textColor: "text-rose-900",
        icon: <X size={16} className="mr-1" />,
        label: "PERDIDA",
      },
      nula: {
        bg: "from-gray-400 to-gray-500",
        textColor: "text-gray-800",
        icon: <AlertCircle size={16} className="mr-1" />,
        label: "NULA",
      },
    };

    return statusConfig[prediction.state] || statusConfig.pendiente;
  };

  const status = getStatusConfig();

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 hover:scale-105 border border-gray-700 hover:border-gray-600 hover:shadow-2xl">
      {/* Header with live indicator if applicable */}
      {liveScore.isLive && (
        <div className="bg-gradient-to-r from-red-600 to-red-500 px-4 py-2 text-white font-medium animate-pulse flex items-center justify-center">
          <span className="h-2 w-2 bg-white rounded-full mr-2"></span>
          <span>EN VIVO</span>
        </div>
      )}

      <div className="p-1">
        {/* Teams and odds section */}
        <div className="flex justify-between mb-3">
          <div className="flex-1 flex justify-between items-center">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 bg-gradient-to-br from-gray-800 to-gray-700 rounded-lg p-1 shadow-lg mb-2 flex items-center justify-center">
                <img
                  src={prediction.homeTeam.logo}
                  alt={prediction.homeTeam.name}
                  className="w-12 h-12 object-contain"
                />
              </div>
              <span className="text-white font-medium text-sm text-center max-w-20 truncate">
                {prediction.homeTeam.name}
              </span>
            </div>

            <div className="text-center mx-2 flex flex-col">
              <span className="text-yellow-500 font-bold text-lg">VS</span>
              {/* Hora del partido */}
              <span className="text-gray-300 text-xs font-medium bg-gray-800 px-2 py-1 rounded mt-1">
                {prediction.hours || "TBA"}
              </span>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 bg-gradient-to-br from-gray-800 to-gray-700 rounded-lg p-1 shadow-lg mb-2 flex items-center justify-center">
                <img
                  src={prediction.awayTeam.logo}
                  alt={prediction.awayTeam.name}
                  className="w-12 h-12 object-contain"
                />
              </div>
              <span className="text-white font-medium text-sm text-center max-w-20 truncate">
                {prediction.awayTeam.name}
              </span>
            </div>
          </div>

          <div className="ml-4 flex flex-col items-center justify-center">
            <div className="bg-gradient-to-r from-green-500 to-orange-500 text-gray-900 font-bold py-2 px-4 rounded-lg text-lg shadow-lg mb-1">
              {prediction.odds.toFixed(2)}
            </div>
            <span className="text-gray-400 text-xs">CUOTA</span>
          </div>
        </div>

        {/* Prediction */}
        <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-lg p-4 mb-4 shadow-inner">
          <p className="text-gray-300 text-sm leading-relaxed">
            <span className="text-yellow-400 font-semibold">Predicción: </span>
            {prediction.prediction}
          </p>
        </div>

        {/* Confidence bar */}
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-400 text-sm">Nivel de Confianza</span>
            <span className="text-green-400 font-bold bg-gray-800 px-2 py-0.5 rounded text-sm">
              {prediction.confidence}%
            </span>
          </div>
          <div className="w-full bg-gray-700 rounded-full h-3 overflow-hidden shadow-inner">
            <div
              className="bg-gradient-to-r from-green-500 to-emerald-400 rounded-full h-3 transition-all duration-500"
              style={{ width: `${prediction.confidence}%` }}
            />
          </div>
        </div>

        {/* Details button */}
        <button
          onClick={() => onShowDetails(prediction.id)}
          className="w-full bg-gradient-to-r from-indigo-600 to-blue-600 hover:from-indigo-700 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-200 flex items-center justify-center space-x-2 shadow-lg"
        >
          <Info size={18} />
          <span>Ver Detalles</span>
        </button>
      </div>

      {/* Status footer */}
      <div
        className={`py-2 text-center font-bold text-sm uppercase tracking-wider bg-gradient-to-r ${status.bg} ${status.textColor} flex items-center justify-center`}
      >
        {status.icon}
        {status.label}
      </div>
    </div>
  );
};

const BetVip = () => {
  const [predictions, setPredictions] = useState([]);
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { userData, loading, error } = useUserData();
  const db = getDatabase();

  useEffect(() => {
    const abetVipRef = ref(db, "abetvip");
    const today = new Date();
    const todayFormatted = `${String(today.getDate()).padStart(
      2,
      "0"
    )}/${String(today.getMonth() + 1).padStart(2, "0")}/${today.getFullYear()}`;

    // Set up real-time listener for all predictions
    const unsubscribe = onValue(abetVipRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedPredictions = Object.keys(data)
          .map((key) => ({ id: key, ...data[key] }))
          .filter((prediction) => prediction.date === todayFormatted);
        setPredictions(loadedPredictions);
      } else {
        setPredictions([]);
      }
    });

    // Clean up listener on component unmount
    return () => unsubscribe();
  }, [db]);

  // Filter predictions based on search query
  const filteredPredictions = predictions.filter(
    (pred) =>
      pred.homeTeam.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pred.awayTeam.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) return <FootballLoading />;
  if (error) return <p>Error: {error}</p>;

  if (userData?.typeU !== "Cuenta Vip") {
    return <AlertPayVip />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-900  text-white py-1 px-2 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header with search */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-white mb-6 text-center">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-500">
              Predicciones VIP
            </span>
          </h1>

          <div className="relative max-w-md mx-auto">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search size={18} className="text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Buscar por nombre de equipo..."
              className="w-full bg-gray-800 border border-gray-700 rounded-lg py-2 pl-10 pr-4 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        {/* Live matches section - only shows if there are live matches */}
        {predictions.some((p) => p.isLive === true) && (
          <div className="mb-12">
            <div className="flex items-center mb-6">
              <div className="flex-grow h-px bg-gradient-to-r from-transparent via-red-500 to-transparent"></div>
              <h2 className="text-2xl font-bold text-white mx-4 flex items-center">
                <span className="h-3 w-3 bg-red-500 rounded-full mr-2 animate-pulse"></span>
                <Zap size={20} className="text-red-500 mr-2" />
                En Directo
              </h2>
              <div className="flex-grow h-px bg-gradient-to-r from-red-500 via-red-500 to-transparent"></div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredPredictions
                .filter((p) => p.isLive === true)
                .map((prediction) => (
                  <TarjetaPrediccion
                    key={prediction.id}
                    prediction={prediction}
                    onShowDetails={setSelectedMatchId}
                  />
                ))}
            </div>
          </div>
        )}
        {/* Upcoming matches */}
        <div>
          <div className="flex items-center mb-6">
            <div className="flex-grow h-px bg-gradient-to-r from-transparent via-indigo-500 to-transparent"></div>
            <h2 className="text-2xl font-bold text-white mx-4">
              Predicciones del Día
            </h2>
            <div className="flex-grow h-px bg-gradient-to-r from-indigo-500 via-indigo-500 to-transparent"></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredPredictions
              .filter((p) => p.isLive !== true)
              .map((prediction) => (
                <TarjetaPrediccion
                  key={prediction.id}
                  prediction={prediction}
                  onShowDetails={setSelectedMatchId}
                />
              ))}
          </div>
        </div>

        {/* Match details modal */}
        <DetallesPartido
          isOpen={!!selectedMatchId}
          onClose={() => setSelectedMatchId(null)}
          matchId={selectedMatchId}
        />

        {/* Empty state */}
        {predictions.length === 0 && (
          <div className="text-center py-16 bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl border border-gray-700 shadow-lg mt-6">
            <AlertCircle size={48} className="mx-auto text-yellow-500 mb-4" />
            <p className="text-2xl text-gray-300 mb-4 font-bold">
              No hay predicciones disponibles
            </p>
            <p className="text-gray-500 max-w-md mx-auto">
              Las predicciones para hoy aún no han sido publicadas. Vuelve más
              tarde para ver nuevas predicciones VIP.
            </p>
          </div>
        )}

        {/* Filtered but empty results */}
        {predictions.length > 0 && filteredPredictions.length === 0 && (
          <div className="text-center py-16 bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl border border-gray-700 shadow-lg mt-6">
            <Search size={48} className="mx-auto text-blue-500 mb-4" />
            <p className="text-2xl text-gray-300 mb-4 font-bold">
              No se encontraron resultados
            </p>
            <p className="text-gray-500 max-w-md mx-auto">
              No hay predicciones que coincidan con "{searchQuery}". Intenta con
              otro término de búsqueda.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BetVip;
