import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Home,
  Brain,
  Users,
  ListOrdered,
  MessageCircleQuestion,
  Flame,
  Bell,
  Settings,
  Search,
  TrendingUp,
} from "lucide-react";
import BetVip from "./BetVip";
import SocialWorld from "../World/SocialWorld";
import Marcadores from "../Home/Marcadores";
import ChatIA from "./ChatIA";
import Combi from "./Combi";

const tabs = [
  { id: "Inicio", label: "Inicio", icon: Home, color: "text-blue-500" },
  { id: "Feed", label: "Feed", icon: Users, color: "text-green-500" },
  { id: "Parlay", label: "Parlay", icon: Flame, color: "text-red-500" },
  {
    id: "Marcador",
    label: "Marcador",
    icon: ListOrdered,
    color: "text-yellow-500",
  },
  { id: "IA", label: "Asistente", icon: Brain, color: "text-purple-500" },
];

export default function HomeVip() {
  const [activeTab, setActiveTab] = useState("Inicio");
  const [showNotification, setShowNotification] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      message: "¡Nuevo partido destacado disponible!",
      time: "hace 5 min",
    },
    { id: 2, message: "Tu apuesta fue ganadora. +$250", time: "hace 1 hora" },
  ]);

  // Detecta cuando la pestaña es "WhatsApp" y redirige
  useEffect(() => {
    if (activeTab === "WhatsApp") {
      window.location.href = "https://wa.me/573023580862?text=Hey!%20Pro";
    }
  }, [activeTab]);

  // Simular notificación nueva después de 30 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showNotification) {
        setShowNotification(true);
        // Añadir nueva notificación
        setNotifications((prev) => [
          {
            id: Date.now(),
            message: "¡Oferta especial! 2x1 en apuestas",
            time: "ahora",
          },
          ...prev,
        ]);
      }
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  // Función para manejar el clic en el icono flotante de Flame
  const handleFlameClick = () => {
    setActiveTab("Parlay");
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Inicio":
        return <BetVip />;
      case "Feed":
        return <SocialWorld />;
      case "Marcador":
        return <Marcadores />;
      case "Parlay":
        return <Combi />;
      case "IA":
        return <ChatIA />;
      default:
        return <BetVip />;
    }
  };

  return (
    <div className="min-h-screen flex flex-col w-full bg-gradient-to-b from-gray-900 to-blue-900 text-white">
      {/* Banner de tendencias */}
      <div className="bg-gradient-to-r from-blue-900 to-purple-900 p-2 overflow-hidden">
        <div className="flex items-center space-x-2 animate-pulse">
          <TrendingUp size={16} className="text-yellow-400" />
          <div className="whitespace-nowrap animate-marquee">
            <span className="mx-4">
              🔥 Real Madrid vs Barcelona - Cuota especial 2.5
            </span>
            <span className="mx-4">
              ⚽ Premier League - Liverpool lidera la tabla
            </span>
            <span className="mx-4">🏀 NBA Finals - Lakers vs Celtics</span>
            <span className="mx-4">
              💰 ¡50 usuarios ganaron con nuestro último pronóstico!
            </span>
          </div>
        </div>
      </div>

      {/* Contenedor principal con animación */}
      <AnimatePresence mode="wait">
        <motion.main
          key={activeTab}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
          className="flex-grow w-full p-3"
        >
          {renderTabContent()}
        </motion.main>
      </AnimatePresence>

      {/* Barra de navegación fija en la parte inferior */}
      <nav className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-blue-900 to-blue-700 shadow-lg rounded-t-xl">
        <div className="flex justify-around p-2">
          {tabs.map(({ id, label, icon: Icon, color }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className="relative flex flex-col items-center text-white transition-all duration-300 hover:opacity-75 py-1"
            >
              <div
                className={`p-2 rounded-full ${
                  activeTab === id ? `bg-opacity-20 bg-white ${color}` : ""
                }`}
              >
                <Icon
                  size={20}
                  className={activeTab === id ? color : "text-gray-300"}
                />
              </div>
              <span
                className={`text-xs mt-1 ${
                  activeTab === id ? "font-medium " + color : "text-gray-300"
                }`}
              >
                {label}
              </span>
              {activeTab === id && (
                <motion.div
                  layoutId="activeTab"
                  className="absolute -bottom-2 h-1 w-8 bg-white rounded-full"
                />
              )}
            </button>
          ))}
        </div>
      </nav>

      {/* Animación flotante para promociones - Ahora abre Combi al hacer clic */}
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{
          opacity: 1,
          scale: 1,
          y: [0, -10, 0],
          transition: { y: { repeat: Infinity, duration: 2 } },
        }}
        onClick={handleFlameClick}
        className="fixed bottom-20 right-4 bg-gradient-to-r from-yellow-500 to-red-500 rounded-full p-3 shadow-lg cursor-pointer z-10"
      >
        <Flame size={24} className="text-white" />
      </motion.div>
    </div>
  );
}
