import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Search, Calendar, Clock, TrendingUp, CheckCircle, AlertCircle, X, Trophy, Award, Activity } from 'lucide-react';

import { Helmet } from 'react-helmet';
import CarouselTransition from '../../Components/CarouselTransition';
import MessageVip from '../../Components/MessageVip';
import AppAnality from '../../Config/AppAnality';
import SocialMediaBanner from '../../Components/SocialMediaBanner';
import RadioOnline from '../radio/RadioOnline';
const getCurrentDateTime = () => {
  const today = new Date();
  return today.toLocaleString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit', 
    hour: '2-digit',
    minute: '2-digit',
  }).replace(',', '');
};

const PredictionCard = ({ prediction }) => {
  // Configuración de estados con iconos de Lucide
  const statusConfig = {
    ganada: {
      bg: 'bg-emerald-600',
      gradient: 'from-emerald-500 to-green-600',
      icon: <CheckCircle size={20} className="mr-2" />,
      text: 'Predicción Ganada'
    },
    perdida: {
      bg: 'bg-rose-600',
      gradient: 'from-rose-500 to-red-600',
      icon: <X size={20} className="mr-2" />,
      text: 'Predicción Perdida'
    },
    pendiente: {
      bg: 'bg-amber-500',
      gradient: 'from-amber-400 to-yellow-500',
      icon: <Clock size={20} className="mr-2" />,
      text: 'Predicción Pendiente'
    },
    nula: {
      bg: 'bg-slate-500',
      gradient: 'from-slate-400 to-gray-500',
      icon: <AlertCircle size={20} className="mr-2" />,
      text: 'Predicción Nula'
    }
  };

  // Obtener la configuración según el estado
  const status = statusConfig[prediction.state] || statusConfig.pendiente;

  return (
    <div className="m-2 relative overflow-hidden bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-xl border border-gray-700 transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
      {/* Indicador de estado en la esquina superior */}
      <div className="absolute top-0 right-0 w-16 h-16 overflow-hidden">
        <div className={`bg-gradient-to-r ${status.gradient} rotate-45 transform origin-bottom-left w-24 h-24 flex items-center justify-center`}>
          <div className="rotate-45 transform origin-center text-white">
            {prediction.state === "ganada" && <Trophy size={16} />}
            {prediction.state === "perdida" && <X size={16} />}
            {prediction.state === "pendiente" && <Clock size={16} />}
            {prediction.state === "nula" && <AlertCircle size={16} />}
          </div>
        </div>
      </div>

      <div className="p-5">
        {/* Cabecera con tiempo y confianza */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center text-gray-400">
            <Clock size={16} className="mr-2 text-gray-400" />
            <span className="text-sm">{prediction.hours}</span>
          </div>
          <div className="flex items-center">
            <span className="bg-gradient-to-r from-violet-600 to-indigo-600 text-white text-xs px-3 py-1 rounded-full font-medium flex items-center shadow-lg">
              <Activity size={14} className="mr-1" />
              {prediction.confidence}% Confianza
            </span>
          </div>
        </div>

        {/* Equipos y partido - Diseño mejorado */}
        <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-lg p-4 mb-4 shadow-inner">
          <div className="flex items-center justify-between">
            {/* Equipo local */}
            <div className="flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-gradient-to-br from-gray-700 to-gray-600 rounded-full p-1 shadow-lg mb-2 flex items-center justify-center">
                <img 
                  src={prediction.homeTeam.logo} 
                  alt={prediction.homeTeam.name} 
                  className="w-10 h-10 rounded-full object-cover"
                />
              </div>
              <span className="text-white font-semibold text-sm">{prediction.homeTeam.name}</span>
            </div>

            {/* VS central con diseño destacado */}
            <div className="flex flex-col items-center">
              <div className="bg-gradient-to-r from-gray-600 to-gray-500 rounded-full w-10 h-10 flex items-center justify-center shadow-lg mb-1">
                <span className="text-white font-bold text-xs">VS</span>
              </div>
              <span className="text-gray-400 text-xs">PARTIDO</span>
            </div>

            {/* Equipo visitante */}
            <div className="flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-gradient-to-br from-gray-700 to-gray-600 rounded-full p-1 shadow-lg mb-2 flex items-center justify-center">
                <img 
                  src={prediction.awayTeam.logo} 
                  alt={prediction.awayTeam.name} 
                  className="w-10 h-10 rounded-full object-cover"
                />
              </div>
              <span className="text-white font-semibold text-sm">{prediction.awayTeam.name}</span>
            </div>
          </div>
        </div>

        {/* Información de la apuesta */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="bg-gray-800 rounded-lg p-3 shadow-inner flex items-center">
            <TrendingUp size={18} className="text-green-400 mr-2" />
            <div>
              <span className="text-gray-400 text-xs block">CUOTA</span>
              <span className="text-green-400 font-bold">{prediction.odds}</span>
            </div>
          </div>
          <div className="bg-gray-800 rounded-lg p-3 shadow-inner flex items-center">
            <Award size={18} className="text-blue-400 mr-2" />
            <div>
              <span className="text-gray-400 text-xs block">DEPORTE</span>
              <span className="text-blue-400 font-semibold">⚽ Fútbol</span>
            </div>
          </div>
        </div>

        {/* Predicción con diseño destacado */}
        <div className="bg-gradient-to-r from-yellow-900 via-amber-800 to-yellow-900 rounded-lg p-4 mb-4 shadow-md border border-yellow-800">
          <p className="text-yellow-300 font-medium text-center">
            {prediction.prediction}
          </p>
        </div>

        {/* Estado de la predicción */}
        <div className={`bg-gradient-to-r ${status.gradient} text-white p-3 rounded-lg shadow-md flex items-center justify-center`}>
          {status.icon}
          <span className="font-bold">{status.text}</span>
        </div>

        {/* Indicador de retorno para predicciones ganadas */}
        {prediction.state === "ganada" && (
          <div className="mt-3 bg-gray-800 rounded-lg p-2 border border-green-800 shadow-inner">
            <div className="flex items-center justify-center">
              <TrendingUp size={16} className="text-green-400 mr-2" />
              <span className="text-green-400 text-sm">
                Retorno: <span className="font-bold">+{(prediction.odds * 100 - 100).toFixed(0)}%</span>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const BetFree = ({ limit }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const abetRef = ref(db, 'abet');

    onValue(abetRef, (snapshot) => {
      const data = snapshot.val();
      const loadedPredictions = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));

      const todayPredictions = loadedPredictions.filter(
        (prediction) => prediction.date === currentDateTime.split(' ')[0]
      );

      setPredictions(todayPredictions);
    });
  }, [currentDateTime]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredPredictions = predictions.filter((prediction) =>
    prediction.homeTeam.name.toLowerCase().includes(searchTerm) ||
    prediction.awayTeam.name.toLowerCase().includes(searchTerm) ||
    prediction.prediction.toLowerCase().includes(searchTerm)
  );

  // Limitar las predicciones si se define un límite
  const predictionsToShow = limit ? filteredPredictions.slice(0, limit) : filteredPredictions;

  return (
    <>
      <Helmet>
        <title>
          Pronósticos Deportivos: Consejos GRATIS | Pronósticos Diarios
        </title>
        <meta
          name="description"
          content="Obtén los mejores consejos de apuestas deportivas gratis. Pronósticos actualizados diariamente para fútbol y otros deportes. Mejora tus probabilidades de ganar hoy."
        />
        <meta
          name="keywords"
          content="apuestas deportivas, pronósticos gratis, consejos de apuestas, fútbol, predicciones deportivas"
        />
        <meta
          property="og:title"
          content="Predicciones deportivas Gratis | Pronósticos Diarios"
        />
        <meta
          property="og:description"
          content="Mejora tus probabilidades de ganar con nuestros consejos de apuestas deportivas gratis y actualizados diariamente."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://deportivospro.online/" />
        <link rel="canonical" href="https://deportivospro.online/" />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          {/* <SocialMediaBanner/> */}
          <RadioOnline />
          <h1 className="text-4xl font-extrabold text-center ">
            Consejos del día
          </h1>
          <p className="text-center text-gray-400 mb-10 flex justify-center items-center mt-2">
            <Calendar size={20} className="mr-2" />
            <span>{currentDateTime}</span>
          </p>

          <div className="mb-8 max-w-md mx-auto">
            <div className="relative m-2">
              <input
                type="text"
                placeholder="Buscar equipos o pronósticos..."
                className="w-full bg-gray-800 text-white border border-gray-700 rounded-full py-3 px-6 pl-12 focus:outline-none"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Search
                className="absolute left-4 top-3.5 text-gray-400"
                size={20}
              />
            </div>
          </div>

          {predictionsToShow.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {predictionsToShow.map((prediction) => (
                <PredictionCard key={prediction.id} prediction={prediction} />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-400 mt-8 p-8 bg-gray-800 rounded-lg shadow-lg">
              <TrendingUp size={48} className="mx-auto mb-4" />
              <p className="text-xl font-semibold mb-2">
                No hay pronósticos disponibles en este momento
              </p>
              <p>
                Vuelve a consultar más tarde o explora nuestro historial de
                predicciones para obtener más información.
              </p>
            </div>
          )}
        </div>
        <MessageVip />
      </div>
    </>
  );
};

export default BetFree;
