import React, { useState, useEffect } from "react";
import { ref, push, onValue, set, update } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { Heart, Send, Image as ImageIcon } from "lucide-react";
import { auth, database } from "../../Config/firebase";
import useUserData from "../../Hooks/useUserData";
import FootballLoading from "../../Utils/FootballLoading";
import AlertPayVip from "../Vip/AlertPayVip";

const storage = getStorage();

export default function SocialWorld() {
  const [posts, setPosts] = useState([]); // Estado para las publicaciones
  const [newPost, setNewPost] = useState({ text: "", image: null }); // Estado para la nueva publicación
  const [selectedImage, setSelectedImage] = useState(null); // Estado para la imagen seleccionada

  // Cargar publicaciones desde Firebase
  useEffect(() => {
    const postsRef = ref(database, "world/posts");
    onValue(postsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const postsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        // Ordenar las publicaciones por timestamp (de más reciente a más antiguo)
        const sortedPosts = postsArray.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        setPosts(sortedPosts);
      }
    });
  }, []);

  // Publicar una nueva publicación
  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user || !newPost.text.trim()) return;

    let imageUrl = null;
    if (newPost.image) {
      const fileRef = storageRef(storage, `posts/${uuidv4()}`);
      await uploadBytes(fileRef, newPost.image);
      imageUrl = await getDownloadURL(fileRef);
    }

    const post = {
      text: newPost.text.trim(),
      imageUrl,
      userId: user.uid,
      userName: user.displayName || user.email?.split("@")[0] || "Anónimo",
      userEmail: user.email,
      timestamp: Date.now(),
      likes: {}, // Objeto para almacenar likes por usuario
    };

    const postsRef = ref(database, `world/posts/${user.uid}_${Date.now()}`);
    await set(postsRef, post);
    setNewPost({ text: "", image: null });
    setSelectedImage(null);
  };

  // Dar like a una publicación
  const handleLike = (postId) => {
    const user = auth.currentUser;
    if (!user) return;

    const postRef = ref(database, `world/posts/${postId}`);
    const post = posts.find((p) => p.id === postId);

    if (post.likes && post.likes[user.uid]) {
      // Si el usuario ya dio like, quitarlo
      const updatedLikes = { ...post.likes };
      delete updatedLikes[user.uid];
      update(postRef, { likes: updatedLikes });
    } else {
      // Si el usuario no ha dado like, agregarlo
      const updatedLikes = { ...post.likes, [user.uid]: true };
      update(postRef, { likes: updatedLikes });
    }
  };
  const { userData, loading, error } = useUserData();

  if (loading) return <FootballLoading />;
  if (error) return <p>Error: {error}</p>;

if (userData?.typeU !== "Cuenta Vip") {
  return <AlertPayVip />; // ✅ Correcto
}

 return (
   <div className="min-h-screen bg-gradient-to-b text-gray-100">
     {/* Contenido principal */}
     <main className="max-w-4xl mx-auto px-1 pt-2 pb-16">
       {/* Área para crear publicaciones */}
       <div className="bg-gray-800 rounded-xl p-2 mb-6 mt-3 shadow-lg border border-gray-700">
         <form onSubmit={handlePostSubmit} className="space-y-3">
           <div className="flex items-start space-x-3">
             <textarea
               placeholder="¿Qué está pasando?"
               value={newPost.text}
               onChange={(e) =>
                 setNewPost({ ...newPost, text: e.target.value })
               }
               className="w-full p-3 bg-gray-700 rounded-lg border border-gray-600 focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-400 placeholder-gray-400 resize-none"
               rows="2"
             />
           </div>

           {selectedImage && (
             <div className="relative mt-2 rounded-lg overflow-hidden border border-gray-700">
               <img
                 src={selectedImage}
                 alt="Vista previa"
                 className="max-h-64 w-full object-cover"
               />
               <button
                 onClick={() => {
                   setSelectedImage(null);
                   setNewPost({ ...newPost, image: null });
                 }}
                 className="absolute top-2 right-2 bg-gray-900 bg-opacity-70 rounded-full p-1 hover:bg-opacity-100 transition-all"
                 aria-label="Eliminar imagen"
               >
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   viewBox="0 0 20 20"
                   fill="currentColor"
                 >
                   <path
                     fillRule="evenodd"
                     d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                     clipRule="evenodd"
                   />
                 </svg>
               </button>
             </div>
           )}

           <div className="flex items-center justify-between border-t border-gray-700 pt-3">
             <div className="flex items-center space-x-2">
               <label className="cursor-pointer flex items-center gap-2 text-blue-400 hover:text-blue-300 p-2 rounded-full hover:bg-gray-700 transition-colors">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                   />
                 </svg>
                 <input
                   type="file"
                   className="hidden"
                   onChange={(e) => {
                     const file = e.target.files[0];
                     setNewPost({ ...newPost, image: file });
                     setSelectedImage(URL.createObjectURL(file));
                   }}
                   accept="image/*"
                 />
               </label>
               <button
                 type="button"
                 className="p-2 text-blue-400 hover:text-blue-300 rounded-full hover:bg-gray-700 transition-colors"
               >
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                   />
                 </svg>
               </button>
               <button
                 type="button"
                 className="p-2 text-blue-400 hover:text-blue-300 rounded-full hover:bg-gray-700 transition-colors"
               >
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                   />
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                   />
                 </svg>
               </button>
             </div>
             <button
               type="submit"
               className="bg-blue-500 text-white px-5 py-2 rounded-full hover:bg-blue-600 transition-colors font-medium disabled:opacity-50 disabled:cursor-not-allowed"
               disabled={!newPost.text.trim()}
             >
               Publicar
             </button>
           </div>
         </form>
       </div>

       {/* Feed de publicaciones */}
       <div className="space-y-4">
         {posts.map((post) => (
           <article
             key={post.id}
             className="bg-gray-800 rounded-xl p-4 shadow-lg border border-gray-700 transition-all hover:border-gray-600"
           >
             <div className="flex items-start space-x-3 mb-3">
               <img
                 src={`https://avatar.iran.liara.run/public/boy?username=${post.userEmail}`}
                 alt={post.userName}
                 className="w-10 h-10 rounded-full object-cover border-2 border-blue-500"
               />
               <div className="flex-1">
                 <div className="flex items-center justify-between">
                   <div>
                     <h3 className="font-bold text-md">{post.userName}</h3>
                     <p className="text-xs text-gray-400">
                       {new Date(post.timestamp).toLocaleString()}
                     </p>
                   </div>
                   <button className="text-gray-400 hover:text-gray-300">
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       className="h-5 w-5"
                       viewBox="0 0 20 20"
                       fill="currentColor"
                     >
                       <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                     </svg>
                   </button>
                 </div>
                 <p className="mt-2 text-md">{post.text}</p>
               </div>
             </div>

             {post.imageUrl && (
               <div className="mt-3 rounded-lg overflow-hidden border border-gray-700">
                 <img
                   src={post.imageUrl}
                   alt="Publicación"
                   className="w-full object-cover max-h-96"
                 />
               </div>
             )}

             <div className="flex items-center gap-4 mt-3 pt-3 border-t border-gray-700">
               <button
                 onClick={() => handleLike(post.id)}
                 className="flex items-center gap-1.5 text-gray-400 hover:text-blue-400 transition-colors"
               >
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={
                       post.likes && Object.keys(post.likes).length > 0 ? 0 : 2
                     }
                     d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                     fill={
                       post.likes && Object.keys(post.likes).length > 0
                         ? "currentColor"
                         : "none"
                     }
                   />
                 </svg>
                 <span className="text-sm font-medium">
                   {post.likes ? Object.keys(post.likes).length : 0}
                 </span>
               </button>

               <button className="flex items-center gap-1.5 text-gray-400 hover:text-green-400 transition-colors">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                   />
                 </svg>
                 <span className="text-sm font-medium">0</span>
               </button>

               <button className="flex items-center gap-1.5 text-gray-400 hover:text-blue-400 transition-colors">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                   />
                 </svg>
                 <span className="text-sm font-medium">Compartir</span>
               </button>

               <button className="flex items-center gap-1.5 text-gray-400 hover:text-yellow-400 transition-colors ml-auto">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-5 w-5"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                   />
                 </svg>
               </button>
             </div>
           </article>
         ))}
       </div>
     </main>
   </div>
 );
}
