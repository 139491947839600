import React, { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';
import { Helmet } from 'react-helmet';

const Marcadores = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://ls.soccersapi.com/widget/res/awo_w5785_668b090e9c75c/widget.js';
    script.async = true;
    
    script.onload = () => {
      setIsLoading(false);
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []); 
 
  return (
    <div className="min-h-screen w-full bg-gray-900 text-white flex flex-col">

      {/* SEO Optimized Helmet */}
      <Helmet>
        <title>Partidos en Vivo - Marcadores Actualizados | DeportivosPro</title>
        <meta
          name="description"
          content="Sigue los partidos en vivo con los marcadores más actualizados. Obtén pronósticos deportivos precisos y análisis experto en DeportivosPro."
        />
        <meta
          name="keywords"
          content="partidos en vivo, marcadores en vivo, resultados deportivos, fútbol, pronósticos, análisis de partidos, deportes"
        />
        <meta name="author" content="DeportivosPro" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://deportivospro.online/marcadores-en-vivo" />
        <meta property="og:title" content="Partidos en Vivo - Marcadores Actualizados | DeportivosPro" />
        <meta property="og:description" content="Consulta los marcadores en vivo y mantente al día con los últimos resultados de tus deportes favoritos en DeportivosPro." />
        <meta property="og:url" content="https://deportivospro.online/marcadores-en-vivo" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Partidos en Vivo - Marcadores Actualizados | DeportivosPro" />
        <meta name="twitter:description" content="Sigue los partidos en vivo y obtén resultados deportivos al instante en DeportivosPro." />
        <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2" />
      </Helmet>

      <div className="relative flex-grow w-full">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-10">
            <Loader2 className="w-12 h-12 animate-spin text-blue-400" />
          </div>
        )}
        <div
          id="ls-widget"
          data-w="awo_w5785_668b090e9c75c"
          className="livescore-widget w-full h-full"
        ></div>
      </div>
    </div>
  );
};

export default Marcadores;
