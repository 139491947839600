import React, { useEffect, useState } from "react";
import { database } from "../../Config/firebase";
import { ref, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { HelpCircle, Calendar, ArrowRight, Award, Clock } from "lucide-react";

export default function Combi() {
  const [combis, setCombis] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Cambiando la ruta a "combi1" según la estructura de la BD proporcionada
    const combiRef = ref(database, "/combi");
    onValue(combiRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Transformando la estructura de datos anidada
        const loadedCombis = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        // Obtener la fecha actual en formato DD/MM/AAAA
        const today = new Date();
        const day = String(today.getDate()).padStart(2, "0");
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const year = today.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        // Filtrar las combinadas por la fecha actual asegurando que la fecha exista
        const todaysCombis = loadedCombis.filter(
          (combi) => combi.date && combi.date === formattedDate
        );

        setCombis(todaysCombis);
      }
      setIsLoading(false);
    });
  }, []);

  // Función para manejar el botón de ayuda
  const handleHelp = (coment) => {
    const message = `Ayuda con esta combinada: ${coment}`;
    navigate(`/support?message=${encodeURIComponent(message)}`);
  };

  // Función para determinar el color y el icono del estado
  const getStatusStyles = (status) => {
    switch (status) {
      case "Pendiente":
        return {
          bgColor: "bg-amber-600",
          textColor: "text-amber-100",
          icon: <Clock size={16} className="mr-1" />,
        };
      case "Ganada":
        return {
          bgColor: "bg-emerald-600",
          textColor: "text-emerald-100",
          icon: <Award size={16} className="mr-1" />,
        };
      default:
        return {
          bgColor: "bg-rose-600",
          textColor: "text-rose-100",
          icon: <ArrowRight size={16} className="mr-1" />,
        };
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white py-12 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-center mb-8">
          <h1 className="text-4xl font-extrabold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
            Parlay del Día
          </h1>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : combis.length > 0 ? (
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {combis.map(({ id, img, coment, odds, status }) => {
              const statusStyles = getStatusStyles(status);

              return (
                <div
                  key={id}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-2xl shadow-lg border border-gray-700 transform hover:scale-105 transition duration-300 hover:shadow-blue-900/20 hover:shadow-xl"
                >
                  <div className="flex justify-between items-start mb-4">
                    <h2 className="text-lg font-medium text-gray-200 line-clamp-2">
                      {coment}
                    </h2>
                    <span
                      className={`ml-2 px-2 py-1 rounded-full text-xs font-semibold flex items-center whitespace-nowrap ${statusStyles.bgColor} ${statusStyles.textColor}`}
                    >
                      {statusStyles.icon} {status}
                    </span>
                  </div>

                  <div className="relative group">
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-600 rounded-xl opacity-75 group-hover:opacity-100 blur-sm transition-all duration-300"></div>
                    <img
                      src={img}
                      alt="Combinada"
                      className="relative w-full h-52 object-cover rounded-xl mb-4 cursor-pointer border border-gray-700 shadow-lg hover:shadow-blue-500/50 transition duration-300"
                      onClick={() => setSelectedImage(img)}
                    />
                  </div>

                  <div className="flex justify-between items-center mb-4 mt-5">
                    <div className="px-3 py-1 bg-gray-700 rounded-full flex items-center">
                      <span className="text-yellow-400 font-bold">Cuota:</span>
                      <span className="ml-2 text-white text-xl font-extrabold">
                        {odds}x
                      </span>
                    </div>
                  </div>

                  <button
                    onClick={() => handleHelp(coment)}
                    className="w-full flex items-center justify-center gap-2 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white py-3 px-4 rounded-xl transition duration-300 shadow-md font-medium"
                  >
                    <HelpCircle size={20} /> Solicitar Ayuda
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-8 text-center max-w-lg mx-auto">
            <div className="flex justify-center mb-4">
              <HelpCircle size={48} className="text-gray-400" />
            </div>
            <p className="text-xl text-gray-300 mb-2">
              No hay combinadas disponibles para hoy.
            </p>
            <p className="text-gray-400">
              Vuelve más tarde para ver las nuevas combinadas del día.
            </p>
          </div>
        )}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50 backdrop-blur-sm"
          onClick={() => setSelectedImage(null)}
        >
          <div className="relative max-w-4xl w-full mx-4">
            <button
              className="absolute -top-12 right-0 text-white text-xl bg-red-600 hover:bg-red-700 rounded-full w-10 h-10 flex items-center justify-center shadow-lg transition-all duration-300 transform hover:scale-110"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedImage(null);
              }}
            >
              &times;
            </button>
            <div className="p-1 bg-gradient-to-r from-blue-500 to-purple-600 rounded-xl">
              <img
                src={selectedImage}
                alt="Imagen Ampliada"
                className="max-w-full max-h-screen object-contain rounded-lg"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
