import React, { useState } from "react";
import {
  Bell,
  FileText,
  Users,
  Lock,
  Search,
  Plus,
  Calendar,
  Settings,
  LogOut,
  ChevronDown,
  Filter,
  Grid,
  List,
  MessageCircleMore,
  CirclePlus,
  ArrowRightToLine,
  CircleFadingArrowUp,
  Route,
  BookText,
  SquareKanban,
  PillBottle,
  SendToBack,
  BookCheck,
  BookOpenText,
  AlarmClock,
} from "lucide-react";
import Card from "./components/Card";
import Modal from "./utils/Modal";
import Sidebar from "./components/Sidebar";

// Card Components

<Card />;

const CardContent = ({ children, className = "" }) => (
  <div className={`p-6 ${className}`}>{children}</div>
);

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [viewMode, setViewMode] = useState("grid");
  const [showNotifications, setShowNotifications] = useState(false);

  const notifications = [
    { id: 1, text: "Nuevo documento compartido", time: "Hace 5 min" },
    { id: 2, text: "Actualización del sistema", time: "Hace 1 hora" },
    { id: 3, text: "Reunión de equipo", time: "Hace 2 horas" },
  ];

  const recentDocuments = [
    {
      id: 1,
      name: "Reporte Q1 2025.docx",
      type: "DOCX",
      size: "2.3 MB",
      date: "Hoy",
    },
    {
      id: 2,
      name: "Presupuesto 2025.xlsx",
      type: "XLS",
      size: "1.8 MB",
      date: "Ayer",
    },
    {
      id: 3,
      name: "Presentación.pptx",
      type: "PPT",
      size: "5.2 MB",
      date: "23 Feb",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función para abrir el modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-100">
      {/* Sidebar */}
      <div className="fixed left-0 top-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out"></div>
      <Sidebar />

      {/* Main Content */}
      <div className="ml-64 p-8">
        {/* Top Bar */}
        <div className="flex justify-between items-center mb-8 bg-white rounded-xl p-4 shadow-sm">
          <div className="relative w-96">
            <input
              type="text"
              placeholder="Buscar documentos..."
              className="w-full pl-10 pr-4 py-2 text-black rounded-lg border border-gray-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-100 transition-all duration-300"
            />
            <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
          </div>

          <div className="flex items-center gap-4">
            {/* View Toggle */}
            <div className="flex bg-gray-100 rounded-lg p-1">
              <button
                onClick={() => setViewMode("grid")}
                className={`p-2 rounded-lg transition-all duration-300 ${
                  viewMode === "grid"
                    ? "bg-white shadow-sm"
                    : "hover:bg-gray-200"
                }`}
              >
                <Grid className="w-5 h-5 text-gray-600" />
              </button>
              <button
                onClick={() => setViewMode("list")}
                className={`p-2 rounded-lg transition-all duration-300 ${
                  viewMode === "list"
                    ? "bg-white shadow-sm"
                    : "hover:bg-gray-200"
                }`}
              >
                <List className="w-5 h-5 text-gray-600" />
              </button>
            </div>

            {/* Notifications */}
            <div className="relative">
              <button
                onClick={() => setShowNotifications(!showNotifications)}
                className="p-2 rounded-lg hover:bg-gray-100 transition-all duration-300 relative"
              >
                <Bell className="w-5 h-5 text-gray-600" />
                <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
              </button>

              {showNotifications && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-xl shadow-lg p-4 transform transition-all duration-300 ease-out z-50">
                  <h3 className="text-lg font-semibold mb-4">Notificaciones</h3>
                  <div className="space-y-3">
                    {notifications.map((notif) => (
                      <div
                        key={notif.id}
                        className="flex items-start gap-3 p-2 hover:bg-gray-50 rounded-lg transition-colors duration-300"
                      >
                        <div className="w-2 h-2 mt-2 bg-blue-500 rounded-full"></div>
                        <div>
                          <p className="text-sm text-gray-700">{notif.text}</p>
                          <p className="text-xs text-gray-400">{notif.time}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div>
              <button className="p-2 rounded-lg hover:bg-gray-100 transition-all duration-300 relative">
                <MessageCircleMore className="w-5 h-5 text-gray-600" />
                <span className="absolute top-0 right-0 w-2 h-2 bg-green-500 rounded-full"></span>
              </button>
            </div>

            {/* Botón que abre el modal */}
            <button
              className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-all duration-300 shadow-sm hover:shadow-md"
              onClick={openModal}
            >
              <CirclePlus className="w-5 h-5" />
              <span>Nuevo</span>
            </button>

            {/* Modal */}
            <Modal isOpen={isModalOpen} closeModal={closeModal} />
          </div>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {[
            { label: "Documentos", value: "1,234", growth: "+12%" },
            { label: "Activos", value: "856", growth: "+5%" },
            { label: "Compartidos", value: "432", growth: "+8%" },
            { label: "Espacio Usado", value: "24.5G", growth: "75%" },
          ].map((stat, index) => (
            <Card
              key={index}
              className="hover:shadow-lg transition-all duration-300"
            >
              <CardContent>
                <h3 className="text-gray-500 text-sm mb-2">{stat.label}</h3>
                <div className="flex items-end gap-2">
                  <span className="text-2xl font-bold text-gray-800">
                    {stat.value}
                  </span>
                  <span className="text-sm text-green-500 mb-1">
                    {stat.growth}
                  </span>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>

        {/* Recent Documents */}
        <Card className="mb-8">
          <CardContent>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-800">
                Documentos Recientes
              </h2>
              <button className="flex items-center gap-2 text-gray-500 hover:text-gray-700 transition-colors duration-300">
                <Filter className="w-4 h-4" />
                <span>Filtrar</span>
              </button>
            </div>

            <div className="space-y-4">
              {recentDocuments.map((doc) => (
                <div
                  key={doc.id}
                  className="flex items-center gap-4 p-4 rounded-lg hover:bg-gray-50 transition-all duration-300 group"
                >
                  <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                    <FileText className="w-6 h-6 text-blue-500" />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-gray-800 font-medium">{doc.name}</h3>
                    <p className="text-sm text-gray-500">
                      {doc.type} • {doc.size} • Modificado {doc.date}
                    </p>
                  </div>
                  <button className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-2 hover:bg-blue-50 rounded-lg">
                    <ChevronDown className="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              ))}
            </div>
            <div className="flex items-center space-x-2 bg-green-800 text-white py-2 px-4 rounded hover:bg-green-700 cursor-pointer">
              <ArrowRightToLine className="w-4 h-4" />
              <span>Ver más documentos</span>
            </div>
          </CardContent>
        </Card>

        {/* Quick Actions */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {[
            {
              icon: Plus,
              label: "Nuevo",
              bgColor: "bg-blue-50 hover:bg-blue-100",
              iconColor: "text-blue-500",
            },
            {
              icon: Users,
              label: "Compartir",
              bgColor: "bg-green-50 hover:bg-green-100",
              iconColor: "text-green-500",
            },
            {
              icon: Lock,
              label: "Acceso Privado",
              bgColor: "bg-purple-50 hover:bg-purple-100",
              iconColor: "text-purple-500",
            },
            {
              icon: Calendar,
              label: "Programar",
              bgColor: "bg-orange-50 hover:bg-orange-100",
              iconColor: "text-orange-500",
            },
          ].map((action, index) => (
            <button
              key={index}
              className={`p-6 rounded-xl ${action.bgColor} transition-all duration-300 group`}
            >
              <action.icon
                className={`w-8 h-8 ${action.iconColor} mb-3 group-hover:scale-110 transition-transform duration-300`}
              />
              <h3 className="text-gray-800 font-medium">{action.label}</h3>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
