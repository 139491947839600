import React, { useEffect, useState } from "react";
import { auth, database } from "../Config/firebase"; // Ruta correcta si el archivo se llama firebase.js
import { ref, onValue } from "firebase/database"; // Usamos onValue para escuchar en tiempo real

export default function MessageVip() {
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentUser = auth.currentUser; // Obtiene el usuario autenticado

    if (currentUser) {
      const userRef = ref(database, "usuarios/" + currentUser.uid); // Crea una referencia al nodo 'usuarios'

      // Escuchamos los cambios en tiempo real usando onValue
      const unsubscribe = onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val(); // Obtiene los datos del usuario
          if (userData && userData.typeU === "Cuenta Vip") {
            setUserType("vip"); // Si es Cuenta Vip, no mostrar mensaje
          } else {
            setUserType("normal"); // Si no es Cuenta Vip, muestra el mensaje
          }
        }
        setLoading(false);
      });

      // Limpiar el listener cuando el componente se desmonte
      return () => unsubscribe();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (userType === "vip") {
    return null; // Si el usuario tiene tipo "Cuenta Vip", no se muestra el mensaje
  }

  return (
    <div className="mt-6 max-w-md mx-auto">
      {/* Premium Card */}
      <div className="rounded-lg bg-gradient-to-r from-indigo-600 to-indigo-700 p-6 shadow-lg">
        <h3 className="text-lg font-semibold text-white">¡Mejora a Premium!</h3>
        <p className="mt-2 text-sm text-indigo-200">
          Accede a pronósticos exclusivos y análisis detallados.
        </p>
        <a
          href="https://wa.me/573023580862?text=Hola, necesito ayuda para adquirir un plan VIP."
          className="mt-4 block w-full rounded-lg bg-white px-4 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-50 transition-colors text-center"
        >
          Actualizar Ahora
        </a>
      </div>
    </div>
  );
}
