import React, { useState, useEffect, useRef } from "react";
import { getDatabase, ref, push, onChildAdded, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Bot, Loader2, User } from "lucide-react";
import FootballLoading from "../../Utils/FootballLoading";
import useUserData from "../../Hooks/useUserData";

  

const initialMenuOptions = [
  { id: 1, text: "Pronósticos deportivos" },
  { id: 2, text: "Ayuda con recargas" },
  { id: 3, text: "Soporte técnico" },
  { id: 4, text: "Estado de cuenta" },
];

// Sub-menu options for each main menu
const subMenuOptions = {
  1: [
    { id: "1-1", text: "¿Dónde ver los Pronósticos?" },
    { id: "1-2", text: "Hay errores en los Pronósticos" },
    { id: "1-3", text: "Quiero ayuda para mi combinada o apuesta" },
  ],
  2: [
    { id: "2-1", text: "Hice una recarga y aún no me llega" },
    { id: "2-2", text: "No entiendo las recargas" },
  ],
  3: [
    { id: "3-1", text: "Solicitar llamada" },
    { id: "3-2", text: "Solicitar Soporte por chat" },
    { id: "3-3", text: "Solicitar mensaje WhatsApp" },
  ],
};

export default function SupportChat() {
  const { userData, loading, error } = useUserData();

  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("Usuario");
  const [userType, setUserType] = useState("");
  const [currentMenu, setCurrentMenu] = useState("main");
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabledMenus, setDisabledMenus] = useState(new Set());
  const messageContainerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await loadUserData(currentUser.uid);
        await loadMessages(currentUser.uid);

        // Only send welcome message if no messages exist
        const db = getDatabase();
        const chatRef = ref(db, `chat-ia/${currentUser.uid}`);
        const snapshot = await get(chatRef);
        if (!snapshot.exists()) {
          sendBotMessage(
            currentUser.uid,
            "¡Bienvenido! Selecciona una opción:",
            true
          );
        }
      } else {
        window.location.href = "/login";
      }
    });
    return () => unsubscribe();
  }, []);

  const loadUserData = async (userId) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `usuarios/${userId}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserName(userData.name || "Usuario");
        setUserType(userData.typeU || "Cuenta Gartis");
      }
    } catch (error) {
      console.error("Error loading user data:", error);
    }
  };

  const loadMessages = (userId) => {
    const db = getDatabase();
    const chatRef = ref(db, `chat-ia/${userId}`);
    onChildAdded(chatRef, (snapshot) => {
      const message = snapshot.val();
      setMessages((prevMessages) => [...prevMessages, message]);

      // If this is a menu message, update the disabled menus set
      if (message.isMenu) {
        setDisabledMenus(new Set());
        setCurrentMenu(message.menuType || "main");
      }
      // If this is a menu selection, disable that menu
      if (message.selectedMenuId) {
        setDisabledMenus((prev) => new Set([...prev, message.selectedMenuId]));
      }
    });
  };

  const sendBotMessage = async (
    userId,
    text,
    isMenu = false,
    selectedMenuId = null,
    menuType = "main"
  ) => {
    if (!userId) return;
    const db = getDatabase();
    const chatRef = ref(db, `chat-ia/${userId}`);
    await push(chatRef, {
      text,
      sender: "bot",
      timestamp: Date.now(),
      userName: "Bot",
      isMenu,
      selectedMenuId,
      menuType,
    });
  };

  const sendUserMessage = async (text) => {
    if (!user || !text.trim()) return;
    const db = getDatabase();
    const chatRef = ref(db, `chat-ia/${user.uid}`);
    await push(chatRef, {
      text,
      sender: user.uid,
      timestamp: Date.now(),
      userName: userName,
    });
  };

  const handleMenuOption = async (option) => {
    if (!user || disabledMenus.has(option.id)) return;

    await sendUserMessage(`${option.text}`);

    // Handle main menu options
    if (typeof option.id === "number") {
      switch (option.id) {
        case 1: // Pronósticos deportivos
          await sendBotMessage(
            user.uid,
            "¿En qué puedo ayudarte con los pronósticos deportivos?",
            true,
            option.id,
            "submenu-1"
          );
          break;
        case 2: // Ayuda con recargas
          await sendBotMessage(
            user.uid,
            "¿Qué necesitas saber sobre recargas?",
            true,
            option.id,
            "submenu-2"
          );
          break;
        case 3: // Soporte técnico
          await sendBotMessage(
            user.uid,
            "Selecciona una opción de soporte técnico:",
            true,
            option.id,
            "submenu-3"
          );
          break;
        case 4: // Estado de cuenta
          await sendBotMessage(
            user.uid,
            `Información de la cuenta:\nUsuario: ${userName}\nTipo de cuenta: ${userType}`,
            false,
            option.id
          );
          // After showing account info, display main menu again
          setTimeout(() => {
            sendBotMessage(user.uid, "¿Necesitas ayuda con algo más?", true);
          }, 1000);
          break;
        default:
          break;
      }
    } else {
      // Handle sub-menu options
      if (option.id.startsWith("1-")) {
        switch (option.id) {
          case "1-1": // Dónde ver los Pronósticos
            await sendBotMessage(
              user.uid,
              "Entra a la app, ve a la parte VIP, y cuando estés ahí vas a ver el inicio que es donde están los pronósticos VIP.",
              false,
              option.id
            );
            // Return to main menu
            setTimeout(() => {
              sendBotMessage(user.uid, "¿Puedo ayudarte con algo más?", true);
            }, 1000);
            break;
          case "1-2": // Errores en los Pronósticos
            await sendBotMessage(
              user.uid,
              "Por favor, descríbenos el error que has encontrado en los pronósticos:",
              false,
              option.id
            );
            setCurrentMenu("input-error");
            break;
          case "1-3": // Ayuda para combinada o apuesta
            await sendBotMessage(
              user.uid,
              "Cuéntanos en qué necesitas ayuda con tu combinada o apuesta:",
              false,
              option.id
            );
            setCurrentMenu("input-bet-help");
            break;
          default:
            break;
        }
      } else if (option.id.startsWith("2-")) {
        switch (option.id) {
          case "2-1": // Recarga no llega
            await sendBotMessage(
              user.uid,
              "Estamos revisando tu recarga. En breve un agente de soporte se pondrá en contacto contigo para resolver esta situación.",
              false,
              option.id
            );
            // Return to main menu
            setTimeout(() => {
              sendBotMessage(user.uid, "¿Puedo ayudarte con algo más?", true);
            }, 1000);
            break;
          case "2-2": // No entiende recargas
            await sendBotMessage(
              user.uid,
              "Estamos aquí para ayudarte con tus dudas sobre recargas. Un agente de soporte se pondrá en contacto contigo para explicarte todo el proceso.",
              false,
              option.id
            );
            // Return to main menu
            setTimeout(() => {
              sendBotMessage(user.uid, "¿Puedo ayudarte con algo más?", true);
            }, 1000);
            break;
          default:
            break;
        }
      } else if (option.id.startsWith("3-")) {
        switch (option.id) {
          case "3-1": // Solicitar llamada
            await sendBotMessage(
              user.uid,
              "Por favor, ingresa tu número telefónico para que un asesor pueda llamarte:",
              false,
              option.id
            );
            setCurrentMenu("input-phone");
            break;
          case "3-2": // Soporte por chat
            await sendBotMessage(
              user.uid,
              "En un momento un agente de soporte se comunicará contigo a través del chat. Gracias por tu paciencia.",
              false,
              option.id
            );
            // Return to main menu
            setTimeout(() => {
              sendBotMessage(user.uid, "¿Puedo ayudarte con algo más?", true);
            }, 1000);
            break;
          case "3-3": // Mensaje WhatsApp
            await sendBotMessage(
              user.uid,
              "Por favor, ingresa tu número de WhatsApp para que un asesor pueda contactarte:",
              false,
              option.id
            );
            setCurrentMenu("input-whatsapp");
            break;
          default:
            break;
        }
      }
    }
  };

  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    await sendUserMessage(inputValue);

    switch (currentMenu) {
      case "input-error":
        await sendBotMessage(
          user.uid,
          "Gracias por reportar el error. Vamos a solucionarlo lo antes posible.",
          false
        );
        setInputValue("");
        // Return to main menu
        setTimeout(() => {
          sendBotMessage(user.uid, "¿Puedo ayudarte con algo más?", true);
        }, 1000);
        break;
      case "input-bet-help":
        await sendBotMessage(
          user.uid,
          "Gracias por tu consulta. En un momento un asesor especializado se pondrá en contacto contigo.",
          false
        );
        setInputValue("");
        // Return to main menu
        setTimeout(() => {
          sendBotMessage(user.uid, "¿Puedo ayudarte con algo más?", true);
        }, 1000);
        break;
      case "input-phone":
      case "input-whatsapp":
        await sendBotMessage(
          user.uid,
          `Gracias. Un asesor se pondrá en contacto contigo en los próximos minutos al número proporcionado.`,
          false
        );
        setInputValue("");
        // Return to main menu
        setTimeout(() => {
          sendBotMessage(user.uid, "¿Puedo ayudarte con algo más?", true);
        }, 1000);
        break;
      default:
        setInputValue("");
        break;
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollHeight, clientHeight } = messageContainerRef.current;
      messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }

    // Focus input field when menu changes to an input mode
    if (currentMenu.startsWith("input-") && inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages, currentMenu]);

  if (!user) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  // Determine which menu options to display
  const getMenuOptions = () => {
    if (currentMenu === "main") return initialMenuOptions;
    if (currentMenu.startsWith("submenu-")) {
      const menuId = parseInt(currentMenu.split("-")[1]);
      return subMenuOptions[menuId] || [];
    }
    return [];
  };


  // Check if we should show the input field
  const showInputField = currentMenu.startsWith("input-");

  return (
    <div className="flex h-screen flex-col bg-gray-900 -mt-5">
      <div className="fixed top-0 z-10 w-full bg-gray-800 p-4 shadow-md">
        <div className="flex items-center gap-2">
          <Bot className="text-blue-500" />
          <h2 className="text-lg font-semibold text-white">
            Asistente Virtual
          </h2>
        </div>
      </div>

      <div
        ref={messageContainerRef}
        className="flex-1 overflow-y-auto p-4 pt-16 pb-32"
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-4 flex ${
              message.sender === user.uid ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`max-w-[80%] rounded-lg p-3 ${
                message.sender === user.uid
                  ? "bg-blue-500 text-white"
                  : "bg-gray-700 text-white"
              }`}
            >
              <div className="mb-1 flex items-center gap-2">
                {message.sender === "bot" ? (
                  <Bot size={16} />
                ) : (
                  <User size={16} />
                )}
                <span className="text-sm font-semibold">
                  {message.userName}
                </span>
              </div>
              <div className="whitespace-pre-wrap break-words">
                {message.text}
              </div>
              {message.isMenu && (
                <div className="mt-2 flex flex-wrap gap-2">
                  {getMenuOptions().map((option) => (
                    <button
                      key={option.id}
                      onClick={() => handleMenuOption(option)}
                      disabled={disabledMenus.has(option.id)}
                      className={`rounded-lg px-4 py-2 text-white ${
                        disabledMenus.has(option.id)
                          ? "bg-gray-500 cursor-not-allowed"
                          : "bg-blue-500 hover:bg-blue-600"
                      }`}
                    >
                      {option.text}
                    </button>
                  ))}
                </div>
              )}
              <div className="mt-1 text-right text-xs opacity-75">
                {new Date(message.timestamp).toLocaleTimeString()}
              </div>
            </div>
          </div>
        ))}
      </div>

      {showInputField && (
        <div className="fixed bottom-0 w-full bg-gray-800 p-4 shadow-lg">
          <form onSubmit={handleInputSubmit} className="flex gap-2">
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={
                currentMenu === "input-phone" ||
                currentMenu === "input-whatsapp"
                  ? "Ingresa tu número"
                  : currentMenu === "input-error"
                  ? "Describe el error encontrado"
                  : "Escribe tu mensaje..."
              }
              className="flex-1 rounded-lg mb-16 bg-gray-700 px-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="rounded-lg bg-blue-500 mb-16 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Enviar
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
