import { useState } from "react";
import {
  Settings,
  CircleFadingPlus,
  Route,
  BookText,
  SquareKanban,
  PillBottleIcon as PillBottle,
  SendToBack,
  BookCheck,
  BookOpenText,
  AlarmClock,
  Users,
  LogOut,
  ChevronDown,
  ChevronUp,
} from "lucide-react";

const managementItems = [
  { icon: BookText, label: "Documentada" },
  { icon: SquareKanban, label: "Auditorias" },
  { icon: PillBottle, label: "Provedores" },
  { icon: SendToBack, label: "Conformidad" },
  { icon: BookCheck, label: "Control de Legislación" },
  { icon: BookOpenText, label: "Actas" },
  { icon: Users, label: "Humana" },
];

const mainItems = [
  { icon: Settings, label: "Configuración del Sistema" },
  { icon: CircleFadingPlus, label: "Mejoramiento Continuo" },
  { icon: Route, label: "Seguimiento y Medición" },
];

const Sidebar = () => {
  const [isManagementOpen, setIsManagementOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <aside
      className="fixed left-0 top-0 h-screen w-72 flex flex-col bg-gradient-to-br from-blue-800 via-blue-700 to-blue-900 text-white shadow-2xl"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-400 scrollbar-track-transparent">
        <div className="p-4">
          <div className="flex items-center gap-3 mb-8">
            <div className="w-8 h-8 rounded-lg bg-white/20 flex items-center justify-center">
              <BookText className="w-5 h-5 text-white" />
            </div>
            <h1 className="text-2xl font-bold text-white tracking-tight select-none">
              DocManager
            </h1>
          </div>

          <nav className="space-y-1">
            {mainItems.map((item, index) => (
              <button
                key={index}
                onClick={() => setActiveItem(item.label)}
                className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-all duration-300 ease-out
                  ${
                    activeItem === item.label
                      ? "bg-white/20 shadow-lg"
                      : "hover:bg-white/10 active:bg-white/15"
                  }`}
              >
                <item.icon
                  className={`w-5 h-5 transition-all duration-300
                  ${
                    activeItem === item.label
                      ? "text-white scale-110"
                      : "text-blue-100 group-hover:text-white"
                  }`}
                />
                <span
                  className={`text-sm font-medium transition-all duration-300
                  ${
                    activeItem === item.label
                      ? "text-white"
                      : "text-blue-50 group-hover:text-white"
                  }`}
                >
                  {item.label}
                </span>
              </button>
            ))}

            <div className="pt-4">
              <button
                onClick={() => setIsManagementOpen(!isManagementOpen)}
                className={`w-full flex items-center justify-between px-4 py-3 rounded-xl transition-all duration-300
                  ${
                    isManagementOpen
                      ? "bg-white/20 shadow-lg"
                      : "hover:bg-white/10 active:bg-white/15"
                  }`}
              >
                <div className="flex items-center gap-3">
                  <BookText
                    className={`w-5 h-5 transition-all duration-300
                    ${
                      isManagementOpen
                        ? "text-white scale-110"
                        : "text-blue-100"
                    }`}
                  />
                  <span
                    className={`text-sm font-medium transition-all duration-300
                    ${isManagementOpen ? "text-white" : "text-blue-50"}`}
                  >
                    Gestión
                  </span>
                </div>
                {isManagementOpen ? (
                  <ChevronUp className="w-4 h-4 text-white transition-transform duration-300" />
                ) : (
                  <ChevronDown className="w-4 h-4 text-blue-100 transition-transform duration-300" />
                )}
              </button>

              <div
                className={`mt-1 space-y-1 overflow-hidden transition-all duration-500 ease-in-out
                ${
                  isManagementOpen
                    ? "max-h-96 opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                {managementItems.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => setActiveItem(item.label)}
                    className={`w-full flex items-center gap-3 px-4 py-3 pl-12 rounded-xl transition-all duration-300
                      ${
                        activeItem === item.label
                          ? "bg-white/20 shadow-lg"
                          : "hover:bg-white/10 active:bg-white/15"
                      }`}
                  >
                    <item.icon
                      className={`w-4 h-4 transition-all duration-300
                      ${
                        activeItem === item.label
                          ? "text-white scale-110"
                          : "text-blue-100"
                      }`}
                    />
                    <span
                      className={`text-sm font-medium transition-all duration-300
                      ${
                        activeItem === item.label
                          ? "text-white"
                          : "text-blue-50"
                      }`}
                    >
                      {item.label}
                    </span>
                  </button>
                ))}
              </div>
            </div>

            <button
              onClick={() => setActiveItem("tasks")}
              className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl mt-4 transition-all duration-300
                ${
                  activeItem === "tasks"
                    ? "bg-white/20 shadow-lg"
                    : "hover:bg-white/10 active:bg-white/15"
                }`}
            >
              <AlarmClock
                className={`w-5 h-5 transition-all duration-300
                ${
                  activeItem === "tasks"
                    ? "text-white scale-110"
                    : "text-blue-100"
                }`}
              />
              <span
                className={`text-sm font-medium transition-all duration-300
                ${activeItem === "tasks" ? "text-white" : "text-blue-50"}`}
              >
                Tareas Pendientes
              </span>
            </button>
          </nav>
        </div>
      </div>

      <div className="p-4 border-t border-white/10 bg-gradient-to-b from-transparent to-blue-900/50">
        <button className="w-full flex items-center justify-center gap-2 px-4 py-3 rounded-xl bg-red-500/10 hover:bg-red-500/20 active:bg-red-500/30 text-red-50 transition-all duration-300 group">
          <LogOut className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
          <span className="text-sm font-medium">Cerrar Sesión</span>
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
