import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { database } from "../../Config/firebase";
import {
  ref,
  onValue,
  push,
  set,
  update,
  serverTimestamp,
} from "firebase/database";
import {
  ChevronLeft,
  Send,
  Mic,
  Smile,
  Play,
  Pause,
  StopCircle,
} from "lucide-react";

export default function ChatConversation() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [otherUserTyping, setOtherUserTyping] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState({});
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const scrollAreaRef = useRef(null);
  const currentUserUUID = localStorage.getItem("userUUID");
  const chatId = [currentUserUUID, userId].sort().join("_");

  // Mantener el scroll en el fondo cuando se añaden mensajes
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, otherUserTyping]);

  // Prevenir scroll automático del body cuando se está en el chat
  useEffect(() => {
    const handleTouchMove = (e) => {
      if (scrollAreaRef.current && !scrollAreaRef.current.contains(e.target)) {
        e.preventDefault();
      }
    };

    document.body.style.overflow = "hidden";
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  useEffect(() => {
    if (!currentUserUUID) {
      navigate("/login");
      return;
    }

    // Obtener información del usuario con el que se está chateando
    const userRef = ref(database, `usuarios/${userId}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData(data);
      }
    });

    // Escuchar mensajes en tiempo real
    const messagesRef = ref(database, `chats/${chatId}/messages`);
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messagesList = [];

      if (data) {
        // Convertir objeto a array
        Object.entries(data).forEach(([id, messageData]) => {
          messagesList.push({
            id,
            ...messageData,
          });
        });

        // Ordenar por timestamp
        messagesList.sort((a, b) => {
          const timestampA = a.timestamp || 0;
          const timestampB = b.timestamp || 0;
          return timestampA - timestampB;
        });

        // Marcar como leídos los mensajes recibidos
        messagesList.forEach((message) => {
          if (message.senderId !== currentUserUUID && !message.read) {
            const messageRef = ref(
              database,
              `chats/${chatId}/messages/${message.id}`
            );
            update(messageRef, { read: true });
          }
        });

        // Actualizar lastMessage como leído si es del otro usuario
        const lastMessage = messagesList[messagesList.length - 1];
        if (lastMessage && lastMessage.senderId !== currentUserUUID) {
          const chatRef = ref(database, `chats/${chatId}`);
          update(chatRef, {
            "lastMessage/read": true,
          });
        }
      }

      setMessages(messagesList);
      setLoading(false);
    });

    // Escuchar estado de escritura del otro usuario
    const typingRef = ref(database, `chats/${chatId}/typing/${userId}`);
    onValue(typingRef, (snapshot) => {
      const isOtherUserTyping = snapshot.val();
      setOtherUserTyping(isOtherUserTyping === true);
    });

    // Limpiar typing status al desmontar
    return () => {
      const myTypingRef = ref(
        database,
        `chats/${chatId}/typing/${currentUserUUID}`
      );
      set(myTypingRef, false);

      // Limpiar grabación si existe
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
        clearInterval(timerRef.current);
      }

      // Detener reproducción de audio
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
    };
  }, [userId, currentUserUUID, navigate, chatId, isRecording, currentAudio]);

  const handleTyping = () => {
    // Actualizar estado de escritura
    if (!isTyping) {
      setIsTyping(true);
      const typingRef = ref(
        database,
        `chats/${chatId}/typing/${currentUserUUID}`
      );
      set(typingRef, true);
    }

    // Reiniciar el timeout
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
      const typingRef = ref(
        database,
        `chats/${chatId}/typing/${currentUserUUID}`
      );
      set(typingRef, false);
    }, 2000);
  };

  const sendMessage = (e) => {
    e?.preventDefault();

    if ((!newMessage.trim() && !audioURL) || loading) return;

    // Referencia a la colección de mensajes
    const messagesRef = ref(database, `chats/${chatId}/messages`);

    // Crear nuevo mensaje
    const newMessageRef = push(messagesRef);
    const messageData = {
      senderId: currentUserUUID,
      receiverId: userId,
      timestamp: Date.now(),
      read: false,
    };

    // Determinar si es mensaje de texto o audio
    if (audioURL) {
      messageData.type = "audio";
      messageData.audioURL = audioURL;
      messageData.duration = recordingTime;
    } else {
      messageData.type = "text";
      messageData.text = newMessage;
    }

    set(newMessageRef, messageData);

    // Actualizar datos de la conversación
    const chatRef = ref(database, `chats/${chatId}`);
    const lastMessageText = audioURL ? "🎤 Mensaje de voz" : newMessage;

    update(chatRef, {
      participants: {
        [currentUserUUID]: true,
        [userId]: true,
      },
      lastMessage: {
        text: lastMessageText,
        senderId: currentUserUUID,
        timestamp: messageData.timestamp,
        read: false,
      },
    });

    // Detener estado de escritura
    const typingRef = ref(
      database,
      `chats/${chatId}/typing/${currentUserUUID}`
    );
    set(typingRef, false);
    setIsTyping(false);
    clearTimeout(typingTimeoutRef.current);

    // Limpiar input y estado de audio
    setNewMessage("");
    setAudioURL(null);
    setRecordingTime(0);
    inputRef.current?.focus();
  };

  // Funciones para grabar audio
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (e) => {
        audioChunksRef.current.push(e.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/mp3",
        });
        const url = URL.createObjectURL(audioBlob);
        setAudioURL(url);

        // Liberar las pistas de audio
        stream.getTracks().forEach((track) => track.stop());
      };

      // Iniciar grabación
      mediaRecorderRef.current.start();
      setIsRecording(true);

      // Iniciar temporizador
      let seconds = 0;
      timerRef.current = setInterval(() => {
        seconds++;
        setRecordingTime(seconds);
      }, 1000);
    } catch (err) {
      console.error("Error al acceder al micrófono", err);
      alert("No se pudo acceder al micrófono");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      clearInterval(timerRef.current);
      setIsRecording(false);
    }
  };

  const cancelRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      clearInterval(timerRef.current);
      setIsRecording(false);
      setAudioURL(null);
      setRecordingTime(0);
    }
  };

  const handleAudioPlay = (audioUrl, messageId) => {
    // Si hay un audio reproduciéndose, pararlo
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    // Si es el mismo audio que estaba reproduciéndose, solo lo detenemos
    if (isPlaying[messageId]) {
      setIsPlaying({ ...isPlaying, [messageId]: false });
      setCurrentAudio(null);
      return;
    }

    // Reproducir nuevo audio
    const audio = new Audio(audioUrl);

    audio.onended = () => {
      setIsPlaying({ ...isPlaying, [messageId]: false });
      setCurrentAudio(null);
    };

    audio.play();
    setCurrentAudio(audio);
    setIsPlaying({ ...isPlaying, [messageId]: true });
  };

  // Formatear tiempo
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // Agrupar mensajes por fecha
  const groupMessagesByDate = () => {
    const groups = {};

    messages.forEach((message) => {
      const date = new Date(message.timestamp);
      const dateStr = date.toLocaleDateString();

      if (!groups[dateStr]) {
        groups[dateStr] = [];
      }

      groups[dateStr].push(message);
    });

    return groups;
  };

  // Formatear fecha para encabezados
  const formatDateHeader = (dateStr) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    try {
      const [month, day, year] = dateStr.split("/");
      const messageDate = new Date(+year, +month - 1, +day);

      if (messageDate.toDateString() === today.toDateString()) {
        return "Hoy";
      } else if (messageDate.toDateString() === yesterday.toDateString()) {
        return "Ayer";
      } else {
        return messageDate.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      }
    } catch (error) {
      // Fallback si hay algún error con el formato de fecha
      return dateStr;
    }
  };

  const messageGroups = groupMessagesByDate();

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      {/* Header */}
      <div className="bg-black border-b border-gray-800 p-3 flex items-center sticky top-0 z-10">
        <button
          onClick={() => navigate("/inbox")}
          className="mr-3 text-xl p-2 rounded-full hover:bg-gray-900"
          aria-label="Volver"
        >
          <ChevronLeft size={24} />
        </button>

        <div className="relative">
          <div className="w-10 h-10 rounded-full overflow-hidden flex items-center justify-center mr-3">
            {userData?.profileImage ? (
              <img
                src={userData.profileImage}
                alt={userData.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/150?text=?";
                }}
              />
            ) : (
              <div className="w-full h-full bg-gray-700 flex items-center justify-center text-white font-bold">
                {userData?.name ? userData.name.charAt(0).toUpperCase() : "?"}
              </div>
            )}
          </div>
          {userData?.online && (
            <div className="absolute bottom-0 right-2 w-3 h-3 bg-green-500 rounded-full border-2 border-black"></div>
          )}
        </div>

        <div className="flex-1">
          <h1 className="font-bold">{userData?.name || "Usuario"}</h1>
          {otherUserTyping ? (
            <p className="text-xs text-pink-500">Escribiendo...</p>
          ) : userData?.online ? (
            <p className="text-xs text-green-500">En línea</p>
          ) : userData?.lastSeen ? (
            <p className="text-xs text-gray-400">
              Última vez{" "}
              {new Date(userData.lastSeen).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          ) : null}
        </div>
      </div>

      {/* Mensajes */}
      <div className="flex-1 overflow-y-auto p-4 bg-black" ref={scrollAreaRef}>
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-pulse flex space-x-2">
              <div className="w-3 h-3 bg-pink-500 rounded-full"></div>
              <div className="w-3 h-3 bg-pink-500 rounded-full"></div>
              <div className="w-3 h-3 bg-pink-500 rounded-full"></div>
            </div>
          </div>
        ) : messages.length === 0 ? (
          <div className="flex flex-col justify-center items-center h-full">
            <div className="w-20 h-20 bg-gray-800 rounded-full flex items-center justify-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                />
              </svg>
            </div>
            <p className="text-gray-500">No hay mensajes. ¡Di hola!</p>
          </div>
        ) : (
          Object.entries(messageGroups).map(([dateStr, messagesForDate]) => (
            <div key={dateStr}>
              <div className="flex justify-center my-4">
                <span className="text-xs bg-gray-800 text-gray-400 px-3 py-1 rounded-full">
                  {formatDateHeader(dateStr)}
                </span>
              </div>

              {messagesForDate.map((message) => {
                const isCurrentUser = message.senderId === currentUserUUID;
                const isAudio = message.type === "audio";

                return (
                  <div
                    key={message.id}
                    className={`max-w-xs mx-2 my-1 ${
                      isCurrentUser ? "ml-auto" : "mr-auto"
                    }`}
                  >
                    {isAudio ? (
                      <div
                        className={`p-3 rounded-2xl ${
                          isCurrentUser
                            ? "bg-pink-500 text-white rounded-br-sm"
                            : "bg-gray-800 text-white rounded-bl-sm"
                        }`}
                      >
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() =>
                              handleAudioPlay(message.audioURL, message.id)
                            }
                            className="w-8 h-8 flex items-center justify-center rounded-full bg-black bg-opacity-20"
                          >
                            {isPlaying[message.id] ? (
                              <Pause size={16} className="text-white" />
                            ) : (
                              <Play size={16} className="text-white" />
                            )}
                          </button>

                          <div className="flex-1">
                            <div className="w-full h-1 bg-black bg-opacity-20 rounded-full">
                              <div
                                className="h-1 bg-white rounded-full"
                                style={{
                                  width: isPlaying[message.id] ? "100%" : "0%",
                                  transition: isPlaying[message.id]
                                    ? `width ${message.duration}s linear`
                                    : "none",
                                }}
                              ></div>
                            </div>
                          </div>

                          <span className="text-xs">
                            {formatTime(message.duration || 0)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`p-3 rounded-2xl ${
                          isCurrentUser
                            ? "bg-pink-500 text-white rounded-br-sm"
                            : "bg-gray-800 text-white rounded-bl-sm"
                        }`}
                      >
                        <p className="break-words">{message.text}</p>
                      </div>
                    )}

                    <div
                      className={`flex items-center mt-1 text-xs text-gray-500 ${
                        isCurrentUser ? "justify-end" : "justify-start"
                      }`}
                    >
                      <span>
                        {new Date(message.timestamp).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>

                      {isCurrentUser && (
                        <span className="ml-1">
                          {message.read ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3 text-blue-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3 text-gray-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ))
        )}
        <div ref={messagesEndRef} />

        {otherUserTyping && (
          <div className="max-w-xs mx-2 my-3 mr-auto">
            <div className="p-3 bg-gray-800 rounded-lg rounded-bl-sm inline-block">
              <div className="flex space-x-1">
                <div
                  className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                  style={{ animationDelay: "0ms" }}
                ></div>
                <div
                  className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                  style={{ animationDelay: "300ms" }}
                ></div>
                <div
                  className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                  style={{ animationDelay: "600ms" }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Grabación de audio en progreso */}
      {isRecording && (
        <div className="p-3 bg-gray-900 border-t border-gray-800 flex items-center">
          <div className="flex-1 bg-gray-800 rounded-full px-4 py-2 mx-2 flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-3 h-3 bg-red-500 rounded-full animate-pulse mr-3"></div>
              <span>{formatTime(recordingTime)}</span>
            </div>
          </div>

          <button
            type="button"
            onClick={stopRecording}
            className="p-2 bg-green-500 text-white rounded-full mr-2"
            aria-label="Finalizar"
          >
            <Send size={20} />
          </button>

          <button
            type="button"
            onClick={cancelRecording}
            className="p-2 bg-red-500 text-white rounded-full"
            aria-label="Cancelar"
          >
            <StopCircle size={20} />
          </button>
        </div>
      )}

      {/* Audio grabado (preview) */}
      {audioURL && !isRecording && (
        <div className="p-3 bg-gray-900 border-t border-gray-800 flex items-center">
          <div className="flex-1 bg-gray-800 rounded-full px-4 py-2 mx-2 flex items-center">
            <button
              onClick={() => handleAudioPlay(audioURL, "preview")}
              className="w-8 h-8 flex items-center justify-center rounded-full bg-pink-500 mr-3"
            >
              {isPlaying["preview"] ? (
                <Pause size={16} className="text-white" />
              ) : (
                <Play size={16} className="text-white" />
              )}
            </button>

            <div className="flex-1">
              <div className="w-full h-1 bg-gray-700 rounded-full">
                <div
                  className="h-1 bg-pink-500 rounded-full"
                  style={{
                    width: isPlaying["preview"] ? "100%" : "0%",
                    transition: isPlaying["preview"]
                      ? `width ${recordingTime}s linear`
                      : "none",
                  }}
                ></div>
              </div>
            </div>

            <span className="text-xs ml-3">{formatTime(recordingTime)}</span>
          </div>

          <button
            onClick={() => sendMessage()}
            className="p-2 bg-pink-500 text-white rounded-full"
            aria-label="Enviar audio"
          >
            <Send size={20} />
          </button>
        </div>
      )}

      {/* Input para enviar mensaje */}
      {!isRecording && !audioURL && (
        <form
          onSubmit={sendMessage}
          className="p-3 bg-gray-900 border-t border-gray-800 flex items-center"
        >
          <div className="flex-1 bg-gray-800 rounded-full px-4 py-2 mx-2 flex items-center">
            <input
              ref={inputRef}
              type="text"
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
                handleTyping();
              }}
              placeholder="Mensaje..."
              className="flex-1 bg-transparent text-white focus:outline-none"
            />

            <button
              type="button"
              className="p-1 text-gray-400 hover:text-pink-500"
              aria-label="Emoji"
            >
              <Smile size={20} />
            </button>
          </div>

          {newMessage.trim() ? (
            <button
              type="submit"
              className="p-2 bg-pink-500 text-white rounded-full"
              aria-label="Enviar"
            >
              <Send size={20} />
            </button>
          ) : (
            <button
              type="button"
              onClick={startRecording}
              className="p-2 text-gray-400 hover:text-pink-500 rounded-full"
              aria-label="Micrófono"
            >
              <Mic size={20} />
            </button>
          )}
        </form>
      )}
    </div>
  );
}
