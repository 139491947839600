import React, { useState, useEffect } from "react";
import {
  User,
  Mail,
  Phone,
  MapPin,
  Briefcase,
  Calendar,
  Edit2,
  LogOut,
  MessageCircle,
  X,
  Gift,
} from "lucide-react";
import useUserData from "../../Hooks/useUserData";
import FootballLoading from "../../Utils/FootballLoading";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Config/firebase";
import { signOut } from "firebase/auth";
import ReferralCodeRedemption from "./ReferralCodeRedemption"; // Import the new component

const ProfileSection = ({ icon: Icon, title, value, editable, onEdit }) => {
  return (
    <div className="flex items-center space-x-4 p-4 bg-gray-800 rounded-lg transition-all duration-300 hover:shadow-lg hover:transform hover:scale-102">
      <Icon className="w-6 h-6 text-red-400" />
      <div className="flex-grow">
        <h3 className="text-sm font-medium text-gray-400">{title}</h3>
        <p className="text-lg text-white">{value}</p>
      </div>
      {editable && (
        <button
          onClick={onEdit}
          className="p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors duration-300"
        >
          <Edit2 className="w-4 h-4 text-white" />
        </button>
      )}
    </div>
  );
};

const SupportBanner = ({ onClose }) => {
  // Mensaje predefinido para WhatsApp
  const message = encodeURIComponent("Hola, necesito ayuda con mi cuenta.");
  const whatsappLink = `https://wa.me/573023580862?text=${message}`;

  return (
    <div className="relative mb-6 bg-gradient-to-r from-red-600 to-orange-500 rounded-lg p-4 shadow-lg animate-fadeIn overflow-hidden">
      <div className="absolute -bottom-8 -right-8 w-32 h-32 bg-white opacity-10 rounded-full"></div>
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-white hover:text-gray-200"
      >
        <X className="w-5 h-5" />
      </button>

      <div className="flex flex-col md:flex-row items-center md:items-start space-y-3 md:space-y-0 md:space-x-4">
        <div className="relative">
          <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
              alt="Support"
              className="w-12 h-12 rounded-full"
            />
          </div>
          <div className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white"></div>
        </div>

        <div className="flex-1 text-center md:text-left">
          <h3 className="text-lg font-bold text-white">¿Necesitas ayuda?</h3>
          <p className="text-white text-opacity-90 mb-2">
            Tu agente encargado de soporte:{" "}
            <span className="font-semibold">Juan</span>
          </p>
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center space-x-2 bg-white text-red-600 font-semibold py-2 px-4 rounded-full hover:bg-gray-100 transition-colors duration-300"
          >
            <MessageCircle className="w-5 h-5" />
            <span>Contactar vía WhatsApp</span>
          </a>
        </div>
      </div>
    </div>
  );
};

const UserProfile = () => {
  const { userData, loading, error, refreshUserData } = useUserData(); // Assuming refreshUserData is available or needs to be added
  const [showSupportBanner, setShowSupportBanner] = useState(true);
  const navigate = useNavigate();

  // Handle code redemption
  const handleCodeRedeemed = (updatedUserData) => {
    console.log("Código redimido, actualizando datos...");
    refreshUserData(); // Asegúrate de que esta función existe
  };

  // Función para cerrar sesión
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Limpiar localStorage
      localStorage.removeItem("userUUID");
      // Redirigir al login
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
        <FootballLoading />
        <p className="mt-4 text-white text-opacity-80 animate-pulse">
          Cargando tu perfil...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4">
        <div className="text-center bg-red-900/30 p-6 rounded-lg border border-red-800 max-w-md">
          <div className="text-red-500 text-6xl mb-4">!</div>
          <h2 className="text-xl font-bold text-white mb-2">
            Error al cargar perfil
          </h2>
          <p className="text-red-400 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition-colors"
          >
            Intentar nuevamente
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black">
      <div className="max-w-4xl mx-auto">
        {/* Banner de soporte */}
        {showSupportBanner && (
          <SupportBanner onClose={() => setShowSupportBanner(false)} />
        )}

        {/* Tarjeta del perfil */}
        <div className="bg-gray-900 rounded-xl shadow-2xl border border-gray-800 overflow-hidden">
          {/* Cabecera del perfil */}
          <div className="relative">
            {/* Banner decorativo */}
            <div className="h-32 bg-gradient-to-r from-red-600 to-orange-500"></div>

            {/* Avatar y nombre */}
            <div className="flex flex-col items-center -mt-16 pb-6">
              <div className="relative">
                <img
                  src="https://avatar.iran.liara.run/public/boy"
                  alt="Profile"
                  className="w-32 h-32 rounded-full border-4 border-gray-900 object-cover shadow-xl"
                />
                <div className="absolute bottom-0 right-4 bg-green-500 w-5 h-5 rounded-full border-2 border-gray-900"></div>
              </div>

              <h1 className="mt-4 text-3xl font-bold text-white">
                {userData?.name || "Sin identificar"}
              </h1>

              {/* Account Type Badge with VIP highlight if applicable */}
              <div
                className={`mt-1 px-3 py-1 rounded-full text-sm font-medium ${
                  userData?.typeU === "Cuenta Vip"
                    ? "bg-gradient-to-r from-yellow-600 to-yellow-400 text-yellow-900"
                    : "bg-red-500/20 text-red-400"
                }`}
              >
                {userData?.typeU || "Usuario"}
                {userData?.typeU === "Cuenta Vip" && (
                  <span className="ml-1">✨</span>
                )}
              </div>

              {/* Separador decorativo */}
              <div className="w-16 h-1 bg-gradient-to-r from-red-500 to-orange-500 rounded-full my-4"></div>
            </div>
          </div>

          {/* Información del perfil */}
          <div className="px-6 pb-8">
            <h2 className="text-xl font-bold text-white mb-4">
              Información de cuenta
            </h2>

            {/* Referral Code Redemption Component */}
            <ReferralCodeRedemption
              userData={userData}
              onCodeRedeemed={refreshUserData}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ProfileSection
                icon={Mail}
                title="Correo electrónico"
                value={userData?.email || "Sin identificar"}
              />
              <ProfileSection
                icon={Phone}
                title="Código de referido"
                value={userData?.referralCode || "Sin identificar"}
              />
              <ProfileSection
                icon={Briefcase}
                title="Tipo de cuenta"
                value={userData?.typeU || "Estándar"}
              />
              <ProfileSection
                icon={Calendar}
                title="Fecha de registro"
                value={
                  userData?.createdAt
                    ? new Date(userData.createdAt).toLocaleDateString()
                    : "No disponible"
                }
              />

              {/* Botón de cerrar sesión */}
              <div className="md:col-span-2 flex justify-center mt-8">
                <button
                  onClick={handleLogout}
                  className="flex items-center justify-center gap-3 bg-red-600 hover:bg-red-700 text-white text-lg px-6 py-3 rounded-full transition-all duration-300 shadow-md active:scale-95"
                >
                  <LogOut className="w-5 h-5" />
                  <span>Cerrar sesión</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
