import React, { useEffect, useState, useRef } from "react";
import {
  Heart,
  MessageCircle,
  Share2,
  Sparkles,
  X,
  Send,
} from "lucide-react";
import { ref, onValue, update, get } from "firebase/database";
import { database } from "../../Config/firebase";
import { FaFire, FaMusic } from "react-icons/fa";
import NavShorts from "./NavShorts";
import LoadingDots from "./components/LoadingDots";
import { useNavigate } from "react-router-dom";

export default function HomeShort() {
  const navigate = useNavigate();
  const [shorts, setShorts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [likedShorts, setLikedShorts] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [soundOn, setSoundOn] = useState(true);
  const [showSoundTooltip, setShowSoundTooltip] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const videoRefs = useRef([]);
  const containerRef = useRef(null);
  const startY = useRef(0);
  const progressBarRef = useRef(null);
  const progressInterval = useRef(null);
  const commentInputRef = useRef(null);

  useEffect(() => {
    loadShorts();
    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, []);

  const loadShorts = () => {
    setIsRefreshing(true);
    const usersRef = ref(database, "usuarios");

    // Get all users first
    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      if (!usersData) {
        setIsRefreshing(false);
        return;
      }

      let allShorts = [];

      // Process each user
      Object.keys(usersData).forEach((userId) => {
        const userData = usersData[userId];

        // Check if user has shorts
        if (userData.shorts) {
          // Process shorts for this user
          Object.keys(userData.shorts).forEach((shortId) => {
            const shortData = userData.shorts[shortId];
            allShorts.push({
              id: shortId,
              userId: userId,
              name: userData.name || "Usuario",
              profile: userData.profileImage || "https://placehold.co/100",
              video: shortData.url,
              description: shortData.descripcion || "",
              song: shortData.song || "",
              likes: shortData.likes ? Object.keys(shortData.likes).length : 0,
              // Keep track of user data for this short
              userData: userData,
            });
          });
        }
      });

      if (allShorts.length > 0) {
        setShorts(shuffleArray(allShorts));

        // Load likes state from data
        const initialLikedState = {};
        const currentUserId = localStorage.getItem("userUUID");

        allShorts.forEach((short) => {
          // Check if current user has liked this short
          const userLiked =
            usersData[short.userId]?.shorts?.[short.id]?.likes?.[
              currentUserId
            ] === true;
          initialLikedState[short.id] = userLiked;
        });

        setLikedShorts(initialLikedState);
        simulateLoading();
      } else {
        setIsRefreshing(false);
      }
    });
  };

  // Load comments for current short
  useEffect(() => {
    if (shorts.length > 0 && currentIndex >= 0) {
      const currentShort = shorts[currentIndex];
      const commentsRef = ref(
        database,
        `usuarios/${currentShort.userId}/shorts/${currentShort.id}/comments`
      );

      onValue(commentsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const commentsArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setComments(commentsArray);
        } else {
          setComments([]);
        }
      });
    }
  }, [currentIndex, shorts]);

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const index = Math.round(
          containerRef.current.scrollTop / window.innerHeight
        );
        if (index !== currentIndex && index >= 0 && index < shorts.length) {
          setCurrentIndex(index);
          resetVideoProgress();
          setShowComments(false); // Close comments panel when switching videos
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentIndex, shorts.length]);

  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === currentIndex) {
          video.currentTime = 0;
          video
            .play()
            .catch((error) => console.log("Autoplay prevented:", error));
          startVideoProgressBar(video);
        } else {
          video.pause();
        }
      }
    });
  }, [currentIndex]);

  const resetVideoProgress = () => {
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
      progressInterval.current = null;
    }
    if (progressBarRef.current) {
      progressBarRef.current.style.width = "0%";
    }
  };

  const startVideoProgressBar = (video) => {
    resetVideoProgress();
    const duration = video.duration;
    if (isNaN(duration) || !progressBarRef.current) return;

    let startTime = 0;
    progressInterval.current = setInterval(() => {
      startTime += 0.1;
      const percentage = Math.min((startTime / duration) * 100, 100);
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percentage}%`;
      }
      if (percentage >= 100) {
        clearInterval(progressInterval.current);
        // Auto-scroll to next video when current one ends
        if (currentIndex < shorts.length - 1 && containerRef.current) {
          containerRef.current.scrollTo({
            top: (currentIndex + 1) * window.innerHeight,
            behavior: "smooth",
          });
        }
      }
    }, 100);
  };

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    const currentY = e.touches[0].clientY;
    const diff = currentY - startY.current;

    if (currentIndex === 0 && diff > 100 && !isRefreshing) {
      setIsRefreshing(true);
      loadShorts();
    }
  };

  const simulateLoading = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setIsRefreshing(false);
          if (containerRef.current) {
            containerRef.current.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
          return 0;
        }
        return prevProgress + 5;
      });
    }, 50);
  };

  const handleVideoClick = () => {
    if (showComments) return; // Don't pause/play if comments are open

    const video = videoRefs.current[currentIndex];
    if (!video) return;

    if (video.paused) {
      video.play();
      if (progressInterval.current === null) {
        startVideoProgressBar(video);
      }
    } else {
      video.pause();
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
        progressInterval.current = null;
      }
    }
  };

  const toggleSound = () => {
    const video = videoRefs.current[currentIndex];
    if (!video) return;

    setSoundOn(!soundOn);
    video.muted = soundOn;
    setShowSoundTooltip(true);
    setTimeout(() => setShowSoundTooltip(false), 1500);
  };

  const handleLike = (shortId) => {
    const currentShort = shorts.find((s) => s.id === shortId);
    if (!currentShort) return;

    const userId = localStorage.getItem("userUUID");
    if (!userId) {
      // Handle case when user is not logged in
      alert("Debes iniciar sesión para dar like");
      return;
    }

    const newLikedState = !likedShorts[shortId];
    setLikedShorts((prev) => ({
      ...prev,
      [shortId]: newLikedState,
    }));

    // Update likes in Firebase within user's shorts node
    const updates = {};
    if (newLikedState) {
      // Add like
      updates[
        `usuarios/${currentShort.userId}/shorts/${shortId}/likes/${userId}`
      ] = true;
    } else {
      // Remove like
      updates[
        `usuarios/${currentShort.userId}/shorts/${shortId}/likes/${userId}`
      ] = null;
    }

    update(ref(database), updates);
  };

  const handleNavigateToProfile = (userId, userName) => {
    // Pause video before navigating
    if (videoRefs.current[currentIndex]) {
      videoRefs.current[currentIndex].pause();
    }
    // Navigate to user profile
    navigate(`/profile-view/${userId}`, { state: { userName } });
  };

  const handleShare = (short) => {
    // Check if navigator.share is available (mobile browsers)
    if (navigator.share) {
      navigator
        .share({
          title: `Video de ${short.name}`,
          text: short.description,
          url: `/shorts/:shortId`,
        })
        .catch((error) => console.log("Error sharing", error));
    } else {
      // Fallback for desktop - copy link to clipboard
      navigator.clipboard
        .writeText(short.video)
        .then(() => {
          alert("Enlace copiado al portapapeles");
        })
        .catch((err) => {
          console.error("Error al copiar: ", err);
        });
    }
  };

  const handleToggleComments = () => {
    setShowComments(!showComments);
    // Pause video when opening comments
    if (!showComments && videoRefs.current[currentIndex]) {
      videoRefs.current[currentIndex].pause();
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
        progressInterval.current = null;
      }
    }
  };

  const handleSubmitComment = () => {
    if (!newComment.trim()) return;

    // Get user ID from localStorage
    const userId = localStorage.getItem("userUUID");
    const currentShort = shorts[currentIndex];
    const timestamp = new Date().toISOString();
    const commentId = `comment_${Date.now()}`;

    // Default values for anonymous users
    let commentUserId = userId || "anonymous_user";
    let userName = "Usuario Anónimo";
    let userProfileImage = "https://placehold.co/100";

    // If user is logged in, get their information from the database
    if (userId) {
      // Get user data from the database
      get(ref(database, `usuarios/${userId}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            // Get name and profile image from database
            userName = userData.name || "Usuario";
            userProfileImage =
              userData.profileImage || "https://placehold.co/100";
          }

          // Get first name from full name
          const firstName = userName.split(" ")[0];

          // Submit comment with retrieved data
          submitCommentToDatabase(
            currentShort,
            commentId,
            commentUserId,
            firstName,
            userProfileImage,
            timestamp
          );
        })
        .catch((error) => {
          console.error("Error getting user data:", error);
          // Submit with default values in case of error
          submitCommentToDatabase(
            currentShort,
            commentId,
            commentUserId,
            "Usuario",
            userProfileImage,
            timestamp
          );
        });
    } else {
      // For anonymous users, use default values and submit directly
      submitCommentToDatabase(
        currentShort,
        commentId,
        commentUserId,
        userName,
        userProfileImage,
        timestamp
      );
    }
  };

  // Helper function to submit comment to database
  const submitCommentToDatabase = (
    currentShort,
    commentId,
    userId,
    firstName,
    userProfileImage,
    timestamp
  ) => {
    const commentData = {
      userId: userId,
      userName: firstName,
      userProfile: userProfileImage,
      text: newComment,
      timestamp,
    };

    // Update in Firebase under the short owner's node
    const updates = {};
    updates[
      `usuarios/${currentShort.userId}/shorts/${currentShort.id}/comments/${commentId}`
    ] = commentData;

    update(ref(database), updates)
      .then(() => {
        setNewComment("");
        // Focus back on input for continuous commenting
        if (commentInputRef.current) {
          commentInputRef.current.focus();
        }
      })
      .catch((error) => {
        console.error("Error saving comment:", error);
        alert("Error al guardar el comentario");
      });
  };

  const formatCount = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "K";
    }
    return count;
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return (
      date.toLocaleDateString() +
      " " +
      date.toLocaleTimeString().substring(0, 5)
    );
  };

  // Method to count actual likes for a short
  const getLikeCount = (shortId) => {
    const currentShort = shorts.find((s) => s.id === shortId);
    if (!currentShort) return 0;

    // If we've already loaded the likes count in the shorts data, use that
    return currentShort.likes || 0;
  };

  return (
    <div className="relative h-screen bg-black overflow-hidden">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-b from-black via-black/70 to-transparent p-3 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <FaFire className="text-red-700 h-6 w-6" />
          <div className="h-8 w-px bg-gray-500"></div>
          <span className="text-white font-semibold text-lg">Para ti</span>
        </div>
        {/* <Search className="text-white h-6 w-6" /> */}
      </div>

      {/* Loading Progress Bar */}
      {isRefreshing && (
        <div className="absolute top-14 left-0 right-0 z-50 h-1 bg-gray-800">
          <div
            className="h-full bg-gradient-to-r from-red-500 via-pink-500 to-purple-500 transition-all duration-100 ease-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
      {/* Main Container */}
      <div
        ref={containerRef}
        className="h-full overflow-y-scroll snap-y snap-mandatory scrollbar-hide"
        style={{ scrollSnapType: "y mandatory" }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {shorts.length === 0 ? (
          <div className="h-screen flex flex-col items-center justify-center text-white">
            <div className="rounded-full bg-gray-800 p-4 mb-4">
              <Sparkles className="w-8 h-8 text-pink-500" />
            </div>
            <h3 className="text-xl font-bold mb-2">Cargando contenido</h3>
            <LoadingDots />
          </div>
        ) : (
          shorts.map((short, index) => (
            <div
              key={short.id}
              className="h-screen w-full snap-start relative flex items-center justify-center"
              style={{ scrollSnapAlign: "start" }}
            >
              {/* Video */}
              <video
                ref={(el) => (videoRefs.current[index] = el)}
                src={short.video}
                className="absolute top-0 left-0 w-full h-full object-cover"
                loop
                playsInline
                autoPlay={index === 0}
                muted={!soundOn}
                onClick={handleVideoClick}
              />
              {/* Video Progress Bar */}
              <div className="absolute top-16 left-0 right-0 h-1 bg-gray-800/50 z-10">
                {index === currentIndex && (
                  <div
                    ref={progressBarRef}
                    className="h-full bg-white transition-all duration-100 ease-linear"
                    style={{ width: "0%" }}
                  ></div>
                )}
              </div>
              {/* Sound Toggle Icon */}
              <button
                className="absolute top-20 right-4 z-20 p-2 bg-black/40 rounded-full"
                onClick={toggleSound}
              >
                {soundOn ? (
                  <svg
                    className="w-5 h-5 text-white"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z"></path>
                  </svg>
                ) : (
                  <svg
                    className="w-5 h-5 text-white"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12,4L9,7H5V17H9L12,20V4M16.59,12L14,9.41L15.41,8L18,10.59L20.59,8L22,9.41L19.41,12L22,14.59L20.59,16L18,13.41L15.41,16L14,14.59L16.59,12Z"></path>
                  </svg>
                )}
              </button>

              {/* Sound Changed Tooltip */}
              {showSoundTooltip && (
                <div className="absolute top-32 left-1/2 transform -translate-x-1/2 bg-black/70 text-white px-4 py-2 rounded-full text-sm z-50 animate-fade-in-out">
                  {soundOn ? "Sonido activado" : "Sonido desactivado"}
                </div>
              )}
              {/* Username and Description Overlay */}
              <div className="absolute bottom-28 left-3 right-16 p-2 z-20">
                <div className="flex items-center mb-2">
                  <img
                    src={short.profile}
                    alt=""
                    className="w-10 h-10 rounded-full border-2 border-white mr-3 cursor-pointer"
                    onClick={() =>
                      handleNavigateToProfile(short.userId, short.name)
                    }
                  />
                  <div>
                    <h3
                      className="text-lg font-bold text-white flex items-center cursor-pointer"
                      onClick={() =>
                        handleNavigateToProfile(short.userId, short.name)
                      }
                    >
                      @{short.name.split(" ")[0]}
                    </h3>
                  </div>
                </div>
                <p className="text-white mb-3">{short.description}</p>
                <div className="flex items-center text-white bg-black/30 rounded-full px-3 py-1 w-fit">
                  <FaMusic className="mr-2 animate-spin-slow" />
                  <p className="text-sm truncate max-w-48">
                    {short.song || "Música original - " + short.name}
                  </p>
                </div>
              </div>
              {/* Action Sidebar */}
              <div className="absolute right-2 bottom-28 space-y-6 text-center z-20">
                <button
                  className={`flex flex-col items-center transition-all duration-300 transform ${
                    likedShorts[short.id] ? "scale-110" : ""
                  }`}
                  onClick={() => handleLike(short.id)}
                >
                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/30">
                    <Heart
                      size={26}
                      fill={likedShorts[short.id] ? "#FE2C55" : "none"}
                      stroke={likedShorts[short.id] ? "#FE2C55" : "white"}
                    />
                  </div>
                  <span className="text-xs text-white block mt-1">
                    {formatCount(getLikeCount(short.id))}
                  </span>
                </button>

                <button
                  className="flex flex-col items-center"
                  onClick={handleToggleComments}
                >
                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/30">
                    <MessageCircle size={26} color="white" />
                  </div>
                  <span className="text-xs text-white block mt-1">
                    {formatCount(comments.length || 0)}
                  </span>
                </button>

                <button
                  className="flex flex-col items-center"
                  onClick={() => handleShare(short)}
                >
                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/30">
                    <Share2 size={26} color="white" />
                  </div>
                  <span className="text-xs text-white block mt-1">
                    Compartir
                  </span>
                </button>

                <div
                  className="w-12 h-12 rounded-md overflow-hidden relative animate-disk-rotate cursor-pointer"
                  onClick={() =>
                    handleNavigateToProfile(short.userId, short.name)
                  }
                >
                  <img
                    src={short.profile || "https://placehold.co/100"}
                    className="absolute inset-0 w-full h-full object-cover"
                    alt="Music"
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-3 h-3 rounded-full bg-white"></div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {/* Comments Panel */}
      {showComments && shorts.length > 0 && currentIndex >= 0 && (
        <div className="fixed inset-0 bg-black/90 z-50 flex flex-col">
          <div className="flex justify-between items-center p-4 border-b border-gray-800">
            <h3 className="text-white text-lg font-bold">
              Comentarios ({comments.length})
            </h3>
            <button onClick={handleToggleComments}>
              <X size={24} color="white" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {comments.length === 0 ? (
              <div className="text-center text-gray-400 mt-10">
                <p>No hay comentarios aún</p>
                <p className="mt-2">¡Sé el primero en comentar!</p>
              </div>
            ) : (
              comments.map((comment) => (
                <div key={comment.id} className="mb-4 flex">
                  <img
                    src={comment.userProfile}
                    alt=""
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div className="flex-1">
                    <div className="flex justify-between items-start">
                      <h4 className="text-white font-bold">
                        {comment.userName}
                      </h4>
                      <span className="text-xs text-gray-400">
                        {formatDate(comment.timestamp)}
                      </span>
                    </div>
                    <p className="text-white mt-1">{comment.text}</p>
                  </div>
                </div>
              ))
            )}
          </div>

          <div className="p-4 border-t border-gray-800 flex items-center bg-gray-900">
            <input
              ref={commentInputRef}
              type="text"
              className="flex-1 bg-gray-800 text-white rounded-full px-4 py-2 outline-none"
              placeholder="Añadir un comentario..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSubmitComment()}
            />
            <button
              className="ml-2 p-2 rounded-full bg-pink-600 text-white"
              onClick={handleSubmitComment}
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      )}
      <NavShorts />
      <style jsx>{`
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }

        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        @keyframes disk-rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @keyframes spin-slow {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @keyframes fade-in-out {
          0% {
            opacity: 0;
            transform: translate(-50%, 10px);
          }
          20% {
            opacity: 1;
            transform: translate(-50%, 0);
          }
          80% {
            opacity: 1;
            transform: translate(-50%, 0);
          }
          100% {
            opacity: 0;
            transform: translate(-50%, -10px);
          }
        }

        .animate-spin-slow {
          animation: spin-slow 8s linear infinite;
        }

        .animate-disk-rotate {
          animation: disk-rotate 6s linear infinite;
        }

        .animate-fade-in-out {
          animation: fade-in-out 1.5s ease-in-out forwards;
        }
      `}</style>
    </div>
  );
}
