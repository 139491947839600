import React, { useState, useEffect } from "react";
import {
  DollarSign,
  TrendingUp,
  Trophy,
  Users,
  Calendar,
  ChevronUp,
  ChevronDown,
  Activity,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const mockChartData = [
  { name: "Lun", value: 80 },
  { name: "Mar", value: 85 },
  { name: "Mie", value: 82 },
  { name: "Jue", value: 89 },
  { name: "Vie", value: 88 },
  { name: "Sab", value: 95 },
  { name: "Dom", value: 92 },
];

export default function AppAnality() {
  const [animatedStats, setAnimatedStats] = useState({
    pronosticos: 0,
    beneficio: 0,
    racha: 0,
    roi: 0,
  });

  useEffect(() => {
    const targetStats = {
      pronosticos: 98,
      beneficio: 700000,
      racha: 107,
      roi: 89.5,
    };

    const animationDuration = 2000;
    const steps = 60;
    const interval = animationDuration / steps;

    let currentStep = 0;

    const animation = setInterval(() => {
      if (currentStep >= steps) {
        clearInterval(animation);
        setAnimatedStats(targetStats);
        return;
      }

      const progress = (currentStep + 1) / steps;
      setAnimatedStats({
        pronosticos: Math.round(targetStats.pronosticos * progress),
        beneficio: Math.round(targetStats.beneficio * progress),
        racha: Math.round(targetStats.racha * progress),
        roi: Number((targetStats.roi * progress).toFixed(1)),
      });

      currentStep++;
    }, interval);

    return () => clearInterval(animation);
  }, []);

  const StatCard = ({
    title,
    value,
    icon: Icon,
    trend,
    chart = false,
    color = "green",
  }) => (
    <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-xl border border-gray-700/50">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className={`p-3 rounded-lg bg-${color}-500/10`}>
            <Icon className={`w-6 h-6 text-${color}-500`} />
          </div>
          <h3 className="text-gray-400 font-medium">{title}</h3>
        </div>
        <div className={`flex items-center text-${color}-500`}>
          {trend === "up" ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <span className="text-sm">2.5%</span>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-end justify-between">
          <h2 className="text-3xl font-bold text-white">{value}</h2>
          {chart && (
            <div className="h-16 w-32">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={mockChartData}>
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke={`var(--${color}-500)`}
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderDashboard = () => (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-white">Rendimiento</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Pronósticos Ganados"
          value={`${animatedStats.pronosticos}/100`}
          icon={TrendingUp}
          trend="up"
          chart={true}
          color="green"
        />
        <StatCard
          title="Beneficio Total"
          value={`$${animatedStats.beneficio.toLocaleString()}`}
          icon={DollarSign}
          trend="up"
          chart={true}
          color="blue"
        />
        <StatCard
          title="Racha Actual"
          value={animatedStats.racha}
          icon={Trophy}
          trend="up"
          chart={true}
          color="yellow"
        />
        <StatCard
          title="ROI"
          value={`${animatedStats.roi}%`}
          icon={Activity}
          trend="up"
          chart={true}
          color="purple"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-6 border border-gray-700/50">
          <h3 className="text-xl font-bold text-white mb-4">
            Rendimiento Semanal
          </h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={mockChartData}>
                <XAxis dataKey="name" stroke="#fff" />
                <YAxis stroke="#fff" />
                <Tooltip
                  contentStyle={{ backgroundColor: "#1F2937", border: "none" }}
                  labelStyle={{ color: "#fff" }}
                />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#10B981"
                  strokeWidth={2}
                  dot={{ stroke: "#10B981", strokeWidth: 2, r: 4 }}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl border border-gray-700/50">
          <h3 className="text-xl font-bold text-white mb-4">
            Estadísticas Destacadas
          </h3>
          <div className="space-y-4">
            {[
              {
                label: "Mejor Racha",
                value: "105 Aciertos",
                icon: Trophy,
                color: "yellow",
              },
              {
                label: "Mayor Beneficio",
                value: "$700.000",
                icon: DollarSign,
                color: "green",
              },
              {
                label: "Seguidores",
                value: "8.2k",
                icon: Users,
                color: "blue",
              },
            ].map((stat, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 bg-gray-700/30 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-lg bg-${stat.color}-500/10`}>
                    <stat.icon className={`w-5 h-5 text-${stat.color}-500`} />
                  </div>
                  <span className="text-gray-300">{stat.label}</span>
                </div>
                <span className="text-white font-semibold">{stat.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <main className="flex-1 overflow-y-auto p-4 lg:p-8">
        <div className="mx-auto max-w-7xl">{renderDashboard()}</div>
      </main>
    </div>
  );
}
