import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import FootballLoading from "../../Utils/FootballLoading";
// import disableActions from "../../Config/disableActions";

// Versión de la aplicación
const APP_VERSION = "v2.5.1";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  // Efecto para verificar si hay credenciales guardadas
  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
            // disableActions();

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Guardar email si "recordarme" está activado
      if (rememberMe) {
        localStorage.setItem("rememberedEmail", email);
      } else {
        localStorage.removeItem("rememberedEmail");
      }

      // Guardar el UID en localStorage
      localStorage.setItem("userUUID", user.uid);

      // Redirigir al dashboard después de iniciar sesión
      navigate("/home");
    } catch (error) {
      console.error("Error al iniciar sesión:", error);

      // Mensajes de error más descriptivos
      if (error.code === "auth/user-not-found") {
        setError("No existe una cuenta con este correo electrónico.");
      } else if (error.code === "auth/wrong-password") {
        setError("Contraseña incorrecta. Inténtalo de nuevo.");
      } else if (error.code === "auth/too-many-requests") {
        setError(
          "Demasiados intentos fallidos. Intenta más tarde o restablece tu contraseña."
        );
      } else {
        setError(
          "No se pudo iniciar sesión. Verifica tus credenciales e intenta nuevamente."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-black">
        <div className="mb-4">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
            alt="Logo"
            className="w-16 h-16 rounded-full border-2 border-red-500"
          />
        </div>
        <FootballLoading />
        <p className="mt-4 text-white font-medium animate-pulse">
          Iniciando sesión...
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-black">
      {/* Tarjeta principal */}
      <div className="bg-gray-900 p-8 rounded-xl shadow-2xl max-w-md w-full border border-gray-800 relative overflow-hidden">
        {/* Elemento decorativo */}
        <div className="absolute -top-10 -right-10 w-40 h-40 bg-red-500 opacity-10 rounded-full"></div>
        <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-orange-500 opacity-10 rounded-full"></div>

        {/* Contenido */}
        <div className="relative z-10">
          {/* Logo y encabezado */}
          <div className="flex flex-col items-center mb-8">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
              alt="Logo"
              className="w-24 h-24 rounded-full border-4 border-red-500 shadow-lg mb-4 transition-transform hover:scale-105 duration-300"
            />
            <h1 className="text-3xl font-bold text-white">DeportivosPro</h1>
            <div className="w-16 h-1 bg-gradient-to-r from-red-500 to-orange-500 rounded-full my-3"></div>
            <h2 className="text-2xl font-medium text-gray-200">
              Iniciar Sesión
            </h2>
          </div>

          {/* Mensaje de error */}
          {error && (
            <div className="mb-6 p-3 bg-red-900/30 border border-red-800 rounded-lg">
              <p className="text-red-400 text-sm flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 28 28"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                {error}
              </p>
            </div>
          )}

          {/* Formulario */}
          <form onSubmit={handleSubmit} className="space-y-5">
            <div className="space-y-2">
              <label
                htmlFor="email"
                className="block text-gray-300 text-sm font-medium pl-1"
              >
                Correo electrónico
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </div>
                <input
                  type="email"
                  id="email"
                  className="w-full pl-10 pr-4 py-3 bg-gray-800/70 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  placeholder="nombre@ejemplo.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="password"
                className="block text-gray-300 text-sm font-medium pl-1"
              >
                Contraseña
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <input
                  type="password"
                  id="password"
                  className="w-full pl-10 pr-4 py-3 bg-gray-800/70 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>

            {/* Opciones adicionales */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="h-4 w-4 text-red-500 focus:ring-red-500 border-gray-700 rounded bg-gray-800"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-400"
                >
                  Recordarme
                </label>
              </div>
              <a
                href="/forgot-password"
                className="text-sm font-medium text-red-500 hover:text-red-400 transition-colors duration-200"
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>

            {/* Botón de envío */}
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-red-600 to-red-500 text-white py-3 px-4 rounded-lg font-medium shadow-lg hover:from-red-500 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-300 transform hover:-translate-y-1"
            >
              Iniciar Sesión
            </button>
          </form>

          {/* Enlaces de ayuda */}
          <div className="mt-6 text-center">
            <p className="text-gray-400">
              ¿No tienes una cuenta?{" "}
              <a
                href="/register"
                className="font-medium text-red-500 hover:text-red-400 transition-colors duration-200"
              >
                Regístrate ahora
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Footer con versión */}
      {/* <div className="mt-8 text-center text-gray-500 text-xs">
        <p>
          © {new Date().getFullYear()} DeportivosPro. Todos los derechos reservados.
        </p>
        <p className="mt-1">{APP_VERSION}</p>
      </div> */}
    </div>
  );
}

export default Login;
