import React, { useState } from "react";
import {
  Share2,
  Info,
  Download,
  Heart,
  Star,
  Phone,
  Mail,
  MessageSquare,
  Facebook,
  Twitter,
  Instagram,
  Globe,
  Copy,
  Check,
} from "lucide-react";

const DeportivosProApp = () => {
  const [liked, setLiked] = useState(false);
  const [activeTab, setActiveTab] = useState("detalles");
  const [copied, setCopied] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const appInfo = {
    name: "DeportivosPro",
    version: "3.1.2",
    description:
      "Tu plataforma premium para pronósticos deportivos profesionales. Obtén análisis detallados, estadísticas actualizadas y predicciones de expertos para tus apuestas deportivas.",
    downloads: "250K+",
    rating: 4.9,
    lastUpdate: "25 marzo, 2025",
    features: [
      "Pronósticos diarios",
      "Estadísticas en tiempo real",
      "Alertas personalizadas",
      "Análisis de expertos",
      "Comunidad activa",
      "Historial de aciertos",
    ],
    contactInfo: {
      phone: "+34 912 456 789",
      email: "contacto@deportivospro.com",
      website: "www.deportivospro.com",
    },
    socialMedia: {
      facebook: "DeportivosPro",
      twitter: "@DeportivosPro",
      instagram: "@deportivos.pro",
    },
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const handleLike = () => {
    setLiked(!liked);
  };

  const handleCopyLink = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSharePlatform = (platform) => {
    alert(`Compartiendo en ${platform}...`);
    setShowShareOptions(false);
  };

  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
      {/* Header con imagen de la app */}
      <div className="bg-gradient-to-r from-green-500 to-blue-600 h-40 relative flex items-center justify-center">
        <div
          className="absolute inset-0 opacity-20"
          style={{
            backgroundImage: 'url("/api/placeholder/800/400")',
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="bg-white p-4 rounded-xl shadow-md z-10">
          <div className="text-3xl font-bold bg-gradient-to-r from-green-500 to-blue-600 text-transparent bg-clip-text">
            {appInfo.name}
          </div>
          <div className="text-xs text-center text-gray-500">
            Pronósticos Deportivos Profesionales
          </div>
        </div>
      </div>

      {/* Tabs de navegación */}
      <div className="flex border-b">
        <button
          className={`flex-1 py-3 font-medium text-sm ${
            activeTab === "detalles"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("detalles")}
        >
          Detalles
        </button>
        <button
          className={`flex-1 py-3 font-medium text-sm ${
            activeTab === "contacto"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("contacto")}
        >
          Contacto
        </button>
      </div>

      {activeTab === "detalles" && (
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
              Versión {appInfo.version}
            </span>
            <div className="flex items-center">
              <Star
                className="h-4 w-4 text-yellow-400 mr-1"
                fill="currentColor"
              />
              <span className="text-gray-600 text-sm">{appInfo.rating}</span>
            </div>
          </div>

          <p className="text-gray-600 text-sm mb-6">{appInfo.description}</p>

          {/* Características principales */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold text-gray-700 mb-2">
              Características principales:
            </h3>
            <ul className="grid grid-cols-2 gap-2">
              {appInfo.features.map((feature, index) => (
                <li
                  key={index}
                  className="flex items-center text-xs text-gray-600"
                >
                  <div className="w-2 h-2 bg-green-400 rounded-full mr-2"></div>
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          {/* Estadísticas */}
          <div className="flex justify-between text-xs text-gray-500 mb-6">
            <div className="flex items-center">
              <Download className="h-3 w-3 mr-1" />
              <span>{appInfo.downloads} descargas</span>
            </div>
            <div>Actualizado: {appInfo.lastUpdate}</div>
          </div>

          {/* Botones de acción */}
          <div className="flex gap-2 relative">
            <button
              className="flex-1 bg-gradient-to-r from-green-500 to-blue-600 text-white py-2 px-4 rounded-lg font-medium flex items-center justify-center hover:opacity-90 transition-opacity"
              onClick={handleShare}
            >
              <Share2 className="h-4 w-4 mr-2" />
              Compartir
            </button>

            <button
              className={`w-12 h-10 flex items-center justify-center rounded-lg border ${
                liked ? "bg-pink-50 border-pink-200" : "border-gray-200"
              }`}
              onClick={handleLike}
            >
              <Heart
                className={`h-5 w-5 ${
                  liked ? "text-pink-500 fill-pink-500" : "text-gray-400"
                }`}
              />
            </button>

            <button className="w-12 h-10 flex items-center justify-center rounded-lg border border-gray-200">
              <Info className="h-5 w-5 text-gray-400" />
            </button>

            {/* Opciones de compartir */}
            {showShareOptions && (
              <div className="absolute top-12 left-0 right-0 bg-white rounded-lg shadow-lg p-4 z-10 border border-gray-100">
                <div className="text-sm font-medium text-gray-700 mb-3">
                  Compartir vía:
                </div>
                <div className="grid grid-cols-4 gap-4 mb-4">
                  <button
                    onClick={() => handleSharePlatform("Facebook")}
                    className="flex flex-col items-center justify-center"
                  >
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mb-1">
                      <Facebook className="h-5 w-5 text-blue-600" />
                    </div>
                    <span className="text-xs text-gray-600">Facebook</span>
                  </button>

                  <button
                    onClick={() => handleSharePlatform("Twitter")}
                    className="flex flex-col items-center justify-center"
                  >
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mb-1">
                      <Twitter className="h-5 w-5 text-blue-600" />
                    </div>
                    <span className="text-xs text-gray-600">Twitter</span>
                  </button>

                  <button
                    onClick={() => handleSharePlatform("WhatsApp")}
                    className="flex flex-col items-center justify-center"
                  >
                    <div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center mb-1">
                      <MessageSquare className="h-5 w-5 text-green-600" />
                    </div>
                    <span className="text-xs text-gray-600">WhatsApp</span>
                  </button>

                  <button
                    onClick={() => handleSharePlatform("Instagram")}
                    className="flex flex-col items-center justify-center"
                  >
                    <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center mb-1">
                      <Instagram className="h-5 w-5 text-purple-600" />
                    </div>
                    <span className="text-xs text-gray-600">Instagram</span>
                  </button>
                </div>

                <div className="flex items-center bg-gray-50 rounded-lg p-2">
                  <input
                    type="text"
                    value="https://deportivospro.com/app"
                    readOnly
                    className="flex-1 bg-transparent border-none text-xs text-gray-500 focus:outline-none"
                  />
                  <button
                    className="ml-2 bg-gray-200 hover:bg-gray-300 rounded-md p-1"
                    onClick={handleCopyLink}
                  >
                    {copied ? (
                      <Check className="h-4 w-4 text-green-600" />
                    ) : (
                      <Copy className="h-4 w-4 text-gray-600" />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === "contacto" && (
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Contáctanos
          </h3>

          <div className="space-y-4">
            <div className="flex items-center p-3 bg-gray-50 rounded-lg">
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                <Phone className="h-5 w-5 text-blue-600" />
              </div>
              <div>
                <div className="text-xs text-gray-500">Teléfono</div>
                <div className="text-sm font-medium">
                  {appInfo.contactInfo.phone}
                </div>
              </div>
            </div>

            <div className="flex items-center p-3 bg-gray-50 rounded-lg">
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                <Mail className="h-5 w-5 text-blue-600" />
              </div>
              <div>
                <div className="text-xs text-gray-500">Email</div>
                <div className="text-sm font-medium">
                  {appInfo.contactInfo.email}
                </div>
              </div>
            </div>

            <div className="flex items-center p-3 bg-gray-50 rounded-lg">
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                <Globe className="h-5 w-5 text-blue-600" />
              </div>
              <div>
                <div className="text-xs text-gray-500">Sitio Web</div>
                <div className="text-sm font-medium">
                  {appInfo.contactInfo.website}
                </div>
              </div>
            </div>
          </div>

          <h3 className="text-lg font-semibold text-gray-800 mt-6 mb-4">
            Redes Sociales
          </h3>

          <div className="flex justify-between">
            <button
              onClick={() => handleSharePlatform("Facebook")}
              className="flex flex-col items-center justify-center"
            >
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-2">
                <Facebook className="h-6 w-6 text-blue-600" />
              </div>
              <span className="text-xs text-gray-600">Facebook</span>
            </button>

            <button
              onClick={() => handleSharePlatform("Twitter")}
              className="flex flex-col items-center justify-center"
            >
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-2">
                <Twitter className="h-6 w-6 text-blue-600" />
              </div>
              <span className="text-xs text-gray-600">Twitter</span>
            </button>

            <button
              onClick={() => handleSharePlatform("Instagram")}
              className="flex flex-col items-center justify-center"
            >
              <div className="w-12 h-12 rounded-full bg-purple-100 flex items-center justify-center mb-2">
                <Instagram className="h-6 w-6 text-purple-600" />
              </div>
              <span className="text-xs text-gray-600">Instagram</span>
            </button>
          </div>

          <div className="mt-6">
            <button className="w-full bg-gradient-to-r from-green-500 to-blue-600 text-white py-3 rounded-lg font-medium">
              Enviar Mensaje
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeportivosProApp;
