import React, { useState, useEffect } from "react";
import { database } from "../../Config/firebase";
import { ref, onValue } from "firebase/database";
import { Link } from "react-router-dom";
import { Clock, Check, CheckCheck } from "lucide-react";
import NavShorts from "./NavShorts";

export default function ChatHome() {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState({});

  useEffect(() => {
    // Obtener el ID del usuario actual del localStorage
    const currentUserUUID = localStorage.getItem("userUUID");

    if (!currentUserUUID) {
      setLoading(false);
      return;
    }

    // Referencia a todos los chats
    const chatsRef = ref(database, "chats");

    // Referencia al estado online de los usuarios
    const onlineStatusRef = ref(database, "userStatus");

    // Escuchar cambios en el estado online
    onValue(onlineStatusRef, (snapshot) => {
      const statusData = snapshot.val() || {};
      setOnlineUsers(statusData);
    });

    // Obtener la lista de conversaciones
    onValue(chatsRef, async (snapshot) => {
      const chatsData = snapshot.val();
      if (!chatsData) {
        setLoading(false);
        setConversations([]);
        return;
      }

      const userConversations = [];

      // Procesar cada chat para encontrar los que incluyen al usuario actual
      for (const [chatId, chatData] of Object.entries(chatsData)) {
        // Verificar si el usuario actual es participante
        if (chatData.participants && chatData.participants[currentUserUUID]) {
          // Obtener el ID del otro participante
          const otherUserId = Object.keys(chatData.participants).find(
            (id) => id !== currentUserUUID
          );

          if (!otherUserId) continue;

          // Obtener datos del otro usuario
          const userRef = ref(database, `usuarios/${otherUserId}`);
          const userSnapshot = await new Promise((resolve) => {
            onValue(userRef, resolve, { onlyOnce: true });
          });

          const userData = userSnapshot.val() || {};

          // Verificar si está escribiendo
          const isTyping = chatData.typing && chatData.typing[otherUserId];

          userConversations.push({
            id: otherUserId,
            chatId,
            nombre: userData.name || "Usuario",
            profileImage:
              userData.profileImage || "https://via.placeholder.com/150?text=?",
            lastMessage: chatData.lastMessage?.text || "Nuevo chat",
            timestamp: chatData.lastMessage?.timestamp || Date.now(),
            isRead: chatData.lastMessage?.read || false,
            isTyping: isTyping,
          });
        }
      }

      // Ordenar por timestamp del último mensaje (más reciente primero)
      userConversations.sort((a, b) => b.timestamp - a.timestamp);

      setConversations(userConversations);
      setLoading(false);
    });
  }, []);

  // Función para formatear la fecha
  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const messageDate = new Date(timestamp);

    // Si es hoy, mostrar la hora
    if (messageDate.toDateString() === now.toDateString()) {
      return messageDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    // Si es esta semana, mostrar el día
    const diffDays = Math.round((now - messageDate) / (1000 * 60 * 60 * 24));
    if (diffDays < 7) {
      const days = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
      return days[messageDate.getDay()];
    }

    // Si es más antiguo, mostrar la fecha
    return messageDate.toLocaleDateString([], {
      day: "2-digit",
      month: "2-digit",
    });
  };

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <div className="bg-black border-b border-gray-800 p-4 sticky top-0 z-10">
        <h1 className="text-xl font-bold text-center">Mensajes</h1>
      </div>

      <div className="flex-1 overflow-y-auto p-2">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-pulse flex space-x-2">
              <div className="w-3 h-3 bg-pink-500 rounded-full"></div>
              <div className="w-3 h-3 bg-pink-500 rounded-full"></div>
              <div className="w-3 h-3 bg-pink-500 rounded-full"></div>
            </div>
          </div>
        ) : conversations.length === 0 ? (
          <div className="flex flex-col justify-center items-center h-full p-4">
            <div className="w-24 h-24 bg-gray-800 rounded-full flex items-center justify-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                />
              </svg>
            </div>
            <p className="text-gray-500 text-center">
              No tienes conversaciones aún
            </p>
            <p className="text-gray-600 text-sm text-center mt-2">
              Tus mensajes con otros usuarios aparecerán aquí
            </p>
          </div>
        ) : (
          <ul className="space-y-2">
            {conversations.map((conversation) => (
              <li key={conversation.id}>
                <Link
                  to={`/inbox/${conversation.id}`}
                  className="flex items-center p-3 rounded-lg bg-gray-900 hover:bg-gray-800 transition-colors"
                >
                  <div className="relative">
                    <div className="w-14 h-14 rounded-full overflow-hidden mr-3 border border-gray-700">
                      <img
                        src={conversation.profileImage}
                        alt={conversation.nombre}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://placehold.co/100";
                        }}
                      />
                    </div>
                    {onlineUsers[conversation.id] && (
                      <div className="absolute bottom-0 right-2 w-3 h-3 bg-green-500 rounded-full border-2 border-gray-900"></div>
                    )}
                  </div>

                  <div className="flex-1 min-w-0">
                    <div className="flex justify-between items-center">
                      <h2 className="font-semibold truncate">
                        {conversation.nombre}
                      </h2>
                      <div className="text-xs text-gray-500 ml-2">
                        {formatTimestamp(conversation.timestamp)}
                      </div>
                    </div>

                    <div className="flex items-center mt-1">
                      {conversation.isTyping ? (
                        <p className="text-sm text-pink-500 font-medium">
                          Escribiendo...
                        </p>
                      ) : (
                        <>
                          <p className="text-sm text-gray-400 truncate flex-1">
                            {conversation.lastMessage}
                          </p>
                          {conversation.isRead ? (
                            <CheckCheck className="w-4 h-4 text-blue-500 ml-1" />
                          ) : (
                            <Check className="w-4 h-4 text-gray-500 ml-1" />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
        <NavShorts />
      
    </div>
  );
}
