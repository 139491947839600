import React, { useState, useEffect } from "react";
import { database } from "../../Config/firebase";
import {
  ref,
  get,
  update,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { Gift, CheckCircle, XCircle } from "lucide-react";

const ReferralCodeRedemption = ({ userData, onCodeRedeemed }) => {
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [userUid, setUserUid] = useState(null);

  const isVipAccount = userData?.typeU === "Cuenta Vip";
  const hasActiveReferral = userData?.activeReferral;

  // Obtener el UID del usuario por su email cuando el componente se monta
  useEffect(() => {
    const getUserUidByEmail = async () => {
      try {
        if (!userData?.email) return;

        const usuariosRef = ref(database, "/usuarios");
        const userQuery = query(
          usuariosRef,
          orderByChild("email"),
          equalTo(userData.email)
        );
        const snapshot = await get(userQuery);

        if (snapshot.exists()) {
          const users = snapshot.val();
          const uid = Object.keys(users)[0]; // Obtener el primer UID encontrado
          setUserUid(uid);
        } else {
          console.error(
            "No se encontró el usuario con el email:",
            userData.email
          );
        }
      } catch (error) {
        console.error("Error al buscar el UID del usuario:", error);
      }
    };

    getUserUidByEmail();
  }, [userData?.email]);

  const resetMessage = () => {
    setTimeout(() => {
      setMessage(null);
      setMessageType(null);
    }, 5000);
  };

  const setMessageWithType = (msg, type) => {
    setMessage(msg);
    setMessageType(type);
    resetMessage();
  };

  const validateAndRedeemCode = async () => {
    if (!referralCode.trim()) {
      setMessageWithType("Por favor, ingresa un código de referido.", "error");
      return;
    }

    if (!userUid) {
      setMessageWithType(
        "No se pudo identificar tu cuenta. Por favor, vuelve a iniciar sesión.",
        "error"
      );
      return;
    }

    setIsSubmitting(true);
    setMessageWithType("Verificando código...", "info");

    try {
      // Validar si el usuario es VIP
      if (isVipAccount) {
        setMessageWithType(
          "Ya tienes una Cuenta Vip y no puedes redimir códigos.",
          "error"
        );
        setIsSubmitting(false);
        return;
      }

      // Validar si ya tiene un código activo
      if (hasActiveReferral) {
        setMessageWithType(
          "Ya tienes un código activo y no puedes redimir otro.",
          "error"
        );
        setIsSubmitting(false);
        return;
      }

      // Verificar si el código existe
      const codesRef = ref(database, "/App/code_invita");
      const codesSnapshot = await get(codesRef);

      if (!codesSnapshot.exists()) {
        setMessageWithType("No se encontraron códigos disponibles.", "error");
        setIsSubmitting(false);
        return;
      }

      const codesData = codesSnapshot.val();

      // Buscar el código ingresado
      const codeEntry = Object.entries(codesData).find(
        ([, value]) => value.code === referralCode.trim()
      );

      if (!codeEntry) {
        setMessageWithType("Código de referido inválido.", "error");
        setIsSubmitting(false);
        return;
      }

      const [codeKey, codeMatch] = codeEntry;

      // Verificar si el usuario ya ha redimido este código
      if (userData.redeemedCodes?.includes(codeMatch.code)) {
        setMessageWithType(
          "Ya has redimido este código anteriormente.",
          "error"
        );
        setIsSubmitting(false);
        return;
      }

      // Obtener los datos actuales del usuario para no sobrescribir
      const userRef = ref(database, `/usuarios/${userUid}`);
      const userSnapshot = await get(userRef);

      if (!userSnapshot.exists()) {
        setMessageWithType(
          "No se pudieron obtener los datos de usuario. Inténtalo nuevamente.",
          "error"
        );
        setIsSubmitting(false);
        return;
      }

      // Conservar los datos actuales del usuario
      const currentUserData = userSnapshot.val();

      // Calcular fecha de expiración
      const now = new Date();
      const expirationDate = new Date(
        now.setDate(now.getDate() + codeMatch.duration_day)
      );

      // Preparar solo las actualizaciones necesarias para el usuario
      const userUpdates = {
        typeU: "Cuenta Vip",
        activeReferral: {
          code: codeMatch.code,
          expirationDate: expirationDate.toISOString(),
          redeemedAt: new Date().toISOString(),
          invitedBy: codeMatch.name_invita,
          duration_day: codeMatch.duration_day,
        },
        redeemedCodes: [
          ...(currentUserData.redeemedCodes || []),
          codeMatch.code,
        ],
      };

      // Actualizar el contador de usuarios invitados en el código
      const codeUpdates = {};
      const invitedUsers = codeMatch.invitedUsers || 0;
      codeUpdates[`/App/code_invita/${codeKey}/invitedUsers`] =
        invitedUsers + 1;

      // Actualizar solo los campos específicos del usuario
      Object.keys(userUpdates).forEach((key) => {
        codeUpdates[`/usuarios/${userUid}/${key}`] = userUpdates[key];
      });

      // Realizar las actualizaciones
      await update(ref(database), codeUpdates);

      // Actualizar los datos locales para la UI
      const updatedUserData = {
        ...currentUserData,
        ...userUpdates,
      };

      setMessageWithType(
        `¡Código redimido correctamente! Tu cuenta ahora es VIP por ${codeMatch.duration_day} días.`,
        "success"
      );
      setReferralCode("");
      setShowCodeInput(false);

      if (onCodeRedeemed) {
        onCodeRedeemed(updatedUserData);
      }
    } catch (error) {
      console.error("Error al redimir código:", error);
      setMessageWithType(
        "Error al procesar el código. Inténtalo nuevamente.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mt-6 mb-6">
      {!showCodeInput ? (
        <button
          onClick={() => setShowCodeInput(true)}
          disabled={isVipAccount || hasActiveReferral || !userUid}
          className={`flex items-center justify-center gap-2 w-full py-3 px-4 rounded-lg transition-all duration-300 ${
            isVipAccount || hasActiveReferral || !userUid
              ? "bg-gray-700 text-gray-400 cursor-not-allowed"
              : "bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-700 hover:to-indigo-700 shadow-lg hover:shadow-purple-500/20"
          }`}
        >
          <Gift className="w-5 h-5" />
          <span>
            {isVipAccount
              ? "Ya tienes Cuenta Vip"
              : hasActiveReferral
              ? "Ya tienes un código activo"
              : !userUid
              ? "Cargando información..."
              : "Redimir código de referido"}
          </span>
        </button>
      ) : (
        <div className="bg-gray-800 rounded-lg p-4 shadow-lg animate-fadeIn">
          <h3 className="text-lg font-semibold text-white mb-3">
            Ingresa tu código de referido
          </h3>
          <div className="flex flex-col sm:flex-row gap-3">
            <input
              type="text"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              placeholder="Ej: AMERED"
              className="flex-1 bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
              disabled={isSubmitting}
            />
            <div className="flex gap-2">
              <button
                onClick={validateAndRedeemCode}
                disabled={isSubmitting || !userUid}
                className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                  isSubmitting || !userUid
                    ? "bg-gray-600 text-gray-300 cursor-not-allowed"
                    : "bg-purple-600 text-white hover:bg-purple-700"
                }`}
              >
                {isSubmitting ? "Procesando..." : "Redimir"}
              </button>
              <button
                onClick={() => {
                  setShowCodeInput(false);
                  setReferralCode("");
                  setMessage(null);
                }}
                disabled={isSubmitting}
                className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
              >
                Cancelar
              </button>
            </div>
          </div>

          {message && (
            <div
              className={`mt-3 p-3 rounded-lg flex items-center ${
                messageType === "success"
                  ? "bg-green-500/20 text-green-300 border border-green-500"
                  : messageType === "info"
                  ? "bg-blue-500/20 text-blue-300 border border-blue-500"
                  : "bg-red-500/20 text-red-300 border border-red-500"
              }`}
            >
              {messageType === "success" ? (
                <CheckCircle className="w-5 h-5 mr-2" />
              ) : messageType === "error" ? (
                <XCircle className="w-5 h-5 mr-2" />
              ) : null}
              {message}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReferralCodeRedemption;
