import React, { useState, useEffect } from "react";
import {
  ref,
  get,
  update,
  query,
  orderByChild,
  equalTo,
  push,
  set,
} from "firebase/database";
import { database } from "../../Config/firebase";
import FootballLoading from "../../Utils/FootballLoading";
import useUserData from "../../Hooks/useUserData";
import { AlertCircle, HelpCircle, Home, Search, ShoppingBag, ShoppingCart, Star, User } from "lucide-react";
import MenuSearch from "./menu/Search";
import MenuCarrito from "./menu/Carrito";
import HomeHelp from "../Support/HomeHelp";
import MenuPerfil from "./menu/Perfil";

export default function HomeShop() {
  const { userData, loading, error } = useUserData();
  const [activeTab, setActiveTab] = useState("all");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("info"); // 'info', 'success', 'error'

  const WHATSAPP_NUMBER = "+3023580862";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const shopRef = ref(database, "/shop");
        const snapshot = await get(shopRef);

        if (snapshot.exists()) {
          const data = snapshot.val();
          const categoriesData = Object.entries(data.categories || {}).map(
            ([id, category]) => ({
              id,
              ...category,
            })
          ); 
          const productsData = Object.entries(data.products || {}).map(
            ([id, product]) => ({
              id,
              ...product,
            })
          );

          setCategories(categoriesData);
          setProducts(productsData);
          setFilteredProducts(productsData);
        } else {
          showModal("No se encontraron datos.", "error");
        }
      } catch (err) {
        showModal(`Error al cargar los datos: ${err.message}`, "error");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterProducts(activeTab, searchTerm);
  }, [activeTab, searchTerm, products]);

  const filterProducts = (category, search) => {
    let filtered = [...products];

    if (category !== "all") {
      filtered = filtered.filter((product) => product.category === category);
    }

    if (search) {
      const searchLower = search.toLowerCase();
      filtered = filtered.filter(
        (product) =>
          product.name.toLowerCase().includes(searchLower) ||
          product.description?.toLowerCase().includes(searchLower)
      );
    }

    setFilteredProducts(filtered);
  };

  const showModal = (message, type = "info") => {
    setModalMessage(message);
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleBuy = async (product) => {
    try {
      if (!userData?.email) {
        showModal("Cargando datos del usuario. Intenta nuevamente.", "error");
        return;
      }

      // Buscar usuario por email en Firebase
      const usuariosRef = ref(database, "usuarios");
      const userQuery = query(
        usuariosRef,
        orderByChild("email"),
        equalTo(userData.email)
      );
      const snapshot = await get(userQuery);

      if (!snapshot.exists()) {
        showModal("No se encontró información del usuario.", "error");
        return;
      }

      // Obtener los datos del usuario (tomará el primer usuario encontrado con ese email)
      const userDataArray = Object.entries(snapshot.val());
      const [userId, userDataFromDB] = userDataArray[0];

      // Validar y convertir el saldo a número
      let userSaldo = parseFloat(userDataFromDB.saldo);

      if (isNaN(userSaldo)) {
        showModal("Error: No se pudo obtener el saldo correctamente.", "error");
        return;
      }

      if (userSaldo < product.price) {
        showModal(
          `Saldo insuficiente. Tienes $${userSaldo} y el producto cuesta $${product.price}.`,
          "error"
        );
        setIsModalOpen(true); // Mostrar el modal con el mensaje de saldo insuficiente
        return;
      }

      // Calcular nuevo saldo y actualizar en Firebase
      const newSaldo = userSaldo - product.price;
      const userRef = ref(database, `/usuarios/${userId}`);
      await update(userRef, { saldo: newSaldo.toString() }); // Convertir a string para mantener el formato original

      // Agregar la compra al nodo "shop/compras"
      const comprasRef = ref(database, "shop/compras");
      const compraId = push(comprasRef); // Generar ID único para la compra
      await set(compraId, {
        userId,
        productId: product.id,
        productName: product.name,
        price: product.price,
        purchaseDate: new Date().toISOString(), // Guardar la fecha de la compra
      });

      // Mensaje para WhatsApp
      const whatsappMessage = `¡Hola! He realizado una compra en DeportivosPro:
    - Producto: ${product.name}
    - Precio: $${product.price}
    - Usuario: ${userData.email}
    Por favor, revisa mi compra.`;

      const whatsappUrl = `https://wa.me/${WHATSAPP_NUMBER}?text=${encodeURIComponent(
        whatsappMessage
      )}`;

      showModal(
        <div className="text-center">
          <p className="mb-4">¡Compra exitosa! Has comprado {product.name}.</p>
          <p className="mb-4">Tu nuevo saldo es: ${newSaldo}</p>
          <a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors mb-4"
          >
            Contactar por WhatsApp
          </a>
        </div>,
        "success"
      );
    } catch (error) {
      console.error("Error en handleBuy:", error);
      showModal("Error al procesar la compra.", "error");
    }
  };


  if (loading) return <FootballLoading />;
  if (error) return <p className="text-red-600">Error: {error}</p>;

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-950 to-blue-900">
      <header className="sticky top-0 z-10 bg-blue-950 shadow-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-white">DeportivosPro</h1>
          <div className="flex items-center gap-4">
            <div className="bg-blue-800 px-4 py-2 rounded-lg">
              <span className="text-white font-medium">
                Saldo: ${userData.saldo}
              </span>
            </div>
          </div>
        </div>

        <div className="relative mb-4">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar productos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg bg-white/10 text-white placeholder:text-gray-400 border-none focus:ring-2 focus:ring-blue-500 outline-none"
          />
        </div>

        <div className="flex gap-2 bg-blue-900/50 p-1 rounded-lg">
          <button
            onClick={() => setActiveTab("all")}
            className={`flex-1 px-4 py-2 rounded-md text-white transition-colors ${
              activeTab === "all" ? "bg-blue-700" : "hover:bg-blue-800"
            }`}
          >
            Todos
          </button>
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setActiveTab(category.id)}
              className={`flex-1 px-4 py-2 rounded-md text-white transition-colors ${
                activeTab === category.id ? "bg-blue-700" : "hover:bg-blue-800"
              }`}
            >
              <span className="flex items-center justify-center gap-2">
                {category.icon} {category.name}
              </span>
            </button>
          ))}
        </div>
      </header>

      <main className="flex-1 container mx-auto px-4 py-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="relative">
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-48 object-cover"
                />
                {product.discount && (
                  <span className="absolute top-2 right-2 bg-red-500 text-white text-sm px-2 py-1 rounded">
                    -{product.discount}
                  </span>
                )}
                {product.featured && (
                  <span className="absolute top-2 left-2 bg-yellow-500 text-white text-sm px-2 py-1 rounded flex items-center gap-1">
                    <Star className="w-4 h-4" /> Destacado
                  </span>
                )}
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-lime-800 mb-2">
                  {product.name}
                </h3>
                <p className="text-gray-600 mb-4">{product.description}</p>
                <div className="flex justify-between items-center">
                  <span className="text-xl font-bold text-blue-600">
                    ${product.price.toLocaleString()}
                  </span>
                  <button
                    onClick={() => handleBuy(product)}
                    className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 transition-colors"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-900 rounded-lg shadow-xl w-96 overflow-hidden">
            <div className="p-4 border-b">
              <h3 className="text-lg font-semibold flex items-center gap-2">
                {modalType === "error" && (
                  <AlertCircle className="text-red-500" />
                )}
                {modalType === "success" && <Star className="text-green-500" />}
                {modalType === "info" && (
                  <AlertCircle className="text-blue-500" />
                )}
                {modalType === "error"
                  ? "Error"
                  : modalType === "success"
                  ? "¡Éxito!"
                  : "Información"}
              </h3>
            </div>
            <div className="p-4">{modalMessage}</div>
            <div className="p-4 flex justify-end gap-2">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
        
      )}
      {/* Vista de búsqueda */}
              {activeTab === "search" && (
                <div className="p-4">
                  {/* Vista de búsqueda */}
                  <MenuSearch />
                </div>
              )}
      
              {/* Vista del carrito */}
              {activeTab === "cart" && (
                <div className="p-4">
                  {/* Vista del carrito */}
                  <MenuCarrito />
                </div>
              )}
      
              {/* Vista de favoritos */}
              {activeTab === "help" && (
                <div className="p-4">
                  {/* Vista de favoritos */}
                  <HomeHelp />
                </div>
              )}
      
              {/* Vista del perfil */}
              {activeTab === "profile" && (
                <div className="p-4">
                  {/* Vista del perfil */}
                  <MenuPerfil />
                </div>
              )}
    </div>
  );
}
