// dale mejor style por FaVolumeXmark, y pon como un logo para el Login, yq ue sea un login de un software, algo mas robusto,
// y pon metos de aut con goolge, y pon a funcinar el login con firebase,
// demo/config/firebase.js nodo: usuarios en la bd realtime de firebase:

import React, { useState } from "react";
import { Eye, EyeOff, Lock, Mail } from "lucide-react";

const LoginDemo = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "El email es requerido";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email inválido";
    if (!formData.password) newErrors.password = "La contraseña es requerida";
    else if (formData.password.length < 6)
      newErrors.password = "Mínimo 6 caracteres";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      console.log("Form submitted:", formData);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-200 to-purple-400 flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        <div className="bg-white rounded-2xl shadow-xl p-8">
          {/* Header */}
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-gray-800 mb-2">
              DocManager
            </h1>
            <p className="text-gray-500">Ingresa a tu cuenta para continuar</p>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Email Field */}
            <div>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="email"
                  placeholder="Email"
                  className={`w-full pl-12 pr-4 text-black py-3 rounded-lg border ${
                    errors.email ? "border-red-300" : "border-gray-200"
                  } focus:border-blue-400 focus:ring-2 focus:ring-blue-100 transition-all duration-300`}
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                    if (errors.email) setErrors({ ...errors, email: "" });
                  }}
                />
              </div>
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            {/* Password Field */}
            <div>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Contraseña"
                  className={`w-full pl-12 pr-12 text-black py-3 rounded-lg border ${
                    errors.password ? "border-red-300" : "border-gray-200"
                  } focus:border-blue-400 focus:ring-2 focus:ring-blue-100 transition-all duration-300`}
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    if (errors.password) setErrors({ ...errors, password: "" });
                  }}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors duration-300"
                >
                  {showPassword ? (
                    <EyeOff className="w-5 h-5" />
                  ) : (
                    <Eye className="w-5 h-5" />
                  )}
                </button>
              </div>
              {errors.password && (
                <p className="mt-1 text-sm text-red-500">{errors.password}</p>
              )}
            </div>

            {/* Remember & Forgot */}
            <div className="flex items-center justify-between text-sm">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded border-gray-300 text-blue-500 focus:ring-blue-400"
                />
                <span className="ml-2 text-gray-600">Recordarme</span>
              </label>
              <button
                type="button"
                className="text-blue-500 hover:text-blue-600 transition-colors duration-300"
              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg transition-all duration-300 transform hover:shadow-lg hover:-translate-y-0.5"
            >
              Iniciar Sesión
            </button>
            {/* Register Link */}
            {/* <p className="text-center text-gray-600 text-sm">
              ¿No tienes cuenta?{" "}
              <button
                type="button"
                className="text-blue-500 hover:text-blue-600 transition-colors duration-300"
              >
                Regístrate aquí
              </button>
            </p> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginDemo;
