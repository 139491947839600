import React, { useState, useEffect } from "react";
import { getDatabase, ref, get, update } from "firebase/database";
import { getAuth } from "firebase/auth";
import { Trophy, Phone, CheckCircle, X } from "lucide-react";

const VipPhoneModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [userData, setUserData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const db = getDatabase();
    const user = auth.currentUser;

    if (user) {
      const userRef = ref(db, `usuarios/${user.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setUserData(data);
          // Si es VIP y no tiene número de celular, mostrar el modal
          if (data.typeU === "Cuenta Vip" && !data.phone) {
            setIsOpen(true);
          }
        }
      });
    }
  }, []);

  const handleSave = () => {
    if (phone.trim() === "") return;

    setIsSubmitting(true);
    const auth = getAuth();
    const db = getDatabase();
    const user = auth.currentUser;

    if (user) {
      const userRef = ref(db, `usuarios/${user.uid}`);
      update(userRef, { phone })
        .then(() => {
          setIsSubmitting(false);
          setSuccess(true);
          setTimeout(() => {
            setIsOpen(false);
          }, 2000);
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error("Error al guardar el número:", error);
        });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50 animate-fadeIn">
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 p-6 rounded-xl shadow-xl border border-yellow-500 w-full max-w-md transform transition-all duration-300 ease-in-out">
        {!success ? (
          <>
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <Trophy className="text-yellow-500 mr-2" size={28} />
                <h2 className="text-2xl font-bold text-white">ACCESO VIP</h2>
              </div>
              <div className="bg-yellow-500 text-black px-2 py-1 rounded-full text-xs font-bold animate-pulse">
                EXCLUSIVO
              </div>
            </div>

            <div className="bg-gray-800 p-4 rounded-lg mb-4 border-l-4 border-yellow-500">
              <h3 className="text-xl font-bold text-white">
                ¡Hola {userData?.name}! 👋
              </h3>
              <p className="mt-2 text-gray-300">
                Como miembro{" "}
                <span className="text-yellow-500 font-bold">VIP</span>, queremos
                enviarte predicciones exclusivas y alertas de apuestas
                directamente a tu celular.
              </p>
            </div>

            <div className="relative mb-6">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <Phone className="text-gray-400" size={18} />
              </div>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Ingresa tu número de celular"
                className="bg-gray-700 w-full pl-10 pr-4 py-3 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition-all duration-200"
              />
            </div>

            <div className="flex justify-between items-center">
              <button
                onClick={() => setIsOpen(false)}
                className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-all duration-200"
              >
                Omitir
              </button>
              <button
                onClick={handleSave}
                disabled={!phone.trim() || isSubmitting}
                className={`bg-yellow-500 hover:bg-yellow-400 text-black font-bold px-6 py-2 rounded-lg transition-all duration-200 flex items-center ${
                  !phone.trim() || isSubmitting
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                {isSubmitting ? (
                  <span className="inline-block w-4 h-4 border-2 border-black border-t-transparent rounded-full animate-spin mr-2"></span>
                ) : null}
                ACTIVAR ALERTAS
              </button>
            </div>

            <div className="mt-4 text-xs text-gray-400 text-center">
              Recibe pronósticos exclusivos y mejora tus estrategias de apuestas
            </div>
          </>
        ) : (
          <div className="text-center py-8 flex flex-col items-center">
            <div className="bg-green-500 p-4 rounded-full mb-4 animate-bounce">
              <CheckCircle className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-bold text-white mb-2">
              ¡Número Registrado!
            </h3>
            <p className="text-gray-300">
              Pronto recibirás nuestras predicciones exclusivas VIP
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VipPhoneModal;
