import React, { useState } from "react";
import { Lock, Mail, User } from "lucide-react";
import { auth, database } from "../../Config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
import FootballLoading from "../../Utils/FootballLoading";

// Versión de la aplicación
const APP_VERSION = "v2.5.1";

const generateReferralCode = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let referralCode = "";
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    referralCode += characters[randomIndex];
  }
  return referralCode;
};

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    typeU: "Cuenta Gratis",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword, typeU } = formData;

    // Validaciones básicas
    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }

    if (password.length < 6) {
      setError("La contraseña debe tener al menos 6 caracteres");
      return;
    }

    setLoading(true);
    setError("");

    try {
      // Registrar al usuario
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
        typeU
      );
      const user = userCredential.user;

      // Guardar el UID del usuario en el localStorage
      localStorage.setItem("userUUID", user.uid);

      // Generar código de referido
      const referralCode = generateReferralCode();

      // Guardar usuario y código de referido en la base de datos
      await set(ref(database, "usuarios/" + user.uid), {
        name: name,
        email: email,
        password: password,
        typeU: typeU,
        referralCode: referralCode,
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
      });

      // Redirigir al home después del registro exitoso
      navigate("/home");
    } catch (error) {
      console.error("Error al registrar usuario:", error);

      // Mensajes de error más descriptivos
      if (error.code === "auth/email-already-in-use") {
        setError("Este correo electrónico ya está registrado.");
      } else if (error.code === "auth/invalid-email") {
        setError("El formato del correo electrónico no es válido.");
      } else if (error.code === "auth/network-request-failed") {
        setError("Problema de conexión. Verifica tu internet.");
      } else {
        setError("Error al crear la cuenta. Por favor, intenta nuevamente.");
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-black">
        <div className="mb-4">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
            alt="Logo"
            className="w-16 h-16 rounded-full border-2 border-red-500"
          />
        </div>
        <FootballLoading />
        <p className="mt-4 text-white font-medium animate-pulse">
          Creando tu cuenta...
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-black">
      {/* Tarjeta principal */}
      <div className="bg-gray-900 p-8 rounded-xl shadow-2xl max-w-md w-full border border-gray-800 relative overflow-hidden">
        {/* Elementos decorativos */}
        <div className="absolute -top-10 -right-10 w-40 h-40 bg-red-500 opacity-10 rounded-full"></div>
        <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-orange-500 opacity-10 rounded-full"></div>

        {/* Contenido */}
        <div className="relative z-10">
          {/* Logo y encabezado */}
          <div className="flex flex-col items-center mb-6">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
              alt="Logo"
              className="w-20 h-20 rounded-full border-4 border-red-500 shadow-lg mb-3 transition-transform hover:scale-105 duration-300"
            />
            <h1 className="text-2xl font-bold text-white">DeportivosPro</h1>
            <div className="w-16 h-1 bg-gradient-to-r from-red-500 to-orange-500 rounded-full my-3"></div>
            <h2 className="text-xl font-medium text-gray-200">Crear Cuenta</h2>
          </div>

          {/* Mensaje de error */}
          {error && (
            <div className="mb-4 p-3 bg-red-900/30 border border-red-800 rounded-lg">
              <p className="text-red-400 text-sm flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                {error}
              </p>
            </div>
          )}

          {/* Formulario */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-gray-300 text-sm font-medium pl-1"
              >
                Nombre completo
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="h-5 w-5 text-gray-500" />
                </div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full pl-10 pr-4 py-2 bg-gray-800/70 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  placeholder="Juan Pérez"
                  required
                />
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="email"
                className="block text-gray-300 text-sm font-medium pl-1"
              >
                Correo electrónico
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-500" />
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full pl-10 pr-4 py-2 bg-gray-800/70 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  placeholder="ejemplo@correo.com"
                  required
                />
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-gray-300 text-sm font-medium pl-1"
              >
                Contraseña
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-500" />
                </div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full pl-10 pr-4 py-2 bg-gray-800/70 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  placeholder="Mínimo 6 caracteres"
                  required
                />
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-300 text-sm font-medium pl-1"
              >
                Confirmar contraseña
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-500" />
                </div>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="w-full pl-10 pr-4 py-2 bg-gray-800/70 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  placeholder="Repite tu contraseña"
                  required
                />
              </div>
            </div>

            {/* Términos y condiciones */}
            <div className="flex items-start mt-4">
              <div className="flex items-center h-5">
                <input
                  id="terms"
                  type="checkbox"
                  required
                  className="h-4 w-4 text-red-500 focus:ring-red-500 border-gray-700 rounded bg-gray-800"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="terms" className="text-gray-400">
                  Acepto los{" "}
                  <a href="/terms" className="text-red-500 hover:text-red-400">
                    Términos y Condiciones
                  </a>{" "}
                  y la{" "}
                  <a
                    href="/privacy"
                    className="text-red-500 hover:text-red-400"
                  >
                    Política de Privacidad
                  </a>
                </label>
              </div>
            </div>

            {/* Botón de registro */}
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-red-600 to-red-500 text-white py-2 px-4 rounded-lg font-medium shadow-lg hover:from-red-500 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-300 transform hover:-translate-y-1 mt-4"
            >
              Crear cuenta
            </button>
          </form>

          {/* Enlaces de ayuda */}
          <div className="mt-6 text-center">
            <p className="text-gray-400">
              ¿Ya tienes una cuenta?{" "}
              <a
                href="/login"
                className="font-medium text-red-500 hover:text-red-400 transition-colors duration-200"
              >
                Iniciar sesión
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Footer con versión
      <div className="mt-6 text-center text-gray-500 text-xs">
        <p>
          © {new Date().getFullYear()} ToWin. Todos los derechos reservados.
        </p>
        <p className="mt-1">{APP_VERSION}</p>
      </div> */}
    </div>
  );
};

export default Register;
