import React, { useState, useEffect } from "react";
import {
  Zap,
  Home,
  Tv,
  ChevronRight,
  Search,
  User,
  Settings,
  Menu,
  Bell,
  X,
  TrendingUp,
  Hexagon,
  Calendar,
  BookOpen,
  CreditCard,
  Gift,
  Star,
  ChevronDown,
  ArrowUpRight,
  Shield,
  Sun,
  Moon,
  Ticket,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

// Content components for each menu item
const HomeContent = () => {
  return (
    <div className="px-3 pt-3">
      {/* Sección de búsqueda */}
      <motion.div
        className="relative mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="relative">
          <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          <input
            type="text"
            placeholder="Buscar equipos, ligas o partidos..."
            className="w-full bg-gray-800 text-white rounded-lg py-2 pl-9 pr-4 text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>
      </motion.div>

      {/* Pestañas de filtro */}
      <EventTabs />

      {/* Promociones - Scroll horizontal */}
      <motion.div
        className="mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <div className="flex justify-between items-center mb-3">
          <div className="flex items-center">
            <Gift className="w-5 h-5 text-yellow-500 mr-2" />
            <h2 className="text-white font-medium">Promociones</h2>
          </div>
          <div className="flex items-center text-gray-400 hover:text-white cursor-pointer">
            <span className="text-xs mr-1">Ver todas</span>
            <ChevronRight className="w-4 h-4" />
          </div>
        </div>

        {/* Scroll horizontal para promociones */}
        <div className="flex overflow-x-auto pb-2 gap-3 scrollbar-hide">
          <PromoCard
            title="¡Bono de Bienvenida!"
            subtitle="Hasta $200"
            color="bg-gradient-to-br from-blue-800 to-blue-900"
          />
          <PromoCard
            title="¡Apuesta Sin Riesgo!"
            subtitle="Primer apuesta"
            color="bg-gradient-to-br from-green-800 to-green-900"
          />
          <PromoCard
            title="Recarga COMPLETA"
            subtitle="50% extra"
            color="bg-gradient-to-br from-purple-800 to-purple-900"
          />
          <PromoCard
            title="Giros Gratis"
            subtitle="Casino Online"
            color="bg-gradient-to-br from-red-800 to-red-900"
          />
        </div>
      </motion.div>

      {/* Ligas Populares */}
      <motion.div
        className="mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <Star className="w-5 h-5 text-yellow-500 mr-2" />
            <h2 className="text-white font-medium">Ligas Populares</h2>
          </div>
          <div className="flex items-center text-gray-400 hover:text-white cursor-pointer">
            <span className="text-xs mr-1">Ver todas</span>
            <ChevronRight className="w-4 h-4" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2">
          <LeagueCard
            name="Copa Libertadores"
            icon="🏆"
            matches="8 partidos"
          />
          <LeagueCard name="La Liga" icon="🇪🇸" matches="12 partidos" />
          <LeagueCard name="Premier" icon="🏴󠁧󠁢󠁥󠁮󠁧󠁿" matches="10 partidos" />
          <LeagueCard name="Serie A" icon="🇮🇹" matches="6 partidos" />
        </div>
      </motion.div>

      {/* Partidos Destacados - Contenido adicional */}
      <motion.div
        className="mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
      >
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <Zap className="w-5 h-5 text-yellow-500 mr-2" />
            <h2 className="text-white font-medium">Partidos Destacados</h2>
          </div>
          <div className="flex items-center text-gray-400 hover:text-white cursor-pointer">
            <span className="text-xs mr-1">Ver todos</span>
            <ChevronRight className="w-4 h-4" />
          </div>
        </div>

        {/* Cards con partidos destacados simplificados para móvil */}
        <div className="space-y-3">
          {[1, 2, 3].map((item) => (
            <motion.div
              key={item}
              className="bg-gray-800 rounded-lg p-3 shadow-md"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-400 text-xs">
                  Copa Libertadores • Hoy 20:00
                </span>
                <div className="flex items-center bg-gray-700 rounded-full px-2 py-0.5">
                  <span className="text-orange-500 text-xs">En vivo</span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span className="bg-gray-700 text-white w-6 h-6 rounded-full flex items-center justify-center text-xs mr-2">
                    B
                  </span>
                  <span className="text-white text-sm">Boca Juniors</span>
                </div>
                <span className="text-white font-medium mx-2">vs</span>
                <div className="flex items-center">
                  <span className="text-white text-sm">River Plate</span>
                  <span className="bg-gray-700 text-white w-6 h-6 rounded-full flex items-center justify-center text-xs ml-2">
                    R
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-3">
                <motion.button
                  className="bg-gray-700 text-white text-xs py-1 px-3 rounded flex-1 mr-1"
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex justify-between">
                    <span>Local</span>
                    <span className="font-medium">2.10</span>
                  </div>
                </motion.button>
                <motion.button
                  className="bg-gray-700 text-white text-xs py-1 px-3 rounded flex-1 mx-1"
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex justify-between">
                    <span>Empate</span>
                    <span className="font-medium">3.25</span>
                  </div>
                </motion.button>
                <motion.button
                  className="bg-gray-700 text-white text-xs py-1 px-3 rounded flex-1 ml-1"
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex justify-between">
                    <span>Visita</span>
                    <span className="font-medium">3.40</span>
                  </div>
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

const LiveSportsContent = () => {
  return (
    <div className="px-3 pt-3">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center mb-4">
          <Zap className="w-6 h-6 text-orange-500 mr-2" />
          <h1 className="text-white text-xl font-bold">Deportes en Vivo</h1>
        </div>
        
        <div className="bg-gray-800 rounded-lg p-4 mb-4">
          <div className="flex justify-between items-center mb-3">
            <div className="text-white font-medium">Filtros</div>
            <motion.button 
              className="text-xs bg-orange-500 rounded px-2 py-1 text-white"
              whileTap={{ scale: 0.95 }}
            >
              Limpiar
            </motion.button>
          </div>
          <div className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide">
            <motion.button 
              className="bg-gray-700 px-3 py-1 rounded-full text-xs text-white whitespace-nowrap"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Fútbol
            </motion.button>
            <motion.button 
              className="bg-gray-700 px-3 py-1 rounded-full text-xs text-white whitespace-nowrap"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Baloncesto
            </motion.button>
            <motion.button 
              className="bg-gray-700 px-3 py-1 rounded-full text-xs text-white whitespace-nowrap"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Tenis
            </motion.button>
            <motion.button 
              className="bg-gray-700 px-3 py-1 rounded-full text-xs text-white whitespace-nowrap"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Voleibol
            </motion.button>
            <motion.button 
              className="bg-gray-700 px-3 py-1 rounded-full text-xs text-white whitespace-nowrap"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Hockey
            </motion.button>
          </div>
        </div>
        
        <div className="space-y-3 mb-6">
          {[1, 2, 3, 4].map((item) => (
            <motion.div
              key={item}
              className="bg-gray-800 rounded-lg p-3 shadow-md"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>
                  <span className="text-gray-400 text-xs">EN VIVO • 32'</span>
                </div>
                <div className="flex items-center text-xs text-gray-400">
                  <Tv className="w-3 h-3 mr-1" />
                  <span>Transmitiendo</span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span className="bg-gray-700 text-white w-6 h-6 rounded-full flex items-center justify-center text-xs mr-2">
                    M
                  </span>
                  <div className="flex flex-col">
                    <span className="text-white text-sm">Man. City</span>
                    <span className="text-green-500 text-xs">1</span>
                  </div>
                </div>
                <span className="text-white font-medium mx-2">vs</span>
                <div className="flex items-center">
                  <div className="flex flex-col items-end">
                    <span className="text-white text-sm">Liverpool</span>
                    <span className="text-green-500 text-xs">2</span>
                  </div>
                  <span className="bg-gray-700 text-white w-6 h-6 rounded-full flex items-center justify-center text-xs ml-2">
                    L
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-3">
                <motion.button
                  className="bg-gray-700 text-white text-xs py-1 px-3 rounded flex-1 mr-1"
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex justify-between">
                    <span>Local</span>
                    <span className="font-medium">2.25</span>
                  </div>
                </motion.button>
                <motion.button
                  className="bg-gray-700 text-white text-xs py-1 px-3 rounded flex-1 mx-1"
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex justify-between">
                    <span>Empate</span>
                    <span className="font-medium">3.10</span>
                  </div>
                </motion.button>
                <motion.button
                  className="bg-gray-700 text-white text-xs py-1 px-3 rounded flex-1 ml-1"
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex justify-between">
                    <span>Visita</span>
                    <span className="font-medium">2.90</span>
                  </div>
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

const SportsContent = () => {
  return (
    <div className="px-3 pt-3">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center mb-4">
          <TrendingUp className="w-6 h-6 text-orange-500 mr-2" />
          <h1 className="text-white text-xl font-bold">Deportes</h1>
        </div>

        <div className="bg-gray-800 rounded-lg p-3 mb-4">
          <input
            type="text"
            placeholder="Buscar deportes..."
            className="w-full bg-gray-700 text-white rounded-lg py-2 px-3 text-sm focus:outline-none"
          />
        </div>
        
        {/* Deportes populares */}
        <div className="space-y-2 mb-6">
          {[
            { name: "Fútbol", icon: "⚽", count: 230 },
            { name: "Baloncesto", icon: "🏀", count: 85 },
            { name: "Tenis", icon: "🎾", count: 42 },
            { name: "Béisbol", icon: "⚾", count: 38 },
            { name: "Hockey", icon: "🏒", count: 24 },
            { name: "Golf", icon: "⛳", count: 12 }
          ].map((sport, index) => (
            <motion.div
              key={index}
              className="bg-gray-800 rounded-lg p-3 flex justify-between items-center"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="flex items-center">
                <div className="mr-3 text-xl">{sport.icon}</div>
                <span className="text-white">{sport.name}</span>
              </div>
              <div className="flex items-center">
                <span className="text-gray-400 text-xs mr-2">{sport.count} eventos</span>
                <ChevronRight className="w-4 h-4 text-gray-400" />
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

const MyBetsContent = () => {
  return (
    <div className="px-3 pt-3">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center mb-4">
          <BookOpen className="w-6 h-6 text-orange-500 mr-2" />
          <h1 className="text-white text-xl font-bold">Mis Apuestas</h1>
        </div>
        
        <div className="bg-gray-800 rounded-lg p-4 mb-4">
          <div className="flex space-x-2 mb-3">
            <motion.button
              className="bg-orange-500 text-white rounded-full px-4 py-1 text-sm font-medium"
              whileTap={{ scale: 0.95 }}
            >
              Activas
            </motion.button>
            <motion.button
              className="bg-gray-700 text-gray-300 rounded-full px-4 py-1 text-sm"
              whileTap={{ scale: 0.95 }}
            >
              Historial
            </motion.button>
            <motion.button
              className="bg-gray-700 text-gray-300 rounded-full px-4 py-1 text-sm"
              whileTap={{ scale: 0.95 }}
            >
              Liquidadas
            </motion.button>
          </div>
        </div>
        
        <div className="space-y-3 mb-6">
          {[1, 2].map((item) => (
            <motion.div
              key={item}
              className="bg-gray-800 rounded-lg p-3 shadow-md"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="flex justify-between mb-2">
                <span className="text-xs text-gray-400">Parlay • 3 Selecciones</span>
                <span className="text-xs text-orange-500 font-medium">En curso</span>
              </div>
              <div className="border-b border-gray-700 pb-2 mb-2">
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-400">River vs Boca</span>
                  <span className="text-xs text-white">Victoria Local</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-400">Barcelona vs Real Madrid</span>
                  <span className="text-xs text-white">Más de 2.5 Goles</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-400">PSG vs Bayern</span>
                  <span className="text-xs text-white">Ambos marcan</span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-xs text-gray-400">Apuesta:</span>
                  <span className="text-sm text-white ml-1">$50</span>
                </div>
                <div>
                  <span className="text-xs text-gray-400">Ganancia potencial:</span>
                  <span className="text-sm text-green-500 ml-1">$312.50</span>
                </div>
              </div>
            </motion.div>
          ))}
          
          <div className="text-center py-4">
            <span className="text-gray-400 text-sm">No hay más apuestas activas</span>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const ExploreContent = () => {
  return (
    <div className="px-3 pt-3">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center mb-4">
          <Search className="w-6 h-6 text-orange-500 mr-2" />
          <h1 className="text-white text-xl font-bold">Explorar</h1>
        </div>
        
        <div className="bg-gray-800 rounded-lg p-3 mb-4">
          <div className="relative">
            <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              type="text"
              placeholder="Buscar equipos, ligas o eventos..."
              className="w-full bg-gray-700 text-white rounded-lg py-2 pl-9 pr-4 text-sm focus:outline-none"
            />
          </div>
        </div>
        
        <div className="grid grid-cols-2 gap-3 mb-6">
          <motion.div
            className="bg-gradient-to-br from-blue-800 to-blue-900 rounded-lg p-4 flex flex-col justify-between h-32"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
          >
            <div className="text-xl mb-1">⚽</div>
            <div>
              <h3 className="text-white font-medium">Fútbol</h3>
              <p className="text-blue-300 text-xs">230 eventos en vivo</p>
            </div>
          </motion.div>
          
          <motion.div
            className="bg-gradient-to-br from-green-800 to-green-900 rounded-lg p-4 flex flex-col justify-between h-32"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
          >
            <div className="text-xl mb-1">🏀</div>
            <div>
              <h3 className="text-white font-medium">Baloncesto</h3>
              <p className="text-green-300 text-xs">85 eventos en vivo</p>
            </div>
          </motion.div>
          
          <motion.div
            className="bg-gradient-to-br from-purple-800 to-purple-900 rounded-lg p-4 flex flex-col justify-between h-32"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
          >
            <div className="text-xl mb-1">🎾</div>
            <div>
              <h3 className="text-white font-medium">Tenis</h3>
              <p className="text-purple-300 text-xs">42 eventos en vivo</p>
            </div>
          </motion.div>
          
          <motion.div
            className="bg-gradient-to-br from-yellow-800 to-yellow-900 rounded-lg p-4 flex flex-col justify-between h-32"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
          >
            <div className="text-xl mb-1">⚾</div>
            <div>
              <h3 className="text-white font-medium">Béisbol</h3>
              <p className="text-yellow-300 text-xs">38 eventos en vivo</p>
            </div>
          </motion.div>
        </div>
        
        <div className="mb-6">
          <div className="flex items-center justify-between mb-3">
            <h2 className="text-white font-medium">Competiciones populares</h2>
            <div className="flex items-center text-gray-400 hover:text-white cursor-pointer">
              <span className="text-xs mr-1">Ver todas</span>
              <ChevronRight className="w-4 h-4" />
            </div>
          </div>
          
          <div className="space-y-2">
            {[
              { name: "Champions League", icon: "🏆", region: "Europa" },
              { name: "Copa Libertadores", icon: "🏆", region: "Sudamérica" },
              { name: "Premier League", icon: "🏴󠁧󠁢󠁥󠁮󠁧󠁿", region: "Inglaterra" },
              { name: "La Liga", icon: "🇪🇸", region: "España" }
            ].map((competition, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 rounded-lg p-3 flex justify-between items-center"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center">
                  <div className="mr-3 text-xl">{competition.icon}</div>
                  <div>
                    <span className="text-white block">{competition.name}</span>
                    <span className="text-gray-400 text-xs">{competition.region}</span>
                  </div>
                </div>
                <ChevronRight className="w-4 h-4 text-gray-400" />
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

// Componente para el Depósito/Retiro
const DepositContent = () => {
  return (
    <div className="px-3 pt-3">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center mb-4">
          <CreditCard className="w-6 h-6 text-orange-500 mr-2" />
          <h1 className="text-white text-xl font-bold">Depósito/Retiro</h1>
        </div>

        <div className="flex space-x-2 mb-4">
          <motion.button
            className="bg-orange-500 text-white rounded-full px-4 py-2 text-sm font-medium flex-1"
            whileTap={{ scale: 0.95 }}
          >
            Depósito
          </motion.button>
          <motion.button
            className="bg-gray-700 text-gray-300 rounded-full px-4 py-2 text-sm flex-1"
            whileTap={{ scale: 0.95 }}
          >
            Retiro
          </motion.button>
        </div>

        <div className="bg-gray-800 rounded-lg p-4 mb-4">
          <div className="text-gray-400 text-sm mb-2">Cantidad a depositar</div>
          <div className="relative mb-4">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white">
              $
            </span>
            <input
              type="text"
              placeholder="0.00"
              className="w-full bg-gray-700 text-white rounded-lg py-3 pl-8 pr-4 text-lg font-medium focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <div className="flex justify-between gap-2 mb-4">
            {[100, 200, 500, 1000].map((amount) => (
              <motion.button
                key={amount}
                className="bg-gray-700 text-white rounded px-2 py-1 text-sm flex-1"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                ${amount}
              </motion.button>
            ))}
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg p-4 mb-4">
          <div className="text-white text-sm font-medium mb-3">
            Método de pago
          </div>
          <div className="space-y-2">
            {[
              { name: "Tarjeta de crédito", icon: "💳" },
              { name: "Transferencia bancaria", icon: "🏦" },
              { name: "Billetera electrónica", icon: "📱" },
              { name: "Criptomonedas", icon: "₿" },
            ].map((method, index) => (
              <motion.div
                key={index}
                className="bg-gray-700 rounded-lg p-3 flex justify-between items-center"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center">
                  <div className="mr-3 text-xl">{method.icon}</div>
                  <span className="text-white">{method.name}</span>
                </div>
                <ChevronRight className="w-4 h-4 text-gray-400" />
              </motion.div>
            ))}
          </div>
        </div>

        <motion.button
          className="bg-orange-500 text-white rounded-lg py-3 font-medium w-full mb-4"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
        >
          Realizar depósito
        </motion.button>
      </motion.div>
    </div>
  );
};

// Utility Components
const PromoCard = ({ title, subtitle, color }) => {
  return (
    <motion.div
      className={`${color} rounded-lg p-3 min-w-[150px] shadow-lg`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="flex items-start mb-2">
        <Gift className="w-5 h-5 text-yellow-400" />
      </div>
      <h3 className="text-white font-medium text-sm">{title}</h3>
      <p className="text-blue-200 text-xs">{subtitle}</p>
      <div className="flex items-center mt-2">
        <span className="text-white text-xs font-medium">Ver más</span>
        <ArrowUpRight className="w-3 h-3 text-white ml-1" />
      </div>
    </motion.div>
  );
};

const LeagueCard = ({ name, icon, matches }) => {
  return (
    <motion.div
      className="bg-gray-800 rounded-lg p-2 flex flex-col items-center"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="flex items-center justify-center w-8 h-8 bg-gray-700 rounded-full mb-1">
        {icon === "🏆" ? (
          <Hexagon className="w-4 h-4 text-yellow-500" />
        ) : icon === "🇪🇸" ? (
          <Flag country="ES" className="w-4 h-4" />
        ) : icon === "🏴󠁧󠁢󠁥󠁮󠁧󠁿" ? (
          <Flag country="GB" className="w-4 h-4" />
        ) : icon === "🇮🇹" ? (
          <Flag country="IT" className="w-4 h-4" />
        ) : (
          <TrendingUp className="w-4 h-4 text-white" />
        )}
      </div>
      <span className="text-white text-xs text-center line-clamp-1">
        {name}
      </span>
      <span className="text-gray-400 text-xs">{matches}</span>
    </motion.div>
  );
};

// Flag component for country flags
const Flag = ({ country, className }) => {
  // Instead of actual emoji flags, we'll use a simple colored box with the country code
  const bgColors = {
    ES: "bg-yellow-600",
    GB: "bg-blue-600",
    IT: "bg-green-600",
  };

  return (
    <div
      className={`${
        bgColors[country] || "bg-gray-600"
      } ${className} flex items-center justify-center text-white text-xs rounded overflow-hidden`}
    >
      {country}
    </div>
  );
};

const EventTabs = () => {
  const [activeTab, setActiveTab] = useState("live");

  return (
    <div className="mb-5">
      <div className="flex mb-4 border-b border-gray-700">
        <motion.button
          className={`pb-2 px-3 text-sm font-medium flex items-center ${
            activeTab === "live"
              ? "text-orange-500 border-b-2 border-orange-500"
              : "text-gray-400"
          }`}
          onClick={() => setActiveTab("live")}
          whileTap={{ scale: 0.97 }}
        >
          <Zap
            className={`w-4 h-4 mr-1 ${
              activeTab === "live" ? "text-orange-500" : "text-gray-400"
            }`}
          />
          En vivo
        </motion.button>
        <motion.button
          className={`pb-2 px-3 text-sm font-medium flex items-center ${
            activeTab === "upcoming"
              ? "text-orange-500 border-b-2 border-orange-500"
              : "text-gray-400"
          }`}
          onClick={() => setActiveTab("upcoming")}
          whileTap={{ scale: 0.97 }}
        >
          <Calendar
            className={`w-4 h-4 mr-1 ${
              activeTab === "upcoming" ? "text-orange-500" : "text-gray-400"
            }`}
          />
          Próximos
        </motion.button>
        <motion.button
          className={`pb-2 px-3 text-sm font-medium flex items-center ${
            activeTab === "highlights"
              ? "text-orange-500 border-b-2 border-orange-500"
              : "text-gray-400"
          }`}
          onClick={() => setActiveTab("highlights")}
          whileTap={{ scale: 0.97 }}
        >
          <Star
            className={`w-4 h-4 mr-1 ${
              activeTab === "highlights" ? "text-orange-500" : "text-gray-400"
            }`}
          />
          Destacados
        </motion.button>
      </div>
    </div>
  );
};

// App Component
export default function BettingApp() {
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [activeTab, setActiveTab] = useState("home");
  const [showNotifications, setShowNotifications] = useState(false);

  // Contents for each main navigation tab
  const tabContents = {
    home: <HomeContent />,
    liveSports: <LiveSportsContent />,
    sports: <SportsContent />,
    myBets: <MyBetsContent />,
    explore: <ExploreContent />,
    deposit: <DepositContent />,
  };

  return (
    <div
      className={`${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } min-h-screen font-sans`}
    >
      {/* Header */}
      <div className="bg-gray-800 text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <button onClick={() => setIsOpen(true)}>
            <Menu className="h-6 w-6" />
          </button>
          <h1 className="ml-3 font-bold text-lg">BetSport</h1>
        </div>
        <div className="flex items-center space-x-3">
          <button onClick={() => setShowNotifications(!showNotifications)}>
            <Bell className="h-5 w-5" />
          </button>
          <button>
            <User className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Sidebar */}
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              className="fixed top-0 left-0 bottom-0 w-64 bg-gray-800 z-50"
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ type: "spring", damping: 25 }}
            >
              <div className="p-4 flex justify-between items-center border-b border-gray-700">
                <h2 className="text-white font-bold text-lg">Menú</h2>
                <button onClick={() => setIsOpen(false)}>
                  <X className="h-5 w-5 text-white" />
                </button>
              </div>
              <div className="p-4">
                <div className="space-y-4">
                  <button
                    className="flex items-center text-white w-full"
                    onClick={() => {
                      setActiveTab("home");
                      setIsOpen(false);
                    }}
                  >
                    <Home className="h-5 w-5 mr-2" />
                    <span>Inicio</span>
                  </button>
                  <button
                    className="flex items-center text-white w-full"
                    onClick={() => {
                      setActiveTab("liveSports");
                      setIsOpen(false);
                    }}
                  >
                    <Zap className="h-5 w-5 mr-2" />
                    <span>Deportes en vivo</span>
                  </button>
                  <button
                    className="flex items-center text-white w-full"
                    onClick={() => {
                      setActiveTab("sports");
                      setIsOpen(false);
                    }}
                  >
                    <TrendingUp className="h-5 w-5 mr-2" />
                    <span>Deportes</span>
                  </button>
                  <button
                    className="flex items-center text-white w-full"
                    onClick={() => {
                      setActiveTab("myBets");
                      setIsOpen(false);
                    }}
                  >
                    <BookOpen className="h-5 w-5 mr-2" />
                    <span>Mis Apuestas</span>
                  </button>
                  <button
                    className="flex items-center text-white w-full"
                    onClick={() => {
                      setActiveTab("explore");
                      setIsOpen(false);
                    }}
                  >
                    <Search className="h-5 w-5 mr-2" />
                    <span>Explorar</span>
                  </button>
                  <button
                    className="flex items-center text-white w-full"
                    onClick={() => {
                      setActiveTab("deposit");
                      setIsOpen(false);
                    }}
                  >
                    <CreditCard className="h-5 w-5 mr-2" />
                    <span>Depósito/Retiro</span>
                  </button>
                </div>

                <div className="mt-8 pt-4 border-t border-gray-700">
                  <button
                    className="flex items-center text-white w-full mb-3"
                    onClick={() => setDarkMode(!darkMode)}
                  >
                    {darkMode ? (
                      <Sun className="h-5 w-5 mr-2" />
                    ) : (
                      <Moon className="h-5 w-5 mr-2" />
                    )}
                    <span>{darkMode ? "Modo claro" : "Modo oscuro"}</span>
                  </button>
                  <button className="flex items-center text-white w-full">
                    <Settings className="h-5 w-5 mr-2" />
                    <span>Configuración</span>
                  </button>
                  <button className="flex items-center text-white w-full mt-3">
                    <Shield className="h-5 w-5 mr-2" />
                    <span>Juego Responsable</span>
                  </button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* Notifications Panel */}
      <AnimatePresence>
        {showNotifications && (
          <>
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setShowNotifications(false)}
            />
            <motion.div
              className="fixed top-0 right-0 bottom-0 w-64 bg-gray-800 z-50"
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "spring", damping: 25 }}
            >
              <div className="p-4 flex justify-between items-center border-b border-gray-700">
                <h2 className="text-white font-bold text-lg">Notificaciones</h2>
                <button onClick={() => setShowNotifications(false)}>
                  <X className="h-5 w-5 text-white" />
                </button>
              </div>
              <div className="p-4 space-y-3">
                <div className="bg-gray-700 rounded-lg p-3">
                  <div className="flex items-start">
                    <Ticket className="w-5 h-5 text-orange-500 mr-2 mt-0.5" />
                    <div>
                      <p className="text-white text-sm">
                        ¡Tu apuesta fue ganadora! Recibiste $112.50
                      </p>
                      <span className="text-gray-400 text-xs">
                        Hace 5 minutos
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-700 rounded-lg p-3">
                  <div className="flex items-start">
                    <Gift className="w-5 h-5 text-orange-500 mr-2 mt-0.5" />
                    <div>
                      <p className="text-white text-sm">
                        Nuevo bono disponible: 50% en tu próximo depósito
                      </p>
                      <span className="text-gray-400 text-xs">
                        Hace 2 horas
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-700 rounded-lg p-3">
                  <div className="flex items-start">
                    <Zap className="w-5 h-5 text-orange-500 mr-2 mt-0.5" />
                    <div>
                      <p className="text-white text-sm">
                        River vs Boca está por comenzar
                      </p>
                      <span className="text-gray-400 text-xs">
                        Hace 6 horas
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* Main Content */}
      <div className="pb-16">{tabContents[activeTab]}</div>

      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-gray-800 border-t border-gray-700 p-1 flex justify-around">
        <motion.button
          className={`flex flex-col items-center py-1 px-2 ${
            activeTab === "home" ? "text-orange-500" : "text-gray-400"
          }`}
          onClick={() => setActiveTab("home")}
          whileTap={{ scale: 0.9 }}
        >
          <Home className="w-5 h-5" />
          <span className="text-xs mt-1">Inicio</span>
        </motion.button>
        <motion.button
          className={`flex flex-col items-center py-1 px-2 ${
            activeTab === "liveSports" ? "text-orange-500" : "text-gray-400"
          }`}
          onClick={() => setActiveTab("liveSports")}
          whileTap={{ scale: 0.9 }}
        >
          <Zap className="w-5 h-5" />
          <span className="text-xs mt-1">En vivo</span>
        </motion.button>
        <motion.button
          className={`flex flex-col items-center py-1 px-2 ${
            activeTab === "myBets" ? "text-orange-500" : "text-gray-400"
          }`}
          onClick={() => setActiveTab("myBets")}
          whileTap={{ scale: 0.9 }}
        >
          <BookOpen className="w-5 h-5" />
          <span className="text-xs mt-1">Apuestas</span>
        </motion.button>
        <motion.button
          className={`flex flex-col items-center py-1 px-2 ${
            activeTab === "explore" ? "text-orange-500" : "text-gray-400"
          }`}
          onClick={() => setActiveTab("explore")}
          whileTap={{ scale: 0.9 }}
        >
          <Search className="w-5 h-5" />
          <span className="text-xs mt-1">Explorar</span>
        </motion.button>
      </div>
    </div>
  );
}