import React from 'react'

export default function Card({ children, className = "" }) {
  return (
    <div>
      <div className={`bg-white rounded-xl shadow-sm ${className}`}>
        {children}
      </div>
    </div>
  );
}
