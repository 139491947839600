import React from "react";

export default function AlertPayVip() {
  const benefits = [
    {
      title: "Predicciones Premium",
      description:
        "Acceso a predicciones exclusivas con alto porcentaje de acierto",
    },
    {
      title: "Análisis Detallado",
      description: "Reportes completos de análisis estadístico y tendencias",
    },
    {
      title: "Soporte Prioritario",
      description: "Atención personalizada 24/7 por nuestros expertos",
    },
    {
      title: "Sin Publicidad",
      description: "Experiencia limpia sin anuncios ni interrupciones",
    },
  ];

  const handleRedirect = () => {
    window.location.href =
      "https://deportivosblogs.online/producto/suscripcion-vip/";
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white py-16 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
      <div className="max-w-4xl mx-auto text-center">
        <h1 className="text-5xl font-extrabold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-500">
          Acceso VIP Requerido
        </h1>
        <p className="text-xl text-gray-300 mb-10">
          Esta sección está disponible exclusivamente para usuarios VIP.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg border border-yellow-600/20 hover:border-yellow-500 transition-all duration-300 transform hover:-translate-y-1 text-left"
            >
              <h3 className="text-lg font-bold text-yellow-400">
                {benefit.title}
              </h3>
              <p className="text-gray-400">{benefit.description}</p>
            </div>
          ))}
        </div>

        <button
          onClick={handleRedirect}
          className="inline-block bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-400 hover:to-yellow-500 text-gray-900 font-bold py-4 px-8 rounded-full transition-all duration-300 text-lg hover:scale-105"
        >
          Mejorar a VIP Ahora
        </button>
      </div>
    </div>
  );
}
